import axios from "axios"
import cookies from "modules/cookies"

const readFile = (file) =>
  new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = (e) => {
      let binary = atob(e.target.result.split(",")[1])

      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }

      let blobData = new Blob([new Uint8Array(array)], { type: file.type })
      resolve(blobData)
    }

    reader.onerror = reject

    reader.readAsDataURL(file)
  })

export default async ({ file, project, entity, id }, token) => {
  const authToken = token || cookies.get("authStartseToken")
  const config = {
    headers: {
      "x-auth-token": authToken,
    },
  }
  const responseUrl = await axios.get(
    `${process.env.REACT_APP_UPLOADER_URI}?type=${file.type}&project=${project}&entity=${entity}&id=${id}`,
    config
  )

  if (
    !responseUrl ||
    !responseUrl.data ||
    !responseUrl.data.uploadURL ||
    !responseUrl.data.file
  )
    throw new Error("Imagem error on uploading image")

  const uploadleFile = await readFile(file)
  await axios.put(responseUrl.data.uploadURL, uploadleFile)

  return responseUrl.data.file
}
