import React, { useState, useMemo } from "react"

import { useForm } from "react-hook-form"
import yup from "modules/form-validation"
import { useLocation } from "react-router-dom"
import updateHubspotProps from "modules/services/lambdas/updateHubspotProps"
import { useTracking } from "modules/hooks/tracking"
import enrollUser from "modules/services/lambdas/enrollUser"
import qs from "qs"

import TextField from "components/Form/TextField"
import AutoComplete from "components/Form/AutoComplete"
import SingleCheckbox from "components/Form/Checkbox/SingleCheckbox"
import LoginButton from "components/Button/LoginButton"
import Link from "@material-ui/core/Link"
import { CustomLabel } from "components/Form/Checkbox/style"
import {
  jobtitle as jobtitleData,
  segment as segmentData,
  department as departmentData,
  countries,
  states,
} from "modules/constants"
import { useUser } from "modules/hooks/onboarding/user"
import { useApp } from "modules/hooks/onboarding/app"
import { SendIcon } from "components/icons"

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  segment: yup.string().required(),
  jobtitle: yup.string().required(),
  department: yup.string().required(),
  country: yup.string().required(),
  state: yup.string().when("country", {
    is: "Brasil",
    then: yup.string().required(),
  }),
  city: yup.string().when("country", {
    is: "Brasil",
    then: yup.string().required(),
  }),
  phone: yup.string().required(),
  agree: yup
    .bool()
    .oneOf([true], "É necessário aceitar o termo para continuar"),
})

const Form = ({ user, redirect }) => {
  const { track, set } = useTracking()
  const { search } = useLocation()
  const queryString = useMemo(() => {
    let _search = search ? search : ""
    return qs.parse(_search.replace("?", ""))
  }, [search])

  const defaultValues = {
    name: user.company?.name,
    segment: user.segment,
    jobtitle: user.jobtitle,
    department: user.department,
    country: user.country,
    state: user.state,
    city: user.city,
    phone: user.phone,
  }

  const [watchCountry, setWatchCountry] = useState(
    defaultValues.country === "Brasil"
  )
  const { updateUser } = useUser()
  const { setLoading } = useApp()

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      const companyName = data.name
      delete data.name
      delete data.agree
      let newUser = {
        ...user,
        ...data,
        company: {
          ...(user?.company || {}),
          name: companyName,
        },
      }

      const department = departmentData.find((e) => e.label === data.department)
        ?.value
      if (department) newUser.department = department

      const jobtitle = jobtitleData.find((e) => e.label === data.jobtitle)
        ?.value
      if (jobtitle) newUser.jobtitle = jobtitle

      const segment = segmentData.find((e) => e.label === data.segment)?.value
      if (segment) newUser.segment = segment

      const eventSlug = queryString?.event

      const userID = await updateUser(newUser)
      await updateHubspotProps(newUser, eventSlug, "Update_info")

      if (eventSlug && userID) {
        await enrollUser(userID._id, eventSlug, true)
      }

      try {
        track("Complete Onboarding", {
          redirect_to: queryString.redirect_to
            ? queryString.redirect_to
            : queryString.redirect_uri,
        })
        set(
          {
            segment: newUser.segment,
            jobtitle: newUser.jobtitle,
            companyName: companyName,
            department: newUser.department,
            educationInterest: newUser.educationInterest,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            fullName: newUser.firstName + " " + newUser.lastName,
            dateOfBirth: newUser.dateOfBirth,
            interests: newUser.interests,
            linkedin: newUser.linkedin,
            phone: newUser.phone,
          },
          user.email
        )
      } catch (err) {
        console.log(err)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)

      redirect()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
      <TextField
        id="name"
        inputRef={register}
        label="Empresa"
        size="medium"
        name="name"
        errors={errors}
        autoFocus={true}
      />

      <AutoComplete
        id="segment"
        options={segmentData}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => value?.label === option?.label}
        disableClearable
        size="medium"
        defaultValue={
          defaultValues.segment
            ? segmentData.find((e) => e.value === defaultValues.segment)
            : null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Segmento"
            size="medium"
            inputRef={register}
            name="segment"
            errors={errors}
          />
        )}
      />

      <AutoComplete
        id="jobtitle"
        options={jobtitleData}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => value?.label === option?.label}
        disableClearable
        size="medium"
        freeSolo
        defaultValue={
          defaultValues.jobtitle
            ? jobtitleData.find((e) => e.value === defaultValues.jobtitle) || {
                label: defaultValues.jobtitle,
              }
            : null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Cargo"
            size="medium"
            inputRef={register}
            name="jobtitle"
            errors={errors}
          />
        )}
      />

      <AutoComplete
        id="department"
        options={departmentData}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => value?.label === option?.label}
        disableClearable
        size="medium"
        defaultValue={
          defaultValues.department
            ? departmentData.find((e) => e.value === defaultValues.department)
            : null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Departamento"
            size="medium"
            inputRef={register}
            name="department"
            errors={errors}
          />
        )}
      />

      <AutoComplete
        id="country"
        options={countries}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => value?.name === option?.name}
        disableClearable
        size="medium"
        defaultValue={
          defaultValues.country ? { name: defaultValues.country } : null
        }
        onChange={(_e, value) => setWatchCountry(value?.name === "Brasil")}
        renderInput={(params) => (
          <TextField
            {...params}
            label="País"
            size="medium"
            inputRef={register}
            name="country"
            errors={errors}
          />
        )}
      />

      {!!watchCountry && (
        <>
          <AutoComplete
            id="state"
            options={states}
            getOptionLabel={(option) => option.estado}
            getOptionSelected={(option, value) =>
              value?.estado === option?.estado
            }
            disableClearable
            size="medium"
            defaultValue={
              defaultValues.state ? { estado: defaultValues.state } : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estado"
                size="medium"
                inputRef={register}
                name="state"
                errors={errors}
              />
            )}
          />

          <TextField
            id="city"
            inputRef={register}
            label="Cidade"
            size="medium"
            name="city"
            errors={errors}
          />
        </>
      )}

      <TextField
        id="phone"
        inputRef={register}
        label="Seu celular"
        size="medium"
        name="phone"
        errors={errors}
      />

      <SingleCheckbox
        errors={errors}
        name="agree"
        register={register}
        defaultValue={true}
        label={
          <CustomLabel>
            <span>Eu concordo com a </span>
            <Link
              target="_blank"
              href="https://docs.startse.com/docs/politica-de-privacidade"
            >
              Política de Privacidade
            </Link>
            <span> do StartSe Summit</span>
          </CustomLabel>
        }
      />
      <div style={{ textAlign: "right" }}>
        <LoginButton
          type="submit"
          endIcon={<SendIcon style={{ color: "#fff" }} />}
        >
          Finalizar
        </LoginButton>
      </div>
    </form>
  )
}

export default Form
