import React from "react"
import styled from "styled-components"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"

export const ErrorContainer = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  color: #ef6e67;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: ${(props) => (props.center ? "center" : "start")};
  margin-top: ${(props) => (props.page ? "30px" : "0px")};
  & svg {
    margin-right: 10px;
  }
`

const Error = ({ text, center, page }) => {
  if (!text) return null

  return (
    <ErrorContainer center={center || page} page={page}>
      <ErrorOutlineIcon />
      {text}
    </ErrorContainer>
  )
}

export default Error
