import styled from "styled-components"
// import { withStyles } from "@material-ui/core/styles"

export const SharedData = styled.p`
  color: #acacac;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  svg {
    color: #0080ed;
    margin-right: 5px;
  }
`
export const Body = styled.div`
  padding: 20px 40px;
  @media screen and (max-width: 600px) {
    padding: 20px 0px;
  }
`

export const Label = styled.p`
  color: #626262;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
`
