import React, { useMemo } from "react"
import Lottie from "react-lottie"

import loadingAnimation from "assets/lotties/loadingAnimation.json"

import { ContainerFullLoading } from "./styles"

function LottieLoading({ loading, fullLoading, size }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
  }
  const renderLottie = useMemo(
    () => (
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        height={size || 300}
        width={size || 300}
      />
    ),
    [defaultOptions, size]
  )

  if (!loading) return null

  if (!fullLoading) return renderLottie
  return <ContainerFullLoading>{renderLottie}</ContainerFullLoading>
}

export default LottieLoading
