import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

const EmailIcon = (props) => (
  <SvgIcon
    className="startse-icon"
    viewBox="0 0 16 20"
    fontSize="small"
    {...props}
  >
    <path d="M4.542,20A4.547,4.547,0,0,1,0,15.458V11.169A4.55,4.55,0,0,1,3.052,6.878v-1.6a5.311,5.311,0,0,1,10.617-.2l0,.219V6.878a4.55,4.55,0,0,1,3.052,4.291v4.289A4.547,4.547,0,0,1,12.183,20ZM1.5,11.169v4.289A3.045,3.045,0,0,0,4.542,18.5h7.641a3.045,3.045,0,0,0,3.042-3.042V11.169a3.046,3.046,0,0,0-3.042-3.042H4.542A3.046,3.046,0,0,0,1.5,11.169ZM12.173,6.627V5.3a3.811,3.811,0,0,0-7.616-.215l-.005.2V6.627Zm-4.554,7.9-.006-.1V12.2a.75.75,0,0,1,1.493-.1l.006.1v2.221a.75.75,0,0,1-1.493.1Z" />
  </SvgIcon>
)
export default EmailIcon
