import React from "react"
import { Redirect, useLocation } from "react-router-dom"

export default ({ to, ...props }) => {
  const { search } = useLocation()

  return (
    <Redirect
      {...props}
      to={{
        pathname: to,
        search,
      }}
    />
  )
}
