/* eslint-disable react/prop-types */
import React, { useState } from "react"

import { useForm } from "react-hook-form"
import yup from "modules/form-validation"

import { withNamespaces } from "react-i18next"

import TextField from "components/Form/TextField"
import AutoComplete from "components/Form/AutoComplete"
import LoginButton from "components/Button/LoginButton"
import Checkbox from "components/Form/Checkbox/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { SendIcon } from "components/icons"
import {
  jobtitle as jobtitleData,
  employees as employeesData,
  revenues as revenuesData,
  segment as segmentData,
  department as departmentData,
} from "modules/constants"
import {
  ProfileFormBox,
  ActionAreaEditProfile,
  ProfileFormSingleCheckboxArea,
} from "../../style"

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  segment: yup.string().required(),
  department: yup.string().required(),
  revenues: yup.string().required(),
  employees: yup.string().required(),
  jobtitle: yup.string().required(),
})

const Form = ({ user, done, show, t }) => {
  const defaultValues = {
    name: user.company?.name,
    segment: user.segment,
    department: user.department,
    revenues: (user.company || {}).revenues,
    employees: (user.company || {}).employees,
    jobtitle: user.jobtitle,
  }
  const [unemployed, setUnemployed] = useState(user.jobtitle === "unemployed")

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })

  const onSubmit = async (data) => {
    if (unemployed)
      return done({
        jobtitle: "unemployed",
        segment: null,
        department: null,
        company: {
          name: null,
          revenues: null,
          employees: null,
        },
      })

    const segment =
      segmentData.find((e) => e.label === data.segment)?.value || data.segment
    const department =
      departmentData.find((e) => e.label === data.department)?.value ||
      data.department
    const revenues =
      revenuesData.find((e) => e.label === data.revenues)?.value ||
      data.revenues
    const employees =
      employeesData.find((e) => e.label === data.employees)?.value ||
      data.employees
    const jobtitle =
      jobtitleData.find((e) => e.label === data.jobtitle)?.value ||
      data.jobtitle

    const newData = {
      jobtitle: unemployed ? "unemployed" : jobtitle || null,
      segment: segment || null,
      department: department || null,
      company: {
        name: data.name || null,
        revenues: revenues === "Não se aplica" ? null : revenues || null,
        employees: employees === "Não se aplica" ? null : employees || null,
      },
    }
    done(newData)
  }

  return (
    <ProfileFormBox
      style={{ display: show ? "block" : "none" }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <h1>{t("title_professional_data")}</h1>
      <h2>{t("subtitle_professional_data")}</h2>
      <ProfileFormSingleCheckboxArea>
        <FormControlLabel
          control={
            <Checkbox
              name="unemployed"
              onChange={() => setUnemployed(!unemployed)}
              checked={unemployed}
              size="small"
            />
          }
          label={t("check_open_to_work")}
        />
      </ProfileFormSingleCheckboxArea>
      <TextField
        id="name"
        inputRef={register}
        label={t("input_company_name")}
        size="medium"
        name="name"
        errors={unemployed ? {} : errors}
        disabled={unemployed}
        autoFocus={true}
      />

      <AutoComplete
        id="segment"
        options={segmentData}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => value?.label === option?.label}
        disableClearable
        size="medium"
        defaultValue={
          defaultValues.segment
            ? segmentData.find((e) => e.value === defaultValues.segment)
            : null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("select_company_segment")}
            size="medium"
            inputRef={register}
            name="segment"
            errors={unemployed ? {} : errors}
            disabled={unemployed}
          />
        )}
      />

      <AutoComplete
        id="department"
        options={departmentData}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => value?.label === option?.label}
        disableClearable
        size="medium"
        defaultValue={
          defaultValues.department
            ? departmentData.find((e) => e.value === defaultValues.department)
            : null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("select_company_department")}
            size="medium"
            inputRef={register}
            name="department"
            errors={unemployed ? {} : errors}
            disabled={unemployed}
          />
        )}
      />

      <AutoComplete
        id="revenues"
        options={revenuesData}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => value?.label === option?.label}
        disableClearable
        size="medium"
        defaultValue={
          defaultValues.revenues
            ? revenuesData.find((e) => e.value === defaultValues.revenues)
            : null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("select_company_revenue")}
            size="medium"
            inputRef={register}
            name="revenues"
            errors={unemployed ? {} : errors}
            disabled={unemployed}
          />
        )}
      />

      <AutoComplete
        id="employees"
        options={employeesData}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => value?.label === option?.label}
        disableClearable
        size="medium"
        defaultValue={
          defaultValues.employees
            ? employeesData.find((e) => e.value === defaultValues.employees)
            : null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("select_company_employees")}
            size="medium"
            inputRef={register}
            name="employees"
            errors={unemployed ? {} : errors}
            disabled={unemployed}
          />
        )}
      />

      <AutoComplete
        id="jobtitle"
        options={jobtitleData}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => value?.label === option?.label}
        disableClearable
        size="medium"
        freeSolo
        defaultValue={
          defaultValues.jobtitle
            ? jobtitleData.find((e) => e.value === defaultValues.jobtitle) || {
                label: defaultValues.jobtitle,
              }
            : null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("select_company_position")}
            size="medium"
            inputRef={register}
            name="jobtitle"
            errors={unemployed ? {} : errors}
            disabled={unemployed}
          />
        )}
      />

      <ActionAreaEditProfile>
        <LoginButton
          type="submit"
          endIcon={
            <SendIcon
              style={{ color: "#fff" }}
              onClick={unemployed ? () => onSubmit() : () => {}}
            />
          }
        >
          {t("button_continue")}
        </LoginButton>
      </ActionAreaEditProfile>
    </ProfileFormBox>
  )
}

export default withNamespaces()(Form)
