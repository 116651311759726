import React from "react"

import Main from "./"
import Badge from "@material-ui/core/Badge"
import Avatar from "@material-ui/core/Avatar"
import { CameraIcon } from "components/icons"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  badge: {
    cursor: "pointer",
    "& .MuiBadge-badge": {
      borderRadius: "50%",
      backgroundColor: "#ACB1C0",
      height: "36px",
      width: "36px",
      color: "#fff",
    },
  },
  icon: {
    fontSize: "18px",
  },
  avatarContainer: {
    border: "4px solid #d5d5d5",
    borderRadius: "50%",
    padding: "2px",
  },
  avatar: {
    width: "90px",
    height: "90px",
    backgroundColor: "rgb(78, 78, 78)",
  },
}))

const ChildrenEl = ({ link, image, clicked }) => {
  const classes = useStyles()

  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      onClick={() => clicked()}
      className={classes.badge}
      badgeContent={<CameraIcon className={classes.icon} />}
    >
      <div className={classes.avatarContainer}>
        <Avatar
          src={image && image.src ? image.src : link}
          className={classes.avatar}
        />
      </div>
    </Badge>
  )
}

const ProfileUploader = ({ setFile, link }) => {
  return (
    <Main setFile={setFile} aspectRatio={1 / 1}>
      <ChildrenEl link={link} />
    </Main>
  )
}

export default ProfileUploader
