import React, { useCallback } from "react"
import { useForm } from "react-hook-form"

import yup from "modules/form-validation"
import { useApp } from "modules/hooks/onboarding/app"
import { useUser } from "modules/hooks/onboarding/user"
import { closeModal } from "modules/helpers"

import TextField from "components/Form/TextField"
import Button from "components/Button"
import Header from "../styled/Header"
import Footer from "../styled/Footer"
import FillLater from "../styled/FillLater"
import BackButton from "components/BackButton"
import Title from "../styled/Title"
import Subtitle from "../styled/Subtitle"

const validationSchema = yup.object().shape({
  challenge: yup.string().max(200),
})

const Challenge = ({
  nextStep,
  backStep,
  show,
  disableLater,
  disableBack,
  done,
}) => {
  const { user, updateUser } = useUser()
  const { setLoading } = useApp()

  const defaultValues = {
    challenge: user.challenge,
  }

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true)
      try {
        await updateUser({
          ...user,
          ...data,
        })
      } catch {
        return closeModal(user)
      }

      setLoading(false)
      nextStep()
    },
    [nextStep, updateUser, user, setLoading]
  )

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: show ? "block" : "none",
        width: "100%",
        maxWidth: "680px",
        margin: "0 auto",
      }}
    >
      <Header hideBorder>
        <Title>Seus Principais desafios</Title>
        <Subtitle>
          Com base nas suas respostas anteriores, em poucas palavras, quais são
          os seus principais desafios agora? E como você acha que podemos te
          ajudar a superá-lo?
        </Subtitle>
      </Header>

      <TextField
        id="challenge"
        label="Escreva aqui..."
        inputRef={register}
        name="challenge"
        multiline
        rows={5}
        errors={errors}
      />
      <Footer>
        {!disableBack && <BackButton onClick={backStep} />}
        {!disableLater && <FillLater onClick={() => closeModal(user)} />}
        <Button type="submit">{done ? "Concluir" : "Avançar"}</Button>
      </Footer>
    </form>
  )
}

export default Challenge
