import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useHistory, useLocation } from "react-router-dom"

import { withNamespaces } from "react-i18next"

import yup from "modules/form-validation"
import auth from "modules/services/auth"
import { useApp } from "modules/hooks/login/app"
import { useClient } from "modules/hooks/login/client"
import { gtm, loginRedirect } from "modules/helpers"

import { GroupFormSocialLogos, FormButtons } from "./style"
import TextField from "components/Form/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import Error from "./Error"
import ErrorAction from "./ErrorAction"
import LinkQS from "components/Router/LinkQS"
import LoginButton from "components/Button/LoginButton"
import { EmailIcon } from "components/icons"

const Login = ({ t }) => {
  const history = useHistory()
  const { search } = useLocation()
  const {
    getQueryString,
    client,
    view,
    checkForSession,
    config,
    setViewTitle,
  } = useClient()
  const [error, setError] = useState(null)
  const { setLoading } = useApp()
  const [email, setEmail] = useState("")

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    agree: yup.bool().oneOf([true], `${t("input_main_modal_message")}`),
  })

  const { register, handleSubmit, errors } = useForm({ validationSchema })

  useEffect(() => {
    if (client && client.redirect_uri) checkForSession()

    // eslint-disable-next-line
  }, [client])

  useEffect(() => {
    setViewTitle(`${t("input_main_modal_social_media_or_email")}`)
    // eslint-disable-next-line
  }, [])

  const setCustomError = (custom, submitedEmail) => {
    if (custom.error === "not_found" && submitedEmail)
      return history.push("/authentication/signup" + search, {
        email: submitedEmail,
      })
    setError(custom)
  }

  const errorAction = (error) => {
    switch (error) {
      case "email_not_confirmed":
        return (
          <ErrorAction>
            {t("input_main_modal_new_password")}{" "}
            <LinkQS
              to="/authentication/password/forgot"
              state={{ email, send: true, activation: true }}
            >
              {t("input_main_modal_clicking_here")}
            </LinkQS>
            .
          </ErrorAction>
        )

      case "password_not_created":
        return (
          <ErrorAction>
            {t("input_main_modal_send_email_to_create_password")}{" "}
            <LinkQS
              to="/authentication/password/forgot"
              state={{ email, send: true }}
            >
              {t("input_main_modal_click_here")}
            </LinkQS>
            .
          </ErrorAction>
        )

      default:
        return null
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    setEmail(data.email)
    try {
      const response = await auth.post("auth/signin/email", data)
      if (!response || !response.status || response.status !== 200)
        throw new Error()

      if (view === "prime") gtm("login", data.email)

      history.push("/authentication/signin/local" + search, { ...data })
    } catch (error) {
      if (
        !error ||
        !error.response ||
        !error.response.data ||
        !error.response.data.error
      )
        return setError({
          message: `${t("text_error_message_login")}`,
        })

      setCustomError(error.response.data, data.email)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <h1>{t("title_main_modal_sign_up")}</h1>
      <h1 className="login-title">
        {view === "prime"
          ? `${t("text_prime_main_modal")}`
          : `${t("subtitle_login")}`}
      </h1>
      {config?.description && <p>{config.description}</p>}
      {search.includes("showDescription") && (
        <h3>
          {t("text_200_hours_main_modal")}{" "}
          <strong>{t("text_free_main_modal")}</strong>{" "}
          {t("text_for_you_main_modal")}.
          <br />
          <br />
          {t("text_your_data_main_modal")}
        </h3>
      )}
      <h2 className="login-subtitle">{t("text_login_connect")}:</h2>

      <GroupFormSocialLogos className="login-social-area">
        <a
          href={`${
            process.env.REACT_APP_AUTH_URI
          }auth/linkedin/${getQueryString()}`}
          onClick={() => {
            loginRedirect(
              `${
                process.env.REACT_APP_AUTH_URI
              }auth/linkedin/${getQueryString()}`
            )
          }}
        >
          <img src="/img/linkedin.svg" alt="Logo Linkedin" />
        </a>
        <a
          href={`${
            process.env.REACT_APP_AUTH_URI
          }auth/google/${getQueryString()}`}
          onClick={() => {
            loginRedirect(
              `${process.env.REACT_APP_AUTH_URI}auth/google/${getQueryString()}`
            )
          }}
        >
          <img className="border" src="/img/google.svg" alt="Logo Google" />
        </a>
        <a
          href={`${
            process.env.REACT_APP_AUTH_URI
          }auth/facebook/${getQueryString()}`}
          onClick={() => {
            loginRedirect(
              `${
                process.env.REACT_APP_AUTH_URI
              }auth/facebook/${getQueryString()}`
            )
          }}
        >
          <img src="/img/facebook.svg" alt="Logo Facebook" />
        </a>
      </GroupFormSocialLogos>

      <p className="divider login-divider">
        <span>{t("text_or_subscribe")}</span>
      </p>
      <h3 className="login-divider-subtitle">{t("text_login_use_email")}</h3>

      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <TextField
          id="email"
          inputRef={register}
          label={t("input_main_modal_email")}
          size="medium"
          name="email"
          errors={errors}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />

        <Error text={error ? error.message : null} />
        {errorAction(error ? error.error : null)}

        <FormButtons>
          <LoginButton fullWidth type="submit">
            {t("button_next")}
          </LoginButton>
        </FormButtons>
      </form>
    </>
  )
}

export default withNamespaces()(Login)
