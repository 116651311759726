import Default from "pages/Login/Default"
import Lawtech from "pages/Login/Lawtech"
import Now from "pages/Login/Now"
import Prime from "pages/Login/Prime"
import Svwc from "pages/Login/SVWC"
import VarejoTech from "pages/Login/VarejoTech"
import SummitLoading from "pages/Login/Summit/Loading"

export default {
  standard: {
    component: Default,
    privacy: "https://docs.startse.com/docs/politica-de-privacidade",
  },
  lawtech: {
    component: Lawtech,
    privacy:
      "https://f.hubspotusercontent00.net/hubfs/3296805/politica-privacidade-varejotech.pdf",
    terms:
      "https://f.hubspotusercontent00.net/hubfs/3296805/termos-uso-varejotech.pdf",
  },
  now: {
    component: Now,
    privacy: "https://docs.startse.com/docs/politica-de-privacidade",
    description: "Para acessar o conhecimento transformador do agora!",
  },
  prime: {
    component: Prime,
  },
  svwc: {
    component: Svwc,
    privacy: "https://www.startse.com/svwc/politica-de-privacidade",
    terms: "https://www.startse.com/termos-de-uso-svwc/",
  },
  varejotech: {
    component: VarejoTech,
    privacy:
      "https://f.hubspotusercontent00.net/hubfs/3296805/politica-privacidade-varejotech.pdf",
    terms:
      "https://f.hubspotusercontent00.net/hubfs/3296805/termos-uso-varejotech.pdf",
  },
  summit: {
    component: Default,
    privacy: "https://docs.startse.com/docs/politica-de-privacidade",
    processingPage: SummitLoading,
  },
  subscribe: {
    component: Default,
    privacy: "https://docs.startse.com/docs/politica-de-privacidade",
  },
  platform: {
    component: Now,
    privacy: "https://docs.startse.com/docs/politica-de-privacidade",
  },
}
