import React from "react"
import styled from "styled-components"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"

export const SuccessContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  text-align: center;

  & h3 {
    color: #2db5b2;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & svg {
    margin-right: 10px;
  }
  & p {
    color: #7a8fa5;
    text-align: center;
  }
`

const Success = ({ title, subtitle }) => {
  if (!title) return null

  return (
    <SuccessContainer>
      <h3>
        <CheckCircleOutlineIcon /> {title}
      </h3>

      <p>{subtitle}</p>
    </SuccessContainer>
  )
}

export default Success
