/* eslint-disable react/display-name */
import React from "react"
import { GlobalStyle, GroupSingleNow, GroupFormNow } from "../style"

import LoginRouter from "../Router"

export default () => {
  return (
    <GroupSingleNow>
      <GlobalStyle />
      <GroupFormNow>
        <img src="/img/startse-logo.svg" alt="Startse Logo" />
        <LoginRouter />
      </GroupFormNow>
    </GroupSingleNow>
  )
}
