import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

const CameraIcon = (props) => (
  <SvgIcon
    className="startse-icon"
    viewBox="0 0 11 20"
    fontSize="small"
    {...props}
  >
    <path d="M10.97.3a1.017,1.017,0,0,1,.1,1.343l-.1.116L2.54,10.025,10.97,18.29a1.017,1.017,0,0,1,.1,1.343l-.1.116a1.068,1.068,0,0,1-1.37.1l-.118-.1L.308,10.755a1.017,1.017,0,0,1-.1-1.343l.1-.116L9.482.3A1.067,1.067,0,0,1,10.97.3Z" />
  </SvgIcon>
)
export default CameraIcon
