import React from "react"
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined"
import {
  LoadingPage,
  LoadingCenter,
  LoadingBottom,
  BorderLinearProgress,
} from "../style"

const Loading = ({ name }) => {
  return (
    <LoadingPage>
      <LoadingCenter>
        <VerifiedUserOutlinedIcon />
        <h1>Parabens {name}!</h1>
        <p>Dados atualizados com sucesso.</p>
      </LoadingCenter>
      <LoadingBottom>
        <p>
          Aguarde, redirecionando de
          <br /> volta para a plataforma
        </p>
        <BorderLinearProgress />
      </LoadingBottom>
    </LoadingPage>
  )
}

export default Loading
