import React from "react"
import ButtonUI from "@material-ui/core/Button"
import { styled } from "@material-ui/core/styles"

const ButtonStyled = styled(ButtonUI)({
  borderRadius: 50,
  padding: "12px 0",
  width: 255,
  margin: "auto",
  textTransform: "initial",
  fontSize: 20,
})

const DefaultButton = (props) => (
  <ButtonStyled variant="contained" color="primary" size="large" {...props} />
)

export default DefaultButton
