import TagManager from "react-gtm-module"

export function concatName(first, last) {
  return `${first} ${last}`
}

export function splitName(name) {
  const parts = name.split(" ")

  if (parts.length < 2) return [name, "-"]

  return [parts.shift(), parts.join(" ")]
}

export function closeModal(user) {
  window.top.postMessage(
    JSON.stringify({
      action: "close",
      user,
    }),
    "*"
  )
}

export function showModal(show) {
  window.top.postMessage(
    JSON.stringify({
      action: "show",
      show,
    }),
    "*"
  )
}

export function changeStep(current) {
  window.top.postMessage(
    JSON.stringify({
      action: "change",
      current,
    }),
    "*"
  )
}

export function loginRedirect(redirect) {
  window.top.postMessage(
    JSON.stringify({
      action: "login",
      redirect,
    }),
    "*"
  )
}

/**
 * Send a data layer event
 *
 * @param {('login'|'registration')} event
 * @param {string} first_name
 * @param {string} last_name
 * @param {string} email
 */
export function gtm(event, email, first_name, last_name) {
  const tagManagerArgs = {
    gtmId: "GTM-NJS8PD6",
    dataLayer: {
      event,
      data: {
        lead_type: "prime",
        first_name,
        last_name,
        email,
      },
    },
  }

  TagManager.dataLayer(tagManagerArgs)
}

export function returnHubspotPropKey(prop) {
  const keyNames = {
    companyName: "nome_da_empresa",
    segment: "segmento",
    jobtitle: "cargo_from_list",
    department: "departamento",
    country: "country",
    state: "estado",
    city: "cidade",
    phone: "telefone",
  }

  return keyNames[prop]
}

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export function getOriginModuleByUrl(url) {
  const providers = {
    "app.startse": "contents",
    "lms.startse": "lms",
    "conferences.startse": "conferences",
  }

  let provider = "onboarding"
  for (const [key, value] of Object.entries(providers)) {
    if (url && url.includes(key)) {
      provider = value
    }
  }

  return provider
}
