import styled from "styled-components"
import { withStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import MuiRadioGroup from "@material-ui/core/RadioGroup"

export const ProfileArea = styled.div`
  text-align: center;
  margin-bottom: 40px;
`
export const RadioGroup = withStyles(() => ({
  root: {
    marginTop: "40px",
    "& .MuiFormControlLabel-label": {
      color: "#818181",
      fontSize: "18px",
      lineHeight: "24px",
      fontFamily: "Barlow",
      fontWeight: 500,
    },
  },
}))(MuiRadioGroup)

export const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "#9B9B9B",
    boxShadow: "0px 1px 3px #00000066",
    fontSize: 14,
    padding: "10px 20px",
  },
}))(Tooltip)

export const SharedData = styled.span`
  color: #0080ed;
  font-weight: 600;
  font-family: "Barlow";
  font-size: 16px;
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  svg {
    color: #0080ed;
    margin-right: 5px;
  }
`
