import React from "react"
import styled from "styled-components"
import CheckIcon from "@material-ui/icons/Check"

export const CustomLabel = styled.div`
  text-align: left;
  line-height: 1.1;
  font-size: 14px;
`

const IconContainer = styled.span`
  width: 1em;
  height: 1em;
  background-color: #0080ed;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckedIcon = () => (
  <IconContainer>
    <CheckIcon style={{ color: "#FFF" }} />
  </IconContainer>
)

export const UncheckIcon = styled.span`
  width: 1em;
  height: 1em;
  border: 2px solid #acb1c0;
  border-radius: 8px;
`
