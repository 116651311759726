import React from "react"
import ButtonUI from "@material-ui/core/Button"
import { styled } from "@material-ui/core/styles"

const ButtonStyled = styled(ButtonUI)({
  "border-radius": "30px",
  padding: "16px 32px",
  fontWeight: 300,
  fontSize: "18px",
})

const LoginButton = (props) => (
  <ButtonStyled variant="contained" color="primary" size="large" {...props} />
)

export default LoginButton
