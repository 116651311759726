import styled from "styled-components"
import { withStyles } from "@material-ui/core/styles"
import { IconButton } from "@material-ui/core"
import MobileStepper from "@material-ui/core/MobileStepper"
import FormControl from "@material-ui/core/FormControl"

export const SubscribeArea = styled.div`
  padding: 8px 20px 32px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1100px) {
    max-width: 600px;
    border: ${(props) => (props.noBorder ? "none" : "1px solid #acb1c0")};
    border-bottom: none;
    border-top: none;
    margin: auto;
    background-color: #fff;
  }

  h1 {
    color: #333333;
    font-family: Barlow, sans-serif;
    font-size: 26px;
    text-align: center;
    margin-top: 30px;
  }
  h2 {
    text-align: center;
    color: #818181;
    font-size: 16px;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 30px;
  }

  .form-area {
    max-width: 520px;
    margin: 0 auto;
  }
`

export const SubscribeBackground = styled.div`
  min-height: 100vh;

  @media screen and (min-width: 1100px) {
    background-color: ${(props) =>
      props.transparent ? "transparent" : "#f7f8fa"};
    width: 100%;
  }
`

export const HeaderEditProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 30px;
  margin-top: 10px;

  .left-btn {
    left: 0;
  }
  .right-btn {
    right: 0;
  }
`

export const CloseButton = withStyles(() => ({
  root: {
    position: "absolute",
    "& svg": {
      color: "#333333",
    },
  },
}))(IconButton)

export const Dots = withStyles(() => ({
  root: {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
  },
}))(MobileStepper)

export const ProfileFormBox = styled.form`
  margin-bottom: 100px;

  h1 {
    margin: 0 0 10px;
    color: #333333;
    font-family: Barlow, sans-serif;
    font-weight: 600;
    font-size: 24px;
  }
  h2 {
    margin: 0 0 40px;
    color: #818181;
    font-family: Barlow, sans-serif;
    font-weight: 600;
    font-size: 16px;
  }
`
export const ActionAreaEditProfile = styled.div`
  text-align: center;
  position: fixed;
  bottom: 30px;
  width: 100%;
  max-width: 600px;
  padding: 20px 40px;
  left: 50%;
  transform: translateX(-50%);
`

export const ProfileFormSingleCheckboxArea = styled.div`
  text-align: center;
  margin-bottom: 15px;

  .MuiFormControlLabel-root {
    margin-right: 0px;
  }
  .MuiIconButton-label {
    font-size: 16px;
  }
  .MuiIconButton-label span {
    border-radius: 6px;
  }
  .MuiIconButton-label svg {
    font-size: 14px;
  }
  .MuiFormControlLabel-label {
    color: #818181;
    font-family: Barlow;
    font-weight: 500;
    font-size: 13px;
  }
`

export const CheckboxesArea = withStyles(() => ({
  root: {
    "& .MuiFormControlLabel-root": {
      marginBottom: "15px",
    },

    "& .MuiFormControlLabel-label": {
      color: "#818181",
      marginLeft: "10px",
      fontFamily: "Barlow",
      fontWeight: "500",
      fontSize: "19px",
      lineHeight: "21px",
    },
  },
}))(FormControl)
