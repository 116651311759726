import React, { useState } from "react"
import PhoneInput from "react-phone-input-2"

import "react-phone-input-2/lib/style.css"
import "./style.css"

export const phoneRegExp = /^\+[1-9]{1,4}[ ()\\-]{0,2}[1-9]+[ ()\\-]{0,2}[0-9 ()\\-]{6,}/

const inputStyle = {
  padding: "3px 0px 6px 48px",
  fontSize: "1.8vh",
  color: "#737373",
  fontFamily: '"Barlow",sans-serif',
  minWidth: "30px",
  backgroundColor: "#d3d3d3",
  borderRadius: "10px",
  border: "1px solid #d3d3d3",
}

const containerStyle = {
  marginTop: "0.3em",
  backgroundColor: "#d3d3d3",
  borderRadius: "10px",
}

const buttonStyle = {
  backgroundColor: "#d3d3d3",
  borderRadius: "10px",
  border: "1px solid #d3d3d3",
}

const alertStyle = {
  fontSize: "14px",
  marginTop: "5px",
  color: "#f44336",
  fontFamily: "Barlow",
  fontWeight: 400,
  lineHeight: 1.66,
  letterSpacing: "0.03333em",
}

const labelStyle = {
  display: "block",
  fontFamily: '"Barlow",sans-serif',
  fontSize: "1em",
  marginTop: "0.8em",
}

const PhoneInputComp = ({ errors, inputRef, ...props }) => {
  const [phone, setPhone] = useState("")

  return (
    <div style={{ width: "100%", maxWidth: "25em" }}>
      <label style={labelStyle}>Telefone</label>
      <PhoneInput
        {...props}
        country={"br"}
        onChange={(value, country, e, formattedValue) =>
          setPhone(formattedValue)
        }
        placeholder="+55 (DDD) XXXXX-XXXX"
        countryCodeEditable={false}
        inputStyle={inputStyle}
        containerStyle={containerStyle}
        buttonStyle={buttonStyle}
      />

      <input type="hidden" ref={inputRef} name="phone" value={phone} />
      {errors.phone && errors.phone.type === "matches" && (
        <div>
          <span role="alert" style={alertStyle}>
            Telefone inválido
          </span>
        </div>
      )}
      {errors.phone && errors.phone.type === "required" && (
        <div>
          <span role="alert" style={alertStyle}>
            Obrigatório
          </span>
        </div>
      )}
    </div>
  )
}

export default PhoneInputComp
