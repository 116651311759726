/* eslint-disable react/prop-types */
import React, { useState } from "react"

import { useForm } from "react-hook-form"

import { withNamespaces } from "react-i18next"

import yup from "modules/form-validation"
import {
  jobtitle as jobtitleData,
  employees as employeesData,
  revenues as revenuesData,
  segment as segmentData,
  department as departmentData,
} from "modules/constants"

import TextField from "components/v4/Form/TextField"
import AutoComplete from "components/v4/Form/AutoComplete"
import DefaultButton from "components/v4/Button/DefaultButton"
import Checkbox from "components/v4/Form/Checkbox/Checkbox"

import FormControlLabel from "@material-ui/core/FormControlLabel"

import { useTracking } from "modules/hooks/tracking"

import {
  ProfileFormBox,
  ActionAreaEditProfile,
  ContainerTextField,
  ProfileFormSingleCheckboxArea,
} from "../../style"

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  segment: yup.string().required(),
  department: yup.string().required(),
  revenues: yup.string().required(),
  employees: yup.string().required(),
  jobtitle: yup.string().required(),
})

const Form = ({ user, done, show, t }) => {
  const { track } = useTracking()

  const defaultValues = {
    name: user.company?.name,
    segment: user.segment,
    department: user.department,
    revenues: (user.company || {}).revenues,
    employees: (user.company || {}).employees,
    jobtitle: user.jobtitle,
  }
  const [unemployed, setUnemployed] = useState(user.jobtitle === "unemployed")

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })

  const onSubmit = async (data) => {
    if (unemployed)
      return done({
        jobtitle: "unemployed",
        segment: null,
        department: null,
        company: {
          name: null,
          revenues: null,
          employees: null,
        },
      })

    const segment =
      segmentData.find((e) => e.label === data.segment)?.value || data.segment
    const department =
      departmentData.find((e) => e.label === data.department)?.value ||
      data.department
    const revenues =
      revenuesData.find((e) => e.label === data.revenues)?.value ||
      data.revenues
    const employees =
      employeesData.find((e) => e.label === data.employees)?.value ||
      data.employees
    const jobtitle =
      jobtitleData.find((e) => e.label === data.jobtitle)?.value ||
      data.jobtitle

    const newData = {
      jobtitle: unemployed ? "unemployed" : jobtitle || null,
      segment: segment || null,
      department: department || null,
      company: {
        name: data.name || null,
        revenues:
          revenues === `${t("message_not_applicable")}`
            ? null
            : revenues || null,
        employees:
          employees === `${t("message_not_applicable")}`
            ? null
            : employees || null,
      },
    }
    done(newData)

    track("Clicked on Continue button - My Profile [2/3]")
  }

  return (
    <ProfileFormBox
      style={{ display: show ? "block" : "none" }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <h1>{t("title_professional_data")}</h1>
      <h2>{t("subtitle_professional_data")}</h2>
      <ProfileFormSingleCheckboxArea>
        <FormControlLabel
          control={
            <Checkbox
              name="unemployed"
              onChange={() => setUnemployed(!unemployed)}
              checked={unemployed}
              size="small"
            />
          }
          label={t("check_open_to_work")}
        />
      </ProfileFormSingleCheckboxArea>
      <ContainerTextField>
        <h3>{t("input_company_name")}</h3>

        <TextField
          id="name"
          inputRef={register}
          size="medium"
          name="name"
          errors={unemployed ? {} : errors}
          disabled={unemployed}
          autoFocus={true}
        />
        <h3>{t("select_company_segment")}</h3>

        <AutoComplete
          id="segment"
          options={segmentData}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => value?.label === option?.label}
          disableClearable
          size="medium"
          defaultValue={
            defaultValues.segment
              ? segmentData.find((e) => e.value === defaultValues.segment)
              : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="medium"
              inputRef={register}
              name="segment"
              errors={unemployed ? {} : errors}
              disabled={unemployed}
            />
          )}
        />
        <h3>{t("select_company_department")}</h3>

        <AutoComplete
          id="department"
          options={departmentData}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => value?.label === option?.label}
          disableClearable
          size="medium"
          defaultValue={
            defaultValues.department
              ? departmentData.find((e) => e.value === defaultValues.department)
              : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="medium"
              inputRef={register}
              name="department"
              errors={unemployed ? {} : errors}
              disabled={unemployed}
            />
          )}
        />
        <h3>{t("select_company_revenue")}</h3>

        <AutoComplete
          id="revenues"
          options={revenuesData}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => value?.label === option?.label}
          disableClearable
          size="medium"
          defaultValue={
            defaultValues.revenues
              ? revenuesData.find((e) => e.value === defaultValues.revenues)
              : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="medium"
              inputRef={register}
              name="revenues"
              errors={unemployed ? {} : errors}
              disabled={unemployed}
            />
          )}
        />
        <h3>{t("select_company_employees")}</h3>

        <AutoComplete
          id="employees"
          options={employeesData}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => value?.label === option?.label}
          disableClearable
          size="medium"
          defaultValue={
            defaultValues.employees
              ? employeesData.find((e) => e.value === defaultValues.employees)
              : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="medium"
              inputRef={register}
              name="employees"
              errors={unemployed ? {} : errors}
              disabled={unemployed}
            />
          )}
        />
        <h3>{t("select_company_position")}</h3>

        <AutoComplete
          id="jobtitle"
          options={jobtitleData}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => value?.label === option?.label}
          disableClearable
          size="medium"
          freeSolo
          defaultValue={
            defaultValues.jobtitle
              ? jobtitleData.find(
                  (e) => e.value === defaultValues.jobtitle
                ) || {
                  label: defaultValues.jobtitle,
                }
              : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="medium"
              inputRef={register}
              name="jobtitle"
              errors={unemployed ? {} : errors}
              disabled={unemployed}
            />
          )}
        />
      </ContainerTextField>
      <ActionAreaEditProfile>
        <DefaultButton type="submit">{t("button_continue")}</DefaultButton>
      </ActionAreaEditProfile>
    </ProfileFormBox>
  )
}

export default withNamespaces()(Form)
