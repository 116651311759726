import React from "react"
import { AppProvider as AppStateProvider } from "./app"
import { ClientProvider } from "./client"

export default function AppProvider({ children }) {
  return (
    <AppStateProvider>
      <ClientProvider>{children}</ClientProvider>
    </AppStateProvider>
  )
}
