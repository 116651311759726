import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { educationInterest } from "modules/constants"
import { useApp } from "modules/hooks/onboarding/app"
import { useUser } from "modules/hooks/onboarding/user"
import { closeModal } from "modules/helpers"

import Button from "../styled/Button"
import Header from "../styled/Header"
import Title from "../styled/Title"
import Subtitle from "../styled/Subtitle"
import Footer from "../styled/Footer"
import BackButton from "components/BackButton"
import FillLater from "../styled/FillLater"
import { Label, Body } from "./styled"
import ChipAutocomplete from "../Components/Inputs/ChipAutocomplete"

const maxSelectedSize = 3

const EducationInterest = ({
  nextStep,
  show,
  backStep,
  disableLater,
  done,
  disableBack,
}) => {
  const { user, updateUser } = useUser()
  const { setLoading } = useApp()
  const [options, setOptions] = useState(educationInterest)
  const [whatCanTeach, setWhatCanTeach] = useState(
    user.educationInterest?.whatCanTeach.map((item) => {
      let founded = educationInterest.find(
        (interest) => item === interest.value
      )

      if (!founded) {
        founded = { label: item, value: item }
      }
      return founded
    }) || []
  )
  const [wantToLearn, setWantToLearn] = useState(
    user.educationInterest?.wantToLearn.map((item) => {
      let founded = educationInterest.find(
        (interest) => item === interest.value
      )

      if (!founded) {
        founded = { label: item, value: item }
      }
      return founded
    }) || []
  )

  const { handleSubmit } = useForm()

  const onSubmit = async () => {
    setLoading(true)

    try {
      await updateUser({
        ...user,
        educationInterest: {
          whatCanTeach: whatCanTeach.map((item) => item.value),
          wantToLearn: wantToLearn.map((item) => item.value),
        },
      })
    } catch (error) {
      closeModal(user)
    }

    setLoading(false)
    nextStep()
  }

  const addOption = (value) => setOptions((old) => [...old, value])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={!show ? { display: "none" } : null}
    >
      <Header>
        <Title>Meus Interesses</Title>
        <Subtitle>
          Amplie o seu networking ajudando outras pessoas e também recebendo
          ajuda delas!
        </Subtitle>
      </Header>

      <Body>
        <Label>Você pode selecionar até três opções:</Label>
        <ChipAutocomplete
          options={options}
          values={wantToLearn}
          setValues={setWantToLearn}
          addOption={addOption}
          disabled={wantToLearn.length === maxSelectedSize}
          label="O que desejo aprender"
        />

        <ChipAutocomplete
          options={options}
          values={whatCanTeach}
          setValues={setWhatCanTeach}
          addOption={addOption}
          disabled={whatCanTeach.length === maxSelectedSize}
          label="O que posso ensinar"
        />
      </Body>

      <Footer>
        {!disableBack && <BackButton onClick={backStep} />}
        {!disableLater && <FillLater onClick={nextStep} />}
        <Button type="submit">{done ? "Concluir" : "Avançar"}</Button>
      </Footer>
    </form>
  )
}

export default EducationInterest
