import React from "react"
import { LoadingPage, BorderLinearProgress } from "./style"
import LoadingArea from "pages/Login/Summit/LoadingArea"

const Loading = () => {
  return (
    <LoadingArea>
      <LoadingPage>
        <p>Aguarde, processando sua inscrição</p>
        <BorderLinearProgress />
      </LoadingPage>
    </LoadingArea>
  )
}

export default Loading
