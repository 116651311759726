import React, { useEffect, useState } from "react"
import cookie from "modules/cookies"

import { useApp } from "modules/hooks/onboarding/app"
import { useUser } from "modules/hooks/onboarding/user"
import { closeModal } from "modules/helpers"

import ProfileStep from "./Components/ProfileStep"
import ProfessionalStep from "./Components/ProfessionalStep"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import {
  SubscribeArea,
  SubscribeBackground,
  HeaderEditProfile,
  CloseButton,
  Dots,
} from "./style"
import CloseIcon from "@material-ui/icons/Close"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

const totalSteps = 2

const Subscribe = () => {
  const { loading, setLoading } = useApp()
  const { user, getUser, updateUser } = useUser()
  const [activeStep, setActiveStep] = useState(0)

  const close = () => closeModal(user)

  useEffect(() => {
    const fetchData = async (_token) => {
      setLoading(true)

      try {
        await getUser(_token)
      } catch (error) {
        console.log(error)
        close()
      } finally {
        setLoading(false)
      }
    }

    const token = cookie.get("authStartseToken")
    if (!token) {
      return close()
    }

    fetchData(token)
    // eslint-disable-next-line
  }, [])

  const nextStep = () => {
    if (activeStep < totalSteps - 1) return setActiveStep(activeStep + 1)
    close()
  }

  const saveData = async (newData) => {
    try {
      setLoading(true)
      await updateUser({
        ...user,
        ...newData,
      })
      nextStep()
    } catch (error) {
      console.log(error)
      close()
    } finally {
      setLoading(false)
    }
  }

  return (
    <SubscribeBackground>
      {loading && (
        <Backdrop open={true} style={{ zIndex: 9 }}>
          <CircularProgress />
        </Backdrop>
      )}
      <SubscribeArea>
        <HeaderEditProfile>
          {activeStep === 1 && (
            <CloseButton onClick={() => setActiveStep(0)} className="left-btn">
              <ArrowBackIosIcon />
            </CloseButton>
          )}
          <CloseButton onClick={close} className="right-btn">
            <CloseIcon />
          </CloseButton>
        </HeaderEditProfile>
        {user && user.email && (
          <>
            <ProfileStep show={activeStep === 0} user={user} done={saveData} />
            <ProfessionalStep
              show={activeStep === 1}
              user={user}
              done={saveData}
            />
          </>
        )}
        <Dots
          variant="dots"
          steps={totalSteps}
          position="static"
          activeStep={activeStep}
        />
      </SubscribeArea>
    </SubscribeBackground>
  )
}

export default Subscribe
