import React, { useState, useEffect } from "react"

import Input from "@material-ui/core/Input"
import { FieldArea, Label } from "./styled"
import { makeStyles } from "@material-ui/core/styles"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import OutlinedInput from "@material-ui/core/OutlinedInput"

const useStyles = makeStyles(() => ({
  root: {
    "@media screen and (max-width: 600px)": {
      marginLeft: "0px !important",
      width: "100%",
    },
    marginLeft: "10px !important",
  },
}))

const TextField = ({
  errors,
  label,
  hasCompleted,
  hide,
  leftComponent,
  rightComponent,
  fieldAreaStyle,
  labelStyle,
  inputAreaStyle,
  variant,
  ...props
}) => {
  const classes = useStyles()
  const error = errors && errors[props.name] ? errors[props.name] : null
  const [validateValue, setValidateValue] = useState("")

  useEffect(() => {
    if (!hasCompleted) return

    const delayDebounceFn = setTimeout(() => {
      if ((validateValue || "").length < 1) return

      hasCompleted(props.name)
    }, 500)

    return () => clearTimeout(delayDebounceFn)

    // eslint-disable-next-line
  }, [validateValue])

  if (hide) return null

  const CustomInput = variant === "outlined" ? OutlinedInput : Input

  return (
    <FieldArea style={fieldAreaStyle}>
      {rightComponent}
      <Label>{label}</Label>
      <FormControl
        error={!!error}
        className={classes.root}
        style={inputAreaStyle}
      >
        <CustomInput
          error={!!error}
          variant="outlined"
          size="small"
          onChange={(e) => setValidateValue(e.target.value)}
          {...props}
        />
        {error ? (
          <FormHelperText>{error.message}</FormHelperText>
        ) : (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
      </FormControl>
      {leftComponent}
    </FieldArea>
  )
}

export default TextField
