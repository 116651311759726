import React, { useEffect, useMemo, useState } from "react"
import cookie from "modules/cookies"

import { useApp } from "modules/hooks/onboarding/app"
import { useUser } from "modules/hooks/onboarding/user"
import qs from "qs"
import { useLocation } from "react-router-dom"

import PainsForm from "./Components/PainsForm"
import ProfileForm from "./Components/ProfileForm"
import Loading from "./Components/Loading"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { sleep } from "modules/helpers"
import { SubscribeArea, Header, SubscribeBackground } from "./style"
import { useTracking } from "modules/hooks/tracking"

const Onboarding = () => {
  const { loading, setLoading } = useApp()
  const { user, getUser, updateUser } = useUser()
  const { search } = useLocation()
  const { track, set } = useTracking()
  const [isContexting, setIsContexting] = useState(false)
  const [form, setForm] = useState(0)

  const queryString = useMemo(() => {
    let _search = search ? search : ""
    return qs.parse(_search.replace("?", ""))
  }, [search])

  const redirect = () => (window.location.href = queryString?.redirect_to)

  useEffect(() => {
    const fetchData = async (_token) => {
      setLoading(true)

      try {
        await getUser(_token)
      } catch (error) {
        console.log(error)
        redirect()
      } finally {
        setLoading(false)
      }
    }

    const token = cookie.get("authStartseToken")
    if (!token) {
      return redirect()
    }

    fetchData(token)
    // eslint-disable-next-line
  }, [])

  const saveData = async (data) => {
    try {
      setLoading(true)
      await updateUser({
        ...user,
        ...data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setForm(1)
      setLoading(false)
    }
  }

  const saveDataPains = async (newPains) => {
    try {
      setIsContexting(true)
      await updateUser({
        ...user,
        pains: newPains,
      })
      track("Fill pains form")
      set({ pains: newPains }, user.email)
    } catch (error) {
      console.log(error)
    } finally {
      await sleep(3000)
      redirect()
    }
  }

  if (loading && !isContexting)
    return (
      <Backdrop open={true} style={{ zIndex: 9 }}>
        <CircularProgress />
      </Backdrop>
    )

  return (
    <SubscribeBackground>
      <SubscribeArea>
        <Header>
          <img src="/img/startse-logo.svg" alt="StartSe logo" />
        </Header>
        {user && user.email && !isContexting && (
          <>
            {form === 0 && <ProfileForm user={user} saveData={saveData} />}
            {form === 1 && <PainsForm user={user} saveData={saveDataPains} />}
          </>
        )}
        {user && user.email && isContexting && (
          <Loading name={user.firstName} />
        )}
      </SubscribeArea>
    </SubscribeBackground>
  )
}

export default Onboarding
