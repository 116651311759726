import React from "react"
import ChipUI from "@material-ui/core/Chip"
import { makeStyles } from "@material-ui/core/styles"
import DoneIcon from "@material-ui/icons/Done"

const useStyles = makeStyles({
  root: {
    fontSize: "12px",
    paddingRight: "14px",
    flexBasis: "210px",
    "& svg": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 600px)": {
      gridColumnStart: (props) => (props.mobileGrow ? "1" : "auto"),
      gridColumnEnd: (props) => (props.mobileGrow ? "3" : "auto"),
    },
    "@media screen and (max-width: 450px)": {
      gridColumnStart: "auto !important",
      gridColumnEnd: "auto !important",
    },
  },
})

const Chip = ({ selected, mobileGrow, ...props }) => {
  const classes = useStyles({ mobileGrow })

  return (
    <ChipUI
      className={classes.root}
      variant={selected ? "default" : "outlined"}
      color="primary"
      icon={<DoneIcon style={{ opacity: selected ? 1 : 0 }} />}
      {...props}
    />
  )
}

export default Chip
