import styled from "styled-components"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"

export const LoadingPage = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
`

export const LoadingCenter = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    color: #0080ed;
    font-size: 152px;
  }

  h1 {
    font-weight: bold;
    font-size: 24px;
    color: #333333;
    margin-bottom: 10px;
  }

  p {
    color: #818181;
    font-size: 16px;
    font-weight: bold;
  }
`

export const LoadingBottom = styled.div`
  p {
    color: #818181;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
`

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    maxWidth: "300px",
    margin: "0 auto",
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress)
