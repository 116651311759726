/* eslint-disable react/prop-types */
import React, { useState } from "react"
import Checkbox from "components/Form/Checkbox/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import LoginButton from "components/Button/LoginButton"
import { SendIcon } from "components/icons"
import { useApp } from "modules/hooks/onboarding/app"
import {
  ProfileFormBox,
  ActionAreaEditProfile,
  CheckboxesArea,
} from "../../style"

const options = [
  {
    label: "Cultura, Diversidade & Gestão de Pessoas",
    value: "cultura-diversidade-and-pessoas",
  },
  {
    label: "Novas tecnologias e disrupções",
    value: "novas-tecnologias-e-disrupcoes",
  },
  {
    label: "Novos Modelos de gestão ágeis",
    value: "novos-modelos-de-gestao-ageis",
  },
  {
    label: "Estratégias de Vendas e Marketing para crescimento de negócios",
    value: "estrategias-de-vendas-e-marketing",
  },
  {
    label: "Inovação",
    value: "inovacao",
  },
  {
    label: "Transformação digital",
    value: "transformacao-digital",
  },
  {
    label: "Empreendedorismo",
    value: "empreendedorismo",
  },
  {
    label: "Carreira e novas habilidades humanas",
    value: "carreira-and-novas-habilidades-humanas",
  },
]

const Form = ({ user, done, show }) => {
  const [error, setError] = useState("")
  const [newPains, setNewPains] = useState(user?.pains || [])
  const { setLoading } = useApp()

  const submit = (e) => {
    e.preventDefault()
    setLoading(true)
    setError("")

    if (newPains.length > 3) {
      setError("Você pode escolher no máximo 3 items")
      return setLoading(false)
    }

    done({
      pains: newPains,
    })
  }

  const handleChange = (value) => {
    const itemIdx = newPains.indexOf(value)
    if (itemIdx < 0) return setNewPains([...newPains, value])

    setNewPains(newPains.filter((_e, idx) => idx !== itemIdx))
  }

  return (
    <ProfileFormBox
      onSubmit={submit}
      style={{ display: show ? "block" : "none" }}
    >
      <h1>
        Com que tipo de conhecimento da Plataforma StartSe pode te ajudar?
      </h1>
      <h2>Escolha até 3 assuntos:</h2>
      <CheckboxesArea error={!!error} component="fieldset">
        <FormGroup>
          {options.map((item) => (
            <FormControlLabel
              control={
                <Checkbox
                  name="pains"
                  value={item.value}
                  onChange={() => handleChange(item.value)}
                  checked={newPains.indexOf(item.value) > -1}
                />
              }
              label={item.label}
              key={item.value}
            />
          ))}
        </FormGroup>
        {error && (
          <FormHelperText style={{ fontWeight: "bold" }}>
            {error}
          </FormHelperText>
        )}
      </CheckboxesArea>
      <ActionAreaEditProfile>
        <LoginButton
          type="submit"
          endIcon={
            <SendIcon
              style={{ color: newPains.length < 1 ? "#C4C4C4" : "#ffffff" }}
            />
          }
          disabled={newPains.length < 1}
        >
          Continuar
        </LoginButton>
      </ActionAreaEditProfile>
    </ProfileFormBox>
  )
}

export default Form
