import React from "react"
import TabsUI from "@material-ui/core/Tabs"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  root: {
    margin: "0 auto 30px",
    maxWidth: "610px",
    width: "100%",
    borderBottom: "1px solid #DDDDDD",
    "& .MuiTab-root": {
      textTransform: "unset",
      fontWeight: "bold",
      fontSize: "18px",
      margin: "0px 20px",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#0080ED !important",
    },
  },
}))

const Tabs = (props) => {
  const classes = useStyles()

  return (
    <TabsUI
      className={classes.root}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      {...props}
    />
  )
}

export default Tabs
