/* eslint-disable react/display-name */
import React from "react"
import * as S from "./style"

import LoginRouter from "../Router"

export default () => {
  return (
    <S.Container>
      <S.GlobalStyle />
      <S.Form>
        <S.FormContent>
          <img
            className="FormContentLogo"
            src="https://startse-landings.s3.us-east-2.amazonaws.com/prime/img/checkout/logo-prime.png"
            alt="Logo StartSe Prime"
          />

          <LoginRouter />
        </S.FormContent>
      </S.Form>

      <S.Testimonial>
        <S.TestimonialTitle>
          StartSe Prime: sua plataforma de conhecimento com tudo o que você
          precisa aprender <span>agora</span>!
        </S.TestimonialTitle>
        <S.TestimonialImage src="https://startse-landings.s3.us-east-2.amazonaws.com/prime/svg/prime-image-login.svg" />

        <S.TestimonialSubTitle>
          Teste grátis sem usar o seu cartão de crédito
        </S.TestimonialSubTitle>
        <S.TestimonialList>
          <S.TestimonialListItem>
            Conteúdo inédito e constante para o seu <span>agora</span>;
          </S.TestimonialListItem>
          <S.TestimonialListItem>
            Curadoria e experiência inéditas de aprendizado;
          </S.TestimonialListItem>
          <S.TestimonialListItem>
            Séries, aulas, materiais exclusivos e muito mais!
          </S.TestimonialListItem>
        </S.TestimonialList>
      </S.Testimonial>
    </S.Container>
  )
}
