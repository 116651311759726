import React, { useMemo } from "react"
import qs from "qs"

import { useLocation } from "react-router-dom"
import {
  GlobalStyleLoginIframe,
  GroupSingleLoginIframe,
  GroupFormLoginIframe,
  GroupFormSocial,
} from "../style"
import LoginV1 from "./V1"
import LoginV2 from "./V2"

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#0080ED",
      light: "#61a7e2",
      dark: "#003a6b",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFFFFF",
      light: "#61a7e2",
      dark: "#003a6b",
      contrastText: "#ffffff",
    },
  },
})

const Login = () => {
  const { search } = useLocation()

  const queries = useMemo(() => (search || "").replace("?", ""), [search])

  const queriesObj = useMemo(() => qs.parse(queries), [queries])

  const inputs = useMemo(() => {
    return Object.keys(queriesObj).map((key) => ({
      name: key,
      value: queriesObj[key],
    }))
  }, [queriesObj])

  return (
    <ThemeProvider theme={theme}>
      <GroupSingleLoginIframe>
        <GlobalStyleLoginIframe />
        <GroupFormLoginIframe>
          <GroupFormSocial>
            {inputs.find(
              (input) => input.name === "version" && input.value === "2"
            ) ? (
              <LoginV2
                queries={queries}
                queriesObj={queriesObj}
                inputs={inputs}
              />
            ) : (
              <LoginV1
                queries={queries}
                queriesObj={queriesObj}
                inputs={inputs}
              />
            )}
          </GroupFormSocial>
        </GroupFormLoginIframe>
      </GroupSingleLoginIframe>
    </ThemeProvider>
  )
}

export default Login
