import styled from "styled-components"

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 20px 0;
    font-weight: 300;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -10px;
`

export const Divider = styled.p`
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #ffffff;
  margin-top: 20px;

  & span {
    width: 40px;
    margin: 0 16px;
  }

  &:before {
    display: block;
    content: "";
    background-color: #dfebf7;
    width: 1px;
    height: 100%;
  }

  &:after {
    display: block;
    content: "";
    background-color: #dfebf7;
    width: 1px;
    height: 100%;
  }
`
export const GroupFormSocialLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    margin: 0 8px;
    cursor: pointer;
    border-radius: 50%;
    width: 40px;

    &.border {
      border: 1px solid #acb1c0;
    }
  }
`
