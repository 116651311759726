import React, { useMemo, useState } from "react"
import TextField from "../../TextField"
import AutoComplete from "../../AutoComplete"
import cookies from "modules/cookies"

import {
  JOBTITLE as jobtitleData,
  REVENUES as revenuesData,
} from "@startse/types"

import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import yup from "modules/form-validation"
import LoadingModal from "../../LoadingModal"
import enrollUser from "modules/services/lambdas/enrollUser"
import updateHubspotProps from "modules/services/lambdas/updateHubspotProps"
import Error from "pages/Login/Error"

import SingleCheckbox from "components/Form/Checkbox/SingleCheckbox"
import { CustomLabel } from "components/Form/Checkbox/style"

import { Container, LabelName, Avatar, Column } from "./styles"
import { Button, Form, RowText } from "../../../style"
import { useUser } from "modules/hooks/onboarding/user"
import LottieLoading from "../../LottieLoading"
import { addParticipant } from "modules/services/platform"
import { useTracking } from "modules/hooks/tracking"

function EventInscription({ eventSlug, hideLogout }) {
  const { updateUser, user } = useUser()
  const { company, jobtitle, phone } = user
  const revenues = company ? company.revenues : null
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [error, setError] = useState("")
  const { track, identify } = useTracking()

  const validationSchema = yup.object().shape({
    jobtitle: jobtitle ? null : yup.string().required(),
    revenues: revenues ? null : yup.string().required(),
    phone: phone ? null : yup.string().required(),
    agree: yup
      .bool()
      .oneOf([true], "É necessário aceitar os termos para continuar"),
  })

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
  })

  const normalizeEventSlug = useMemo(() => {
    let eventName = ""
    if (eventSlug) {
      const slugSplit = eventSlug.split("-")
      slugSplit.forEach(
        (label) =>
          (eventName = `${eventName} ${label
            .charAt(0)
            .toUpperCase()}${label.slice(1)}`)
      )
    }

    return eventName
  }, [eventSlug])

  const normalizeName = useMemo(() => {
    const { firstName, lastName } = user
    const first = `${firstName.split(" ")[0]}`
    const last = `${lastName.split(" ")[lastName.split(" ").length - 1]}`

    return `${first}  ${last}`
  }, [user])

  const getQuerys = () => {
    let params = []
    window.location.search
      .split("?")
      .filter((item) => item !== "")
      .forEach((item) => {
        params = [...params, ...item.split("&")]
      })
    let querys = {}
    params
      .filter((item) => item !== "")
      .forEach((param) => {
        const split = param.split("=")
        querys = {
          ...querys,
          [split[0]]: split[1],
        }
      })
    return querys
  }

  const onSubmit = async (data) => {
    setShowLoadingModal(true)
    try {
      try {
        track("Clicked subscribe event", {
          eventName: eventSlug,
        })
        identify(user.email)
      } catch (err) {
        console.log(err)
      }

      const newCompany = revenues
        ? company
        : {
            name: "",
            revenues: data.revenues,
            employees: "",
            market: null,
          }
      const dataUser = {
        jobtitle: data.jobtitle || jobtitle,
        phone: data.phone || phone,
        company: newCompany,
      }

      await updateUser({ ...user, ...dataUser })
      await enrollUser(user._id, eventSlug)
      await updateHubspotProps(user, eventSlug)
      const querys = getQuerys()

      if (querys.referralCode) {
        const participant = {
          email: user.email,
          firstname: user.firstName,
          lastname: user.lastName,
          referralCode: querys.referralCode,
          refSource: querys.refSource,
        }

        await addParticipant(participant)
      }

      cookies.set("startseUserEventEnroll", `${user._id}_${eventSlug}`)
      window.parent.location.href = `${process.env.REACT_APP_EVENT_URI}`
    } catch (err) {
      console.log(err)
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return setError(error.response.data.message)
      }
      setError("Erro ao cadastrar no evento, por favor tente mais tarde")
    } finally {
      setShowLoadingModal(false)
    }
  }
  const logout = () => {
    cookies.remove("authStartseToken")
    window.location.reload()
  }

  return (
    <>
      <Container hideLogout={hideLogout}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          hideLogout={hideLogout}
        >
          <Column>
            <Avatar alt={user.firstName} src={user.profileImageURL} />
            <LabelName>{normalizeName}</LabelName>
            {!revenues && (
              <AutoComplete
                placeholder="Selecione"
                options={revenuesData}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  value?.label === option?.label
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tamanho da empresa"
                    placeholder="Selecione"
                    inputRef={register}
                    name="revenues"
                    errors={errors}
                  />
                )}
              />
            )}
            {!jobtitle && (
              <AutoComplete
                placeholder="Selecione"
                options={jobtitleData}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  value?.value === option?.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Selecione"
                    label="Cargo"
                    inputRef={register}
                    name="jobtitle"
                    errors={errors}
                  />
                )}
              />
            )}
            {!phone && (
              <InputMask mask="99 99999 9999" maskChar={null}>
                {() => (
                  <TextField
                    name="phone"
                    placeholder="(DDD) XXXXX-XXXX"
                    label="Telefone"
                    inputRef={register}
                    errors={errors}
                  />
                )}
              </InputMask>
            )}
          </Column>
          <Column>
            <SingleCheckbox
              errors={errors}
              name="agree"
              register={register}
              defaultValue={true}
              label={
                <CustomLabel
                  style={{ fontSize: "2.2vh", fontFamily: "Barlow" }}
                >
                  <span>{`Eu concordo com os termos do ${normalizeEventSlug}`}</span>
                </CustomLabel>
              }
            />
            <Error text={error} center />
            <Button type="submit">Continuar</Button>
            {!hideLogout && (
              <RowText notHide onClick={logout}>
                <p>
                  <span>Acessar com outra conta</span>
                </p>
              </RowText>
            )}
          </Column>
        </Form>
      </Container>
      {!hideLogout ? (
        <LoadingModal
          userName={user.firstName}
          eventName={normalizeEventSlug}
          showModal={showLoadingModal}
        />
      ) : (
        <LottieLoading fullLoading loading={showLoadingModal} />
      )}
    </>
  )
}

export default EventInscription
