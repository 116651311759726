import React, { useState, useEffect } from "react"

import { useLocation } from "react-router-dom"
import { useApp } from "modules/hooks/onboarding/app"
import { useUser } from "modules/hooks/onboarding/user"
import qs from "qs"
import { useForm } from "react-hook-form"
import yup from "modules/form-validation"
import uploader from "modules/services/uploader"
import { closeModal } from "modules/helpers"

import Header from "../styled/Header"
import Title from "../styled/Title"
import Subtitle from "../styled/Subtitle"
import ProfileUploader from "components/ImageUploader/ProfileUploader"
import TextField from "../Components/Inputs/TextField"
import Footer from "../styled/Footer"
import Button from "../styled/Button"
import FillLater from "../styled/FillLater"
import {
  CardItem,
  CardHeader,
  CardBody,
  Main,
  CardBottom,
  CardProfileInfo,
  CardProfileName,
  CardProfile,
  RadioGroup,
  LightTooltip,
  SharedData,
} from "./styled"
import BackButton from "components/BackButton"
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"

const validationSchema = yup.object().shape({
  linkedin: yup.string(),
  headline: yup.string(),
})

const Card = ({
  show,
  nextStep,
  backStep,
  disableLater,
  disableBack,
  done,
}) => {
  const [cardTitle, setCardTitle] = useState("StartSe")
  const { user, updateUser } = useUser()
  const { setLoading } = useApp()
  const { search } = useLocation()
  const [file, setFile] = useState(null)
  const [value, setValue] = useState("yes")
  const [tooltip, setTooltip] = useState(false)

  const getLinkedinUser = (url) => {
    if (!url || url.indexOf("linkedin") < 0) return url

    if (url.endsWith("/")) url = url.slice(0, -1)

    const arrayURL = url.split("/") || []
    if (!arrayURL || arrayURL.length < 1) return url

    return arrayURL[arrayURL.length - 1]
  }

  const setAsLinkedinUrl = (profile) => {
    if (!profile || profile.indexOf("linkedin") > -1) return profile

    if (profile.indexOf("in/") > -1)
      return "https://www.linkedin.com/" + profile

    return "https://www.linkedin.com/in/" + profile
  }

  const defaultValues = {
    linkedin: getLinkedinUser(user.linkedin),
    headline: user.headline,
    bio: user.bio,
  }

  useEffect(() => {
    if (user.hideUserData) {
      setValue("no")
    } else {
      setValue("yes")
    }
  }, [user])

  useEffect(() => {
    if (!search) return

    const queryString = qs.parse(search.replace("?", ""))

    if (
      !queryString ||
      !queryString.cardTitle ||
      queryString.cardTitle.length < 1
    )
      return

    setCardTitle(queryString.cardTitle)
    // eslint-disable-next-line
  }, [search])

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })

  const onSubmit = async (data) => {
    setLoading(true)

    data.linkedin = setAsLinkedinUrl(data.linkedin)
    let profileImageURL = user.profileImageURL
    if (file) {
      try {
        profileImageURL = await uploader({
          file,
          project: "auth",
          entity: "user/profile-image-url",
          id: user._id,
        })
      } catch {}
    }

    try {
      await updateUser({
        ...user,
        ...data,
        hideUserData: value === "no",
        profileImageURL,
      })
    } catch (error) {
      return closeModal(user)
    }

    user.hideUserData = value === "no"

    setLoading(false)
    nextStep({
      ...user,
      ...data,
      profileImageURL,
    })
  }

  return (
    <Main
      onSubmit={handleSubmit(onSubmit)}
      style={!show ? { display: "none" } : null}
    >
      <Header>
        <Title>Meu cartão de visita</Title>
        <Subtitle>
          Para você ter a experiência de networking completa, customize o seu
          cartão de visita virtual
        </Subtitle>
      </Header>
      <CardItem>
        <CardHeader>
          <img
            src="https://startse-landings.s3.us-east-2.amazonaws.com/eventos-online/svg/logos/white-logo-startse-small.svg"
            alt="Logo StartSe reduzido, cor branca"
            width="30px"
          />
          {cardTitle}
          <span>{` ${new Date().getFullYear()} —`}</span>
        </CardHeader>
        <CardBody>
          <CardProfile>
            <ProfileUploader
              setFile={(file) => setFile(file)}
              link={user.profileImageURL}
            />
            <CardProfileInfo>
              <CardProfileName>{`${user.firstName} ${user.lastName}`}</CardProfileName>
              <TextField
                id="linkedin"
                label="www.linkedin.com/"
                inputRef={register}
                name="linkedin"
                placeholder="seu link aqui"
                errors={errors}
                fieldAreaStyle={{
                  marginBottom: "0px",
                }}
                labelStyle={{
                  fontSize: "14px",
                }}
                style={{
                  fontSize: "14px",
                }}
                rightComponent={
                  <img
                    src="/img/linkedin-logo.svg"
                    alt="Linkedin logo"
                    style={{ marginRight: "5px" }}
                  />
                }
              />
            </CardProfileInfo>
          </CardProfile>
          <CardBottom>
            <TextField
              id="headline"
              inputRef={register}
              name="headline"
              placeholder="Digite aqui seu título/cargo…"
              errors={errors}
              inputAreaStyle={{
                width: "100%",
                marginLeft: "0px !important",
              }}
            />
            <TextField
              id="bio"
              name="bio"
              placeholder="Digite aqui sua Mini Biografia Profissional…"
              multiline
              fullWidth
              inputRef={register}
              rows={3}
              variant="outlined"
              fieldAreaStyle={{ marginBottom: 0 }}
              inputAreaStyle={{
                width: "100%",
                marginLeft: "0px !important",
              }}
            />
          </CardBottom>
        </CardBody>
      </CardItem>

      <RadioGroup
        aria-label="shareData"
        name="shareData"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        <FormControlLabel
          value="yes"
          control={<Radio color="primary" />}
          label="Aceito compartilhar meu perfil com outros participantes"
        />
        <FormControlLabel
          value="no"
          control={<Radio color="primary" />}
          label="Não quero, obrigado"
        />
      </RadioGroup>

      <LightTooltip
        placement="top"
        open={tooltip}
        onClose={() => setTooltip(false)}
        onOpen={() => setTooltip(false)}
        leaveTouchDelay={5000}
        title={
          <>
            Vamos compartilhar os seguintes dados: <br />- Nome, Título/Cargo{" "}
            <br />- Linkedin <br />- Foto de Perfil <br />- Mini Biografia
            Profissional
          </>
        }
      >
        <SharedData onClick={() => setTooltip(true)}>
          <InfoOutlinedIcon />
          Quais dados serão compartilhados?
        </SharedData>
      </LightTooltip>

      <Footer>
        {!disableBack && <BackButton onClick={backStep} />}
        {!disableLater && <FillLater onClick={nextStep} />}
        <Button type="submit">{done ? "Concluir" : "Avançar"}</Button>
      </Footer>
    </Main>
  )
}

export default Card
