import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { reactI18nextModule } from "react-i18next"

import translationEN from "./locales/en/translation.json"
import translationES from "./locales/es/translation.json"
import translationPT from "./locales/pt-BR/translation.json"

import {
  JOBTITLE,
  JOBTITLE_EN,
  JOBTITLE_ES,
  REVENUES,
  REVENUES_EN,
  REVENUES_ES,
  INTERESTS,
  INTERESTS_EN,
  INTERESTS_ES,
} from "@startse/types"

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  pt: {
    translation: translationPT,
  },
}

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

let jobtitleData = JOBTITLE
let revenuesData = REVENUES
let interestsData = INTERESTS

switch (i18n.language) {
  case "en":
    jobtitleData = JOBTITLE_EN
    revenuesData = REVENUES_EN
    interestsData = INTERESTS_EN
    break
  case "es":
    jobtitleData = JOBTITLE_ES
    revenuesData = REVENUES_ES
    interestsData = INTERESTS_ES
    break
  default:
    jobtitleData = JOBTITLE
    revenuesData = REVENUES
    interestsData = INTERESTS
    break
}

export { jobtitleData, revenuesData, interestsData }

export default i18n
