import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import StepperUI from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import { isMobile } from "react-device-detect"
import MobileStepper from "@material-ui/core/MobileStepper"

const useStyles = makeStyles({
  root: {
    boxShadow: "0px 1px 3px #00000066",
    marginBottom: "10px",
  },
  mobile: {
    flexGrow: 1,
    background: "#FFFFFF",
    justifyContent: "center",
    boxShadow: "0px 1px 3px #00000066",
    padding: "20px 10px",
    marginBottom: "10px",
  },
  label: {
    "& .MuiTypography-root": {
      fontFamily: "'IBM Plex Sans', sans-serif",
    },
    "& .MuiStepLabel-iconContainer svg": {
      fontSize: "28px",
    },
  },
})

const Stepper = ({ activeStep, steps, stepsData }) => {
  const classes = useStyles()

  if (isMobile)
    return (
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        className={classes.mobile}
      />
    )

  return steps.length > 1 ? (
    <StepperUI activeStep={activeStep} className={classes.root}>
      {steps.map((value) => (
        <Step key={value}>
          <StepLabel className={classes.label}>{stepsData[value]}</StepLabel>
        </Step>
      ))}
    </StepperUI>
  ) : null
}

export default Stepper
