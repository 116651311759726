import React from "react"

import TextField from "components/Form/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import LoginButton from "components/Button/LoginButton"
import { EmailIcon } from "components/icons"

import { Column, Divider, Row, GroupFormSocialLogos } from "./styles"

const LoginV2 = ({ queries, queriesObj, inputs }) => (
  <Row>
    <Column>
      <h3>Conecte-se via:</h3>
      <GroupFormSocialLogos>
        <a
          href={`${process.env.REACT_APP_AUTH_URI}auth/linkedin/?${queries}`}
          target="_parent"
          rel="noopener noreferrer"
        >
          <img src="/img/linkedin.svg" alt="Logo Linkedin" />
        </a>
        <a
          href={`${process.env.REACT_APP_AUTH_URI}auth/google/?${queries}`}
          target="_parent"
          rel="noopener noreferrer"
        >
          <img src="/img/google.svg" alt="Logo Google" />
        </a>
        <a
          href={`${process.env.REACT_APP_AUTH_URI}auth/facebook/?${queries}`}
          target="_parent"
          rel="noopener noreferrer"
        >
          <img src="/img/facebook.svg" alt="Logo Facebook" />
        </a>
      </GroupFormSocialLogos>
    </Column>

    <Divider>
      <span>ou</span>
    </Divider>

    <Column>
      <h3>Usando seu e-mail</h3>

      <form
        autoComplete="off"
        action={`${process.env.REACT_APP_HOST}authentication/email`}
        method="GET"
        target="_parent"
      >
        {inputs.map((input) => (
          <input
            key={input.name}
            type="hidden"
            name={input.name}
            value={input.value}
          />
        ))}
        <TextField
          id="email"
          label="E-mail"
          size="medium"
          name="email"
          autoFocus={queriesObj.autoFocus !== "false"}
          required
          type="email"
          color="secondary"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />

        <LoginButton
          fullWidth
          type="submit"
          style={{ marginTop: "8px", minWidth: "250px" }}
        >
          Faça parte
        </LoginButton>
      </form>
    </Column>
  </Row>
)

export default LoginV2
