import React from "react"
import { LocationProvider } from "./location"
import { UserProvider } from "./user"
import { AppProvider as AppStateProvider } from "./app"

export default function AppProvider({ children }) {
  return (
    <AppStateProvider>
      <UserProvider>
        <LocationProvider>{children}</LocationProvider>
      </UserProvider>
    </AppStateProvider>
  )
}
