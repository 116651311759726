import React from "react"
import MuiCheckbox from "@material-ui/core/Checkbox"
import { CheckedIcon, UncheckIcon } from "./style"

const Checkbox = (props) => {
  return (
    <MuiCheckbox
      color="primary"
      checkedIcon={<CheckedIcon />}
      icon={<UncheckIcon />}
      {...props}
    />
  )
}

export default Checkbox
