import React, { useState } from "react"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import { CheckedIcon, UncheckIcon } from "./style"

const SingleCheckbox = ({
  register,
  name,
  errors,
  autoWidth,
  defaultValue,
  ...props
}) => {
  const error = errors && errors[name] ? errors[name] : null
  const [checked, setChecked] = useState(defaultValue || false)
  return (
    <FormControl
      error={!!error}
      component="div"
      style={autoWidth ? {} : { width: "100%", margin: "20px 0" }}
    >
      <FormControlLabel
        inputRef={register}
        control={
          <Checkbox
            color="primary"
            checkedIcon={<CheckedIcon />}
            icon={<UncheckIcon />}
            name={name}
          />
        }
        checked={checked}
        onChange={() => setChecked(!checked)}
        {...props}
      />
      {!!error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}

export default SingleCheckbox
