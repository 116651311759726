import React, { useState, useRef } from "react"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import Button from "../Button"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import { Dialog, Typography } from "@material-ui/core"

const styles = (theme) => ({
  uploadButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  card: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: "100px",
    "&:hover div": {
      display: "block",
    },
  },

  image: {
    borderRadius: "5px",
    width: "100px",
    height: "100px",
    objectFit: "cover",
  },

  imageButtons: {
    display: "none",
    position: "relative",
    bottom: "3.3rem",
    width: "100px",
    textAlign: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },

  imageButtonIcon: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },

  dialog: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.dialog} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "600px",
    height: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default ({ setFile, children, aspectRatio, minWidth, minHeight }) => {
  const [toCrop, setToCrop] = useState(null)
  const [image, setImage] = useState({
    file: null,
    src: null,
  })
  const [showModal, setShowModal] = useState(false)
  const cropEl = useRef(null)
  let inputFile

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const uuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
  }

  const base64StringtoFile = (base64String, filename) => {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const uploadedFile = async (file) => {
    try {
      const base64 = await toBase64(file)
      setToCrop(base64)
    } catch (error) {
      console.log(error)
    }

    setShowModal(true)
  }

  const cropped = () => {
    const base64 = cropEl.current.getCroppedCanvas().toDataURL()
    const file = base64StringtoFile(
      base64,
      image && image.file && image.file.name ? image.file.name : uuid()
    )
    setImage({
      src: base64,
      file: file,
    })
    setFile(file)
    setToCrop(null)
    setShowModal(false)
  }

  const cropMove = (event) => {
    const data = cropEl.current.getData()

    if (data.width < (minWidth || 0)) {
      event.preventDefault()

      data.width = minWidth

      cropEl.current.setData(data)
    }

    if (data.height < (minHeight || 0)) {
      event.preventDefault()

      data.height = minHeight

      cropEl.current.setData(data)
    }
  }

  return (
    <div>
      {children && children.props && children.type
        ? React.createElement(children.type, {
            ...{
              ...children.props,
              clicked: () => inputFile.click(),
              image,
            },
          })
        : null}

      <input
        type="file"
        accept="image/*"
        onChange={(e) => {
          e.preventDefault()
          uploadedFile(e.target.files[0])
        }}
        ref={(input) => {
          inputFile = input
        }}
        style={{ display: "none" }}
      />

      <Dialog
        open={showModal}
        aria-labelledby="customized-dialog-title"
        onClose={() => setShowModal(false)}
      >
        <DialogTitle onClose={() => setShowModal(false)}>
          Cortar Imagem
        </DialogTitle>
        <DialogContent dividers>
          <Cropper
            style={{ height: 400, width: "100%" }}
            src={toCrop}
            cropmove={cropMove}
            ref={cropEl}
            // Cropper.js options
            aspectRatio={aspectRatio}
            guides={false}
            autoCropArea={1}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cropped} type="button">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
