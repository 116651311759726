import React from "react"

import { Select } from "./Select"
import { ChipAutocompleteContainer, ChipContainer, ChipItem } from "./styled"

const ChipAutocomplete = ({
  values,
  setValues,
  options,
  addOption,
  ...props
}) => {
  const handleDelete = (value) => {
    const oldValues = [...values]
    setValues(oldValues.filter((item) => item.value !== value))
  }

  const handleChange = (value) => {
    const found = (options || []).findIndex((item) => item.label === value)
    const item = {
      label: value,
      value,
    }
    const oldValues = [...values]

    if (found < 0) {
      addOption(item)
      setValues([...oldValues, item])
    } else {
      setValues([...oldValues, options[found]])
    }
  }

  return (
    <ChipAutocompleteContainer>
      <Select
        options={(options || [])
          .filter((item) => !(values || []).find((i) => i.value === item.value))
          .map((item) => item.label)}
        value={null}
        SelectProps={{
          isCreatable: true,
        }}
        onChange={handleChange}
        placeholder="escreva"
        {...props}
      />

      <ChipContainer>
        {(values || []).map((i) => (
          <ChipItem
            key={i.value}
            clickable
            color="primary"
            label={i.label}
            onDelete={() => handleDelete(i.value)}
          />
        ))}
      </ChipContainer>
    </ChipAutocompleteContainer>
  )
}

export default ChipAutocomplete
