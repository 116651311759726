/* eslint no-template-curly-in-string: 0 */
/* eslint no-useless-concat: 0 */

import * as yup from "yup"

import i18n from "./../../i18n"

yup.setLocale({
  mixed: {
    required: `${i18n.t("text_required_form_validation")}`,
    notType: `${i18n.t("text_no_type_form_validation")}`,
  },
  string: {
    email: `${i18n.t("text_invalid_email_form_validation")}`,
    min:
      `${i18n.t("text_min_characters_form_validation")}` +
      " ${min} " +
      `${i18n.t("text_characters_form_validation")}`,
    max:
      `${i18n.t("text_max_characters_form_validation")}` +
      " ${max} " +
      `${i18n.t("text_characters_form_validation")}`,
  },
  number: {
    min: `${i18n.t("text_min_number_form_validation")}` + " ${min}",
    max: `${i18n.t("text_max_number_form_validation")}` + " ${max}",
  },
})

export default yup
