import React from "react"
import styled from "styled-components"
import MuiAvatar from "@material-ui/core/Avatar"

export const SubscribeArea = styled.div`
  padding: 8px 20px 32px;
  width: 100%;

  @media screen and (min-width: 1100px) {
    max-width: 600px;
    border: 1px solid #acb1c0;
    border-bottom: none;
    border-top: none;
    margin: auto;
    background-color: #fff;
    min-height: 100vh;
  }

  h1 {
    color: #333333;
    font-family: Barlow, sans-serif;
    font-size: 26px;
    text-align: center;
    margin-top: 30px;
  }
  h2 {
    text-align: center;
    color: #818181;
    font-size: 16px;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 30px;
  }

  .form-area {
    max-width: 520px;
    margin: 0 auto;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SubscribeBackground = styled.div`
  @media screen and (min-width: 1100px) {
    background-color: #f7f8fa;
    width: 100%;
    min-height: 100vh;
  }
`

const AvatarBorder = styled.div`
  border: 1px solid #0080ed;
  padding: 2px;
  border-radius: 50%;
`

export const Avatar = (props) => (
  <AvatarBorder>
    <MuiAvatar {...props} />
  </AvatarBorder>
)
