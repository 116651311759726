import styled from "styled-components"

export const TextError = styled("p")({
  color: "red",
  fontSize: "16px",
})

export const ContainerButton = styled("div")({
  width: "100%",
  ".MuiInput-underline:before ": {
    borderBottom: "0px solid",
  },
  ".MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid",
  },
})
