import styled, { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  body {
    background-color: #01183c;
    position: relative;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;

    @media screen and (min-width: 1100px) {
      width: 100vw;
      height: 100vh;
    }
  }

  #root {
    width: 100%;
    height: 100%;
    position: relative;
  }
`
export const Container = styled.div`
  background-color: #01183c;
  width: 100vw;
  padding: 0;

  @media screen and (min-width: 1024px) {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: initial;
  }
`

export const Form = styled.div`
  height: 100%;
  padding: 32px 0;

  @media screen and (min-width: 1024px) {
    width: 50%;
    padding: 46px 0 0;
  }
`

export const FormContent = styled.div`
  width: 90%;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    max-width: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
  }

  & .FormContentLogo {
    margin-left: -12px;

    @media screen and (min-width: 1024px) {
      position: absolute;
      top: 24px;
      left: 7px;
      width: 200px;
      margin-left: initial;
    }
  }

  & h1 {
    color: #fff;
    text-align: center;
    font-size: 28px;
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    margin: 32px auto;

    @media screen and (min-width: 1024px) {
      margin: 64px auto 32px;
      font-size: 35px;
    }
  }

  & h2 {
    color: #989898;
    font-size: 15px;
    font-weight: 300;
    text-align: center;
  }

  & h3 {
    text-align: center;
  }

  & p.divider {
    max-width: 388px;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
      background-color: #48a2f0;
    }

    & span {
      color: #fff;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #0080ed;
    color: #0080ed;
  }

  & .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined,
  & .MuiInputAdornment-root.MuiInputAdornment-positionStart,
  & .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    color: #0080ed;
  }

  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #0080ed;
  }

  & .MuiSvgIcon-root {
    fill: #0080ed;
  }

  & .MuiInputBase-root {
    color: #0080ed;
  }

  & form > div:first-child,
  & form div:nth-child(3) {
    width: 100%;
  }

  & form {
    border-top: none;
    max-width: 388px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const Testimonial = styled.div`
  background: #0080ed;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 32px;
  position: relative;

  @media screen and (min-width: 1024px) {
    width: 50%;
    height: 100%;
  }
`

export const TestimonialTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  max-width: 640px;

  & span {
    text-transform: uppercase;
  }

  @media screen and (min-width: 1024px) {
    font-size: 28px;
  }
`

export const TestimonialImage = styled.img`
  width: 100%;
  max-width: 392px;
  padding: 16px 0 10px;

  @media screen and (min-width: 1024px) {
    padding: 32px 0 20px;
  }
`

export const TestimonialSubTitle = styled.p`
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
`

export const TestimonialList = styled.u`
  text-decoration: none;
  padding-top: 8px;
  text-align: left;
`

export const TestimonialListItem = styled.li`
  text-decoration: none;
  line-height: 1.6;

  & span {
    text-transform: uppercase;
  }
`
