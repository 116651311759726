import styled from "styled-components"

export const Container = styled.div`
  position: absolute;
  margin: auto;
  box-shadow: 10px 10px 60px 0 rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  top: 20%;
  min-height: 350px;
  min-width: 250px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
  padding: 2rem;
  border: solid 3px #ffffff;
  z-index: 100;
  .KKujJ {
    margin-top: 20px;
  }
  .MuiInputBase-root {
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    position: relative;
    font-size: 18px;
    margin-top: 40px;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
  }
  .MuiInput-underline:after {
    border-bottom: 0px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px;
  }
  .MuiInput-underline:before {
    border-bottom: none;
  }
  @media (max-width: 780px) {
    top: 10%;
    max-width: 250px;
    height: 400px;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ContainerText = styled.div`
  text-align: center;
`
export const Title = styled.h1`
  margin-top: 20px;
  font-weight: bold;
  @media (max-width: 780px) {
    font-size: 22px;
  }
`
export const Text = styled.p`
  margin-top: 10px;
  font-size: 20px;
  word-wrap: break-word;
`
export const Form = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
`
export const Input = styled.input`
  margin-top: 40px;
  font-family: Barlow;
  font-size: 18px;
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  border-color: #c3c3c3;
  color: #c3c3c3;
`
export const Button = styled.button`
  cursor: pointer;
  width: 70%;
  padding: 10px;
  height: 45px;
  border: 0;
  background-color: #1e90ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 24px;
  margin-top: 32px;
  @media (max-width: 780px) {
    width: 70%;
  }
`
export const ContainerIcon = styled.div`
  display: flex;
  justify-content: center;
  .MuiSvgIcon-root {
    color: #008000;
    width: 10rem;
    height: 10rem;
  }
`
export const ContainerSuccess = styled.div`
  display: flex;
  font-family: Barlow;
  text-align: center;
  flex-direction: column;
  margin-bottom: 30px;
`
export const ContainerCircularProgress = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
`
