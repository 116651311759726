import styled from "styled-components"
import { Avatar as AvatarMui } from "@material-ui/core"

export const Container = styled.div`
  font-size: 2vh;
  height: ${(props) => (props.hideLogout ? "100%" : null)};
`

export const LabelName = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 14px;
  font-weight: bold;
`

export const Avatar = styled(AvatarMui)`
  width: 70px !important;
  height: 70px !important;
  margin: 0.8em 0;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
