export default [
  {
    id_estado: 1,
    estado: "Acre",
    uf: "AC",
  },
  {
    id_estado: 2,
    estado: "Alagoas",
    uf: "AL",
  },
  {
    id_estado: 3,
    estado: "Amazonas",
    uf: "AM",
  },
  {
    id_estado: 4,
    estado: "Amapá",
    uf: "AP",
  },
  {
    id_estado: 5,
    estado: "Bahia",
    uf: "BA",
  },
  {
    id_estado: 6,
    estado: "Ceará",
    uf: "CE",
  },
  {
    id_estado: 7,
    estado: "Distrito Federal",
    uf: "DF",
  },
  {
    id_estado: 8,
    estado: "Espírito Santo",
    uf: "ES",
  },
  {
    id_estado: 9,
    estado: "Goiás",
    uf: "GO",
  },
  {
    id_estado: 10,
    estado: "Maranhão",
    uf: "MA",
  },
  {
    id_estado: 11,
    estado: "Minas Gerais",
    uf: "MG",
  },
  {
    id_estado: 12,
    estado: "Mato Grosso do Sul",
    uf: "MS",
  },
  {
    id_estado: 13,
    estado: "Mato Grosso",
    uf: "MT",
  },
  {
    id_estado: 14,
    estado: "Pará",
    uf: "PA",
  },
  {
    id_estado: 15,
    estado: "Paraíba",
    uf: "PB",
  },
  {
    id_estado: 16,
    estado: "Pernambuco",
    uf: "PE",
  },
  {
    id_estado: 17,
    estado: "Piauí",
    uf: "PI",
  },
  {
    id_estado: 18,
    estado: "Paraná",
    uf: "PR",
  },
  {
    id_estado: 19,
    estado: "Rio de Janeiro",
    uf: "RJ",
  },
  {
    id_estado: 20,
    estado: "Rio Grande do Norte",
    uf: "RN",
  },
  {
    id_estado: 21,
    estado: "Rondônia",
    uf: "RO",
  },
  {
    id_estado: 22,
    estado: "Roraima",
    uf: "RR",
  },
  {
    id_estado: 23,
    estado: "Rio Grande do Sul",
    uf: "RS",
  },
  {
    id_estado: 24,
    estado: "Santa Catarina",
    uf: "SC",
  },
  {
    id_estado: 25,
    estado: "Sergipe",
    uf: "SE",
  },
  {
    id_estado: 26,
    estado: "São Paulo",
    uf: "SP",
  },
  {
    id_estado: 27,
    estado: "Tocantins",
    uf: "TO",
  },
]
