/* eslint-disable */

export default {
  name: "getUserByEmail",
  setUser: (variables) => {
    variables.email = "{{email}}"
    return variables
  },
  data: /* GraphQL */ `
    query GetUserByEmail($email: String!) {
      getUserByEmail(email: $email) {
        _id
        email
        bio
        firstName
        lastName
        profileImageURL
        country
        state
        city
        jobtitle
        headline
        dateOfBirth
        phone
        linkedin
        interests
        segment
        challenge
        hasCompletedProfile
        department
        hideUserData
        pains
        company {
          name
          revenues
          employees
          market
        }
        educationInterest {
          wantToLearn
          whatCanTeach
        }
      }
    }
  `,
}
