import React from "react"
import { withNamespaces } from "react-i18next"

import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined"

import {
  LoadingPage,
  LoadingCenter,
  LoadingBottom,
  BorderLinearProgress,
} from "./style"

const Loading = ({ name, t }) => {
  return (
    <LoadingPage>
      <LoadingCenter>
        <VerifiedUserOutlinedIcon />
        <h1>
          {t("title_loading")} {name}!
        </h1>
        <p>{t("text_sucess_loading")}</p>
      </LoadingCenter>
      <LoadingBottom>
        <p>{t("text_redirect_loading")}</p>
        <BorderLinearProgress />
      </LoadingBottom>
    </LoadingPage>
  )
}

export default withNamespaces()(Loading)
