import Cookies from "universal-cookie"

const cookiesInstance = new Cookies()
const cookies = {}
const options = {
  domain: process.env.REACT_APP_COOKIE_DOMAIN,
  path: "/",
}

cookies.get = (name) => cookiesInstance.get(name, options)
cookies.set = (name, value) => cookiesInstance.set(name, value, options)
cookies.remove = (name) => cookiesInstance.remove(name, options)

export default cookies
