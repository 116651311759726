/* eslint-disable react/display-name */
import React from "react"
import { GlobalStyle, GroupForm } from "../style"
import { Page } from "./style"

export default ({ children }) => {
  return (
    <Page className="summit-loading">
      <GlobalStyle />
      <img
        src="/img/startse-logo.svg"
        alt="Logo StartSe"
        className="summit-loading-logo"
      />

      <div className="summit-loading-area">
        <h1>
          StartSe
          <br />
          Summit
        </h1>
        <GroupForm bottom>{children}</GroupForm>
      </div>
    </Page>
  )
}
