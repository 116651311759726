import axios from "axios"

const api = axios.create({
  baseURL: `${process.env.REACT_APP_PLATFORM_URI}/api/indications`,
})

export const addParticipant = async ({
  email,
  firstname,
  lastname,
  referralCode,
  refSource,
}) =>
  await api.post("", {
    email,
    firstname,
    lastname,
    referralCode,
    refSource,
  })
