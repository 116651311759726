import styled from "styled-components"
import { withStyles } from "@material-ui/core/styles"
import MuiChip from "@material-ui/core/Chip"

export const FieldArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1100px) {
    flex-wrap: nowrap;
  }
  .error-msg {
    color: #f44336;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    display: block;
    margin-left: 10px;
  }
`

export const Label = styled.label`
  color: #5c5c5c;
  text-align: center;
  @media screen and (max-width: 600px) {
    width: 100%;
    text-align: left;
  }
`

export const ChipAutocompleteContainer = styled.div`
  width: 420px;
  max-width: 100%;
  margin: 0 auto 20px;
`
export const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -10px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export const ChipItem = withStyles(() => ({
  root: {
    margin: "0px 10px 10px 0px",
    "justify-content": "space-between",
  },
}))(MuiChip)
