import React, { createContext, useState, useContext } from "react"

const AppContext = createContext({})

export function AppProvider({ children }) {
  const [loading, setLoading] = useState(true)

  return (
    <AppContext.Provider value={{ loading, setLoading }}>
      {children}
    </AppContext.Provider>
  )
}

export function useApp() {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error("useApp must be used within an AppProvider")
  }

  return context
}
