/* eslint-disable react/prop-types */
import React, { useState } from "react"

import { useForm } from "react-hook-form"
import { withNamespaces } from "react-i18next"

import yup from "modules/form-validation"
import uploader from "modules/services/uploader"
import { useApp } from "modules/hooks/onboarding/app"

import TextField from "components/v4/Form/TextField"
import DefaultButton from "components/v4/Button/DefaultButton"
import ProfileUploader from "components/ImageUploader/ProfileUploader"

import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import { useTracking } from "modules/hooks/tracking"

import {
  ProfileFormBox,
  ActionAreaEditProfile,
  ContainerTextField,
} from "../../style"
import { LightTooltip, SharedData, ProfileArea, RadioGroup } from "./style"

const validationSchema = yup.object().shape({
  linkedin: yup.string(),
  headline: yup.string(),
  bio: yup.string(),
})

const Form = ({ user, done, show, t }) => {
  const { track } = useTracking()

  const [file, setFile] = useState(null)
  const [hideUserData, setHideUserData] = useState(
    user.hideUserData ? "no" : "yes"
  )
  const [tooltip, setTooltip] = useState(false)
  const { setLoading } = useApp()
  const defaultValues = {
    linkedin: user.linkedin,
    headline: user.headline,
    bio: user.bio,
  }

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })

  const changeHideUser = (event) => {
    setHideUserData(event.target.value)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    let profileImageURL = user.profileImageURL
    if (file) {
      try {
        profileImageURL = await uploader({
          file,
          project: "auth",
          entity: "user/profile-image-url",
          id: user._id,
        })
      } catch (error) {
        console.log(error)
      }
    }
    done({
      ...data,
      profileImageURL,
      hideUserData: hideUserData === "no",
    })

    track("Clicked on Continue button - My Profile [3/3]")
  }

  return (
    <ProfileFormBox
      style={{ display: show ? "block" : "none" }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <h1>{t("title_business_card")}</h1>
      <h2>{t("subtitle_business_card")}</h2>
      <ProfileArea>
        <ProfileUploader
          setFile={(file) => setFile(file)}
          link={user.profileImageURL}
        />
      </ProfileArea>
      <ContainerTextField>
        <h3>{t("input_business_card_linkedin")}</h3>
        <TextField
          id="linkedin"
          inputRef={register}
          size="medium"
          name="linkedin"
          errors={errors}
          autoFocus={true}
        />
        <h3>{t("input_business_card_position")}</h3>
        <TextField
          id="headline"
          inputRef={register}
          size="medium"
          name="headline"
          errors={errors}
        />
        <h3>{t("textarea_business_card_bio")}</h3>
        <TextField
          id="bio"
          inputRef={register}
          size="medium"
          name="bio"
          variant="outlined"
          style={{ marginTop: "12px" }}
          rows={4}
          multiline
          errors={errors}
        />
        <RadioGroup name="hide" value={hideUserData} onChange={changeHideUser}>
          <FormControlLabel
            value="yes"
            control={<Radio color="primary" />}
            label={t("check_share_profile")}
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" />}
            label={t("check_do_not_share_profile")}
          />
        </RadioGroup>

        <LightTooltip
          placement="top-start"
          open={tooltip}
          onClose={() => setTooltip(false)}
          onOpen={() => setTooltip(false)}
          leaveTouchDelay={5000}
          title={t("link_shared_data_content")}
        >
          <SharedData onClick={() => setTooltip(true)}>
            {t("link_shared_data_title")}
          </SharedData>
        </LightTooltip>
      </ContainerTextField>

      <ActionAreaEditProfile>
        <DefaultButton type="submit">{t("button_continue")}</DefaultButton>
      </ActionAreaEditProfile>
    </ProfileFormBox>
  )
}

export default withNamespaces()(Form)
