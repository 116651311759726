import React, { createContext, useState, useContext } from "react"

const AppContext = createContext({})

// eslint-disable-next-line react/prop-types
export function AppProvider({ children }) {
  const [loading, setLoading] = useState(false)
  const [fullLoading, setFullLoading] = useState(false)
  const [welcomeView, setWelcomeView] = useState(false)
  const [passwordForgot, setPasswordForgot] = useState(false)

  const viewResetPassword = () => {
    if (passwordForgot === false) {
      setPasswordForgot(true)
    } else {
      setPasswordForgot(false)
    }
  }

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        fullLoading,
        setFullLoading,
        welcomeView,
        setWelcomeView,
        passwordForgot,
        viewResetPassword,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useApp() {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error("useApp must be used within an AppProvider")
  }

  return context
}
