import styled from "styled-components"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"

export const SummitArea = styled.div`
  height: 50%;
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 10px;

  .summit-desktop {
    display: none;
  }

  @media screen and (min-width: 1100px) {
    width: 50%;
    height: 100%;
    text-align: center;

    .summit-desktop {
      display: block;
    }

    .summit-mobile {
      display: none;
    }
  }

  h1 {
    color: #333333;
    font-size: 51px;
    font-family: "Barlow", sans-serif;
    line-height: 0.9;
    margin-bottom: 20px;

    @media screen and (min-width: 1100px) {
      text-transform: uppercase;
      font-size: 40px;
      margin-bottom: 10px;
    }
  }

  p {
    color: #818181;
    font-size: 16px;
    font-family: "Barlow", sans-serif;
    font-weight: bold;
    max-width: 275px;

    @media screen and (min-width: 1100px) {
      color: #333333;
      max-width: 330px;
      margin: 0 auto;
    }

    &.summit-bottom {
      margin-top: 70px;
      font-size: 12px;
      font-weight: normal;
      max-width: none;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 10px;
        width: 25px;
      }
    }
  }
`

export const SummitImage = styled.img`
  border-radius: 50%;
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  @media screen and (min-width: 1100px) {
    display: none;
  }

  &.one {
    top: 30px;
    left: 30px;
    width: 75px;
    height: 75px;
  }

  &.two {
    top: 40px;
    left: 180px;
    width: 60px;
    height: 60px;
  }

  &.three {
    top: 0px;
    left: 300px;
    width: 85px;
    height: 85px;
  }

  &.four {
    top: 160px;
    left: -20px;
    width: 60px;
    height: 60px;
  }

  &.five {
    top: 130px;
    left: 90px;
    width: 90px;
    height: 90px;
  }

  &.six {
    top: 140px;
    left: 260px;
    width: 95px;
    height: 95px;
  }

  &.seven {
    top: 280px;
    left: 280px;
    width: 100px;
    height: 100px;
  }
`

export const Page = styled.div`
  background-image: url("/img/summit-background.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;

  background-size: auto 50%;
  background-position-x: 150%;
  background-position-y: top;

  &.summit-loading {
    display: block;
  }

  .summit-loading-area {
    text-align: center;
    position: absolute;
    top: 40%;
    width: 100%;
    @media screen and (min-width: 1100px) {
      width: 50%;
    }

    h1 {
      color: #333333;
      font-size: 51px;
      font-family: "Barlow", sans-serif;
      line-height: 0.9;
      margin-bottom: 20px;
      @media screen and (min-width: 1100px) {
        margin-bottom: 60px;
      }
    }
  }

  @media screen and (min-width: 1100px) {
    background-size: 50% auto;
    background-position-x: 105%;
    background-position-y: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;

    .summit-login-area {
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }

  .logo-mobile {
    margin: 20px 0px 0px 10px;
    display: block;

    @media screen and (min-width: 1100px) {
      display: none;
    }
  }

  .summit-loading-logo {
    margin: 20px 0px 0px 20px;
    max-width: 150px;
  }

  .logo-desktop {
    display: none;
    max-width: 200px;

    @media screen and (min-width: 1100px) {
      display: block;
    }
  }

  @media screen and (max-width: 1100px) {
    .login-divider,
    .login-subtitle,
    .login-title,
    .login-divider-subtitle,
    .login-local-title,
    .login-local-subtitle,
    .signup-title,
    .signup-subtitle,
    .password-title,
    .forgot-title {
      display: none !important;
    }

    .pre-logged-user {
      margin-top: 10px;
    }

    .cancel-button {
      margin-top: 10px;
    }

    .login-social-area {
      justify-content: flex-start;
    }
  }
`

export const LoadingPage = styled.div`
  @media screen and (max-width: 1100px) {
    margin-top: 90px;
  }

  p {
    margin-bottom: 30px;
    color: #818181;
    font-weight: bold;
  }
`

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress)
