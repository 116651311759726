import steps from "./steps"
import countries from "./countries"
import states from "./states"
import educationInterest from "./educationInterest"

import {
  JOBTITLE,
  EMPLOYEES,
  REVENUES,
  SEGMENT,
  MARKET,
  INTERESTS,
  DEPARTMENT,
} from "@startse/types"

const jobtitle = JOBTITLE
const employees = EMPLOYEES
const revenues = REVENUES
const segment = SEGMENT
const market = MARKET
const interests = INTERESTS
const department = DEPARTMENT

export {
  jobtitle,
  employees,
  revenues,
  segment,
  market,
  interests,
  steps,
  countries,
  states,
  department,
  educationInterest,
}
