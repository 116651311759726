import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

const EmailIcon = (props) => (
  <SvgIcon className="startse-icon" viewBox="0 0 30 30" {...props}>
    <g transform="translate(2.506 27.569) rotate(-90)">
      <path d="M0,12.531A12.531,12.531,0,1,1,12.531,25.063,12.545,12.545,0,0,1,0,12.531Zm1.88,0A10.652,10.652,0,1,0,12.531,1.88,10.664,10.664,0,0,0,1.88,12.531Zm10.091,3.316-.106-.091L7.516,11.388A.94.94,0,0,1,8.742,9.97l.106.091,3.682,3.7,3.685-3.7a.939.939,0,0,1,1.224-.094l.106.09a.941.941,0,0,1,.094,1.224l-.091.106L13.2,15.756a.94.94,0,0,1-1.226.091Z" />
    </g>
  </SvgIcon>
)
export default EmailIcon
