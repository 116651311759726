import React from "react"
import { TextFieldUI } from "./style"

const TextField = ({ errors, ...props }) => {
  const error = errors && errors[props.name] ? errors[props.name] : null

  return (
    <TextFieldUI
      error={!!error}
      variant="outlined"
      size="small"
      {...props}
      helperText={error ? error.message : props.helperText}
    />
  )
}

export default TextField
