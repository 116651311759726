import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
      border-radius: 5px;
    }
  }

  body {
    position: relative;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
  }

  #root {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .MuiDialogContent-root-251.jss250.MuiDialogContent-dividers-252 {
    max-width: initial;
    max-height: initial;
    width: 100%;
    height: 100%;
  }

  [class^=MuiInputAdornment-root] {
    padding: 18px 0px 24px 0px;
  }
  
  .startse-icon {
    color: #818181;
  }

  .Mui-focused .startse-icon {
    color: #0080ED;
  }

  .Mui-error .startse-icon {
    color: #f44336;
  }
`
