import React from "react"
import TextFieldUI from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    //marginBottom: theme.spacing(2),
    width: "100%",
  },
}))

const TextField = ({ errors, ...props }) => {
  const classes = useStyles()
  const error = errors && errors[props.name] ? errors[props.name] : null

  return (
    <TextFieldUI
      error={!!error}
      className={classes.root}
      variant="standard"
      size="small"
      {...props}
      helperText={error ? error.message : props.helperText}
    />
  )
}

export default TextField
