import React, { useEffect, useMemo } from "react"
import cookie from "modules/cookies"

import { useApp } from "modules/hooks/onboarding/app"
import { useUser } from "modules/hooks/onboarding/user"
import qs from "qs"
import { useLocation } from "react-router-dom"

import Form from "./Components/Form"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { SubscribeArea, Avatar, Header, SubscribeBackground } from "./style"

const Subscribe = () => {
  const { loading, setLoading } = useApp()
  const { user, getUser } = useUser()
  const { search } = useLocation()

  const queryString = useMemo(() => {
    let _search = search ? search : ""
    return qs.parse(_search.replace("?", ""))
  }, [search])

  const redirect = () => (window.location.href = queryString?.redirect_to)

  const login = () =>
    (window.location.href = `${process.env.REACT_APP_HOST}?redirect_uri=${process.env.REACT_APP_HOST}subscribe&redirect_to=${queryString?.redirect_to}`)

  useEffect(() => {
    const fetchData = async (_token) => {
      setLoading(true)

      try {
        await getUser(_token)
      } catch (error) {
        console.log(error)
        login()
      } finally {
        setLoading(false)
      }
    }

    const token = cookie.get("authStartseToken")
    if (!token) {
      return login()
    }

    fetchData(token)
    // eslint-disable-next-line
  }, [])

  if (loading)
    return (
      <Backdrop open={true} style={{ zIndex: 9 }}>
        <CircularProgress />
      </Backdrop>
    )

  return (
    <SubscribeBackground>
      <SubscribeArea>
        <Header>
          <img
            src="https://startse-landings.s3.us-east-2.amazonaws.com/login/svg/logos/mini.svg"
            alt="Mini StartSe logo"
          />
          <Avatar alt={user?.firstName} src={user?.profileImageURL} />
        </Header>
        <div className="form-area">
          <h1>Quase lá!</h1>
          <h2>Preencha os campos abaixo pra finalizar:</h2>

          {user && user.email && <Form user={user} redirect={redirect} />}
        </div>
      </SubscribeArea>
    </SubscribeBackground>
  )
}

export default Subscribe
