/* eslint-disable react/prop-types */
import React from "react"
import { withNamespaces } from "react-i18next"

import { useUser } from "modules/hooks/onboarding/user"

import { ProfileFormBox } from "../../style"

const Congratulations = ({ show, t }) => {
  const { user } = useUser()

  return (
    <ProfileFormBox style={{ display: show ? "block" : "none" }}>
      <h1 className="h1Bigger">
        {t("text_final_message_title")}, {user.firstName}!
      </h1>
      <h2>{t("text_final_message_subtitle")}</h2>
      <svg
        className="congratulationsCheckIcon"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        enableBackground="new 0 0 24 24"
        version="1.0"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
      >
        <polyline
          fill="none"
          points="20,6 9,17 4,12"
          stroke="#009C17"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </svg>
    </ProfileFormBox>
  )
}

export default withNamespaces()(Congratulations)
