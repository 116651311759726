import styled from "styled-components"
import TextField from "@material-ui/core/TextField"

export const TextFieldUI = styled(TextField)`
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 12px;
  max-width: 300px;
  fieldset {
    border-color: transparent;
    border-radius: 12px;
    &:hover {
      border-color: transparent;
    }
  }
`
