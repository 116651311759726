import React, { useEffect, useState } from "react"
import {
  Container,
  Title,
  Name,
  ContainerInfo,
  ContainerForm,
  useStyles,
  ContainerLogo,
} from "./style"

import { Button } from "pages/Login/Sign/style"

import { Backdrop, CircularProgress } from "@material-ui/core"
import LogoStartSe from "../../Login/Sign/icon/LogoStartseBlue"
import { Avatar } from "@material-ui/core"
import AutoComplete from "pages/Login/Sign/components/AutoComplete"
import TextField from "pages/Login/Sign/components/TextField"

import { useForm } from "react-hook-form"
import yup from "modules/form-validation"
import Error from "pages/Login/Error"
import {
  JOBTITLE as jobtitleData,
  REVENUES as revenuesData,
} from "@startse/types"
import { CustomLabel } from "components/Form/Checkbox/style"
import { useClient } from "modules/hooks/login/client"
import SingleCheckbox from "components/Form/Checkbox/SingleCheckbox"
import cookie from "modules/cookies"
import { useUser } from "modules/hooks/onboarding/user"
import PhoneInputComp, {
  phoneRegExp,
} from "pages/Login/Sign/components/PhoneInput"

const validationSchema = yup.object().shape({
  jobtitle: yup.string().required(),
  revenues: yup.string().required(),
  phone: yup.string().required().matches(phoneRegExp),
  agree: yup
    .bool()
    .oneOf([true], "É necessário aceitar o termo para continuar"),
})

export const RegisterSocial = () => {
  const classes = useStyles()
  const { config } = useClient()
  const [user, setUser] = useState("")
  const [loading, setLoading] = useState(false)
  const { getUser, updateUser } = useUser()
  const [error, setError] = useState("")

  const { register, handleSubmit, errors } = useForm({ validationSchema })

  useEffect(() => {
    const fetchData = async (_token) => {
      try {
        const data = await getUser(_token)
        setUser(data)
      } catch (error) {
        console.log(error)
      }
    }

    const token = cookie.get("authStartseToken")

    fetchData(token)
    // eslint-disable-next-line
  }, [])

  function closeModalCompleted(user) {
    window.top.postMessage(
      JSON.stringify({
        action: "closeCompleted",
        user,
      }),
      "*"
    )
  }

  async function onSubmit(data) {
    setLoading(true)
    let jobtitle = jobtitleData.find((jobtitleDataList) =>
      jobtitleDataList.label === data.jobtitle
        ? jobtitleDataList.value
        : undefined
    )
    if (jobtitle === undefined) {
      jobtitle = data.jobtitle
    } else {
      jobtitle = jobtitle?.value
    }

    const revenues = revenuesData.find((revenuesDataList) =>
      revenuesDataList.label === data.revenues
        ? revenuesDataList.value
        : undefined
    )

    const company = {
      name: "",
      revenues: revenues ? revenues.value : "Não se aplica",
      employees: "",
      market: null,
    }
    const dataUser = {
      jobtitle,
      email: user.email,
      phone: data.phone,
      company: company,
      lastName: user.lastName || "-",
    }

    setError(null)

    try {
      await updateUser({ ...user, ...dataUser })
      closeModalCompleted(user)
      setLoading(false)
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return setError(error.response.data.message)
      }
      setError("Erro ao criar conta, por favor tente mais tarde")
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading && (
        <Backdrop open={true} style={{ zIndex: 9 }}>
          <CircularProgress />
        </Backdrop>
      )}
      <Container>
        <ContainerLogo>
          <LogoStartSe />
        </ContainerLogo>
        <Title>Complete seu cadastro</Title>
        <ContainerInfo>
          <Avatar
            alt={user.firstName}
            src={user.profileImageURL}
            className={classes.avatar}
          />
          <Name>{user.firstName}</Name>
        </ContainerInfo>
        <ContainerForm
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
        >
          <AutoComplete
            placeholder="Selecione"
            options={jobtitleData}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) =>
              value?.value === option?.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Selecione"
                label="Cargo"
                size="medium"
                inputRef={register}
                name="jobtitle"
                errors={errors}
              />
            )}
          />
          <AutoComplete
            options={revenuesData}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) =>
              value?.label === option?.label
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tamanho da empresa"
                placeholder="Selecione"
                size="medium"
                inputRef={register}
                name="revenues"
                errors={errors}
              />
            )}
          />
          <PhoneInputComp errors={errors} inputRef={register} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {config?.privacy || config?.terms ? (
              <SingleCheckbox
                errors={errors}
                name="agree"
                register={register}
                defaultValue={true}
                label={
                  <CustomLabel>
                    <span>Eu concordo com a </span>
                    <a
                      style={{
                        textDecoration: "none",
                        fontFamily: "Barlow",
                        color: "rgb(30, 144, 255)",
                      }}
                      rel="noopener noreferrer"
                      target={"_blank"}
                      href="https://docs.startse.com/docs/politica-de-privacidade"
                    >
                      Política de Privacidade
                    </a>
                  </CustomLabel>
                }
              />
            ) : null}
          </div>
          <Error text={error} />
          <Button type="submit">Continuar</Button>
        </ContainerForm>
      </Container>
    </>
  )
}
