import styled from "styled-components"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import { IconButton } from "@material-ui/core"
import MobileStepper from "@material-ui/core/MobileStepper"

export const SubscribeArea = styled.div`
  padding: 8px 20px 32px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1100px) {
    max-width: 600px;
    border: 1px solid #acb1c0;
    border-bottom: none;
    border-top: none;
    margin: auto;
    background-color: #fff;
  }

  h1 {
    color: #333333;
    font-family: Barlow, sans-serif;
    font-size: 26px;
    text-align: center;
    margin-top: 30px;
  }
  h2 {
    text-align: center;
    color: #818181;
    font-size: 16px;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 30px;
  }

  .form-area {
    max-width: 520px;
    margin: 0 auto;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  img {
    width: 130px;
  }
`

export const SubscribeBackground = styled.div`
  min-height: 100vh;

  @media screen and (min-width: 1100px) {
    background-color: #f7f8fa;
    width: 100%;
  }
`

export const PlatformForm = styled.form`
  margin-top: 40px;
  margin-bottom: 50px;

  h1 {
    color: #333333;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left;
    font-weight: 600;
    margin-top: 0px;
  }

  p {
    color: #818181;
    font-size: 16px;
    font-family: Barlow, sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
  }

  & .MuiFormControlLabel-root {
    margin-bottom: 15px;
  }

  & .MuiFormControlLabel-label {
    color: #818181;
    margin-left: 10px;
    font-family: Barlow;
    font-weight: 500;
    font-size: 19px;
    line-height: 21px;
  }
`

export const ActionArea = styled.div`
  text-align: right;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  padding: 20px 40px;
  left: 50%;
  transform: translateX(-50%);
`

export const BackButton = styled(Button)`
  color: #acb1c0;
`

export const LoadingPage = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
`

export const LoadingCenter = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    color: #0080ED;
    font-size: 152px;
    margin-bottom;
  }

  h1 {
    font-weight: bold;
    font-size: 24px;
    color: #333333;
    margin-bottom: 10px;
  }

  p {
    color: #818181;
    font-size: 16px;
    font-weight: bold;
  }
`

export const LoadingBottom = styled.div`
  p {
    color: #818181;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
`

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    maxWidth: "300px",
    margin: "0 auto",
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress)

export const HeaderEditProfile = styled.div`
  width: 100%;
  .left-btn {
    float: left;
  }
  .right-btn {
    float: right;
  }
`

export const CloseButton = withStyles(() => ({
  root: {
    color: "#333333",
  },
}))(IconButton)

export const Dots = withStyles(() => ({
  root: {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
  },
}))(MobileStepper)

export const ProfileFormBox = styled.form`
  margin-bottom: 80px;

  h1 {
    margin: 0 0 10px;
    color: #333333;
    font-family: Barlow, sans-serif;
    font-weight: 600;
    font-size: 24px;
  }
  h2 {
    margin: 0 0 40px;
    color: #818181;
    font-family: Barlow, sans-serif;
    font-weight: 600;
    font-size: 16px;
  }
`
export const ActionAreaEditProfile = styled.div`
  text-align: center;
  position: fixed;
  bottom: 30px;
  width: 100%;
  max-width: 600px;
  padding: 20px 40px;
  left: 50%;
  transform: translateX(-50%);
`

export const ProfileFormSingleCheckboxArea = styled.div`
  text-align: center;
  margin-bottom: 15px;

  .MuiFormControlLabel-root {
    margin-right: 0px;
  }
  .MuiIconButton-label {
    font-size: 16px;
  }
  .MuiIconButton-label span {
    border-radius: 6px;
  }
  .MuiIconButton-label svg {
    font-size: 14px;
  }
  .MuiFormControlLabel-label {
    color: #818181;
    font-family: Barlow;
    font-weight: 500;
    font-size: 13px;
  }
`
