import React from "react"

const LogoStartseBlue = () => {
  return (
    <svg
      id="Grupo_4942"
      data-name="Grupo 4942"
      xmlns="http://www.w3.org/2000/svg"
      width="78.813"
      height="27.589"
      viewBox="0 0 78.813 27.589"
    >
      <g id="Grupo_381" data-name="Grupo 381" transform="translate(44.326 0)">
        <circle
          id="Elipse_1270"
          data-name="Elipse 1270"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(0)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1271"
          data-name="Elipse 1271"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(2.759 24.83)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1272"
          data-name="Elipse 1272"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(6.897)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1273"
          data-name="Elipse 1273"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(6.897 4.138)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1274"
          data-name="Elipse 1274"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(6.897 20.692)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1275"
          data-name="Elipse 1275"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(6.897 24.83)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1276"
          data-name="Elipse 1276"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(11.036)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1277"
          data-name="Elipse 1277"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(11.036 4.138)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1278"
          data-name="Elipse 1278"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(11.036 8.277)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1279"
          data-name="Elipse 1279"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(11.036 16.553)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1280"
          data-name="Elipse 1280"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(11.036 20.692)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1281"
          data-name="Elipse 1281"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(11.036 24.83)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1282"
          data-name="Elipse 1282"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(15.174)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1283"
          data-name="Elipse 1283"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(15.174 4.138)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1284"
          data-name="Elipse 1284"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(15.174 8.277)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1285"
          data-name="Elipse 1285"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(15.174 12.415)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1286"
          data-name="Elipse 1286"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(15.174 16.553)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1287"
          data-name="Elipse 1287"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(15.174 20.692)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1288"
          data-name="Elipse 1288"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(15.174 24.83)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1289"
          data-name="Elipse 1289"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(19.312)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1290"
          data-name="Elipse 1290"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(19.312 4.138)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1291"
          data-name="Elipse 1291"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(19.312 8.277)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1292"
          data-name="Elipse 1292"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(19.312 12.415)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1293"
          data-name="Elipse 1293"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(19.312 16.553)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1294"
          data-name="Elipse 1294"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(19.312 20.692)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1295"
          data-name="Elipse 1295"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(19.312 24.83)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1296"
          data-name="Elipse 1296"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(23.451 4.138)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1297"
          data-name="Elipse 1297"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(23.171 8.631) rotate(-13.282)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1298"
          data-name="Elipse 1298"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(23.451 12.415)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1299"
          data-name="Elipse 1299"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(23.451 16.553)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1300"
          data-name="Elipse 1300"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(23.451 20.692)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1301"
          data-name="Elipse 1301"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(27.589 8.277)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1302"
          data-name="Elipse 1302"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(27.386 12.654) rotate(-9.217)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1303"
          data-name="Elipse 1303"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="matrix(0.973, -0.23, 0.23, 0.973, 27.309, 16.907)"
          fill="#0080ed"
        />
        <circle
          id="Elipse_1304"
          data-name="Elipse 1304"
          cx="1.379"
          cy="1.379"
          r="1.379"
          transform="translate(31.727 12.415)"
          fill="#0080ed"
        />
      </g>
      <g id="Grupo_382" data-name="Grupo 382" transform="translate(0 20.714)">
        <circle
          id="Elipse_1305"
          data-name="Elipse 1305"
          cx="3.437"
          cy="3.437"
          r="3.437"
          transform="translate(0 0)"
          fill="#0080ed"
        />
      </g>
      <g
        id="Grupo_384"
        data-name="Grupo 384"
        transform="translate(13.75 0.063)"
      >
        <path
          id="Caminho_495"
          data-name="Caminho 495"
          d="M2055.189,174.214l-1.633-.241c-3.125-.467-5.712-.993-5.712-2.7s2.053-2.812,5.23-2.812a16.554,16.554,0,0,1,7.384,1.813l.032.015a1.078,1.078,0,0,0,1.413-.313c.446-.6.849-1.255,1.239-1.887.2-.317.389-.632.589-.942a1.242,1.242,0,0,0,.228-.973,1.166,1.166,0,0,0-.579-.7,22.4,22.4,0,0,0-10.43-2.4,15.3,15.3,0,0,0-8.257,2.137,7.4,7.4,0,0,0-3.6,6.4c0,4.337,2.976,6.866,9.1,7.732l1.839.26c3.812.543,5.876,1.14,5.876,2.741,0,1.693-2.16,2.83-5.375,2.83a16.464,16.464,0,0,1-8.323-2.268,1.166,1.166,0,0,0-1.586.195c-.4.486-2.035,2.771-2.167,3.057a1.121,1.121,0,0,0,.428,1.44,22.644,22.644,0,0,0,11.626,2.945,15.886,15.886,0,0,0,8.433-2.128,7.592,7.592,0,0,0,3.731-6.626C2064.679,177.685,2061.485,175.139,2055.189,174.214Z"
          transform="translate(-2040.35 -163.071)"
          fill="#0080ed"
        />
      </g>
    </svg>
  )
}

export default LogoStartseBlue
