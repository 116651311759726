import React from "react"
import AutocompleteUI from "@material-ui/lab/Autocomplete"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}))

const AutoComplete = (props) => {
  const classes = useStyles()

  return (
    <AutocompleteUI
      className={classes.root}
      noOptionsText={"Sem dados"}
      size="small"
      {...props}
    />
  )
}

export default AutoComplete
