import axios from "axios"

const URI = process.env.REACT_APP_SERVICES_URI

const enrollUser = async (userID, eventSlug, profile = false) => {
  try {
    const event = await axios.get(
      `${URI}/squads/event?filter[slug]=${eventSlug}`
    )
    const eventID = event.data.rows[0]._id
    const response = await axios.post(`${URI}/event-enroll`, {
      userID,
      eventID,
      profile,
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

export default enrollUser
