import React, { useState, useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"

import yup from "modules/form-validation"
import * as patterns from "modules/form-validation/pattern"
import auth from "modules/services/auth"
import { useApp } from "modules/hooks/login/app"
import { useClient } from "modules/hooks/login/client"
import { gtm, splitName } from "modules/helpers"
import enrollUser from "modules/services/lambdas/enrollUser"
import qs from "qs"
import { useTracking } from "modules/hooks/tracking"

import { SignupForm, SignupContainer, HelperText } from "./style"
import TextField from "components/Form/TextField"
import SingleCheckbox from "components/Form/Checkbox/SingleCheckbox"
import GroupedInputs from "components/Form/GroupedInputs"
import { useLocation } from "react-router-dom"
import Error from "./Error"
import LoginButton from "components/Button/LoginButton"
import Link from "@material-ui/core/Link"
import { CustomLabel } from "components/Form/Checkbox/style"
import PreLoggedUser from "components/PreLoggedUser"
import RedirectQS from "components/Router/RedirectQS"
import { ProfileIcon, LockIcon } from "components/icons"
import InputAdornment from "@material-ui/core/InputAdornment"
import updateHubspotProps from "modules/services/lambdas/updateHubspotProps"
import LinkQS from "components/Router/LinkQS"

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^([A-Za-zÀ-ÖØ-öø-ÿ]{3,})+\s+([A-Za-zÀ-ÖØ-öø-ÿ\s]{2,})+$/,
      "Nome completo obrigatório"
    )
    .required(),
  password: patterns.password,
  passwordAgain: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "As senhas não são iguais"),
  agree: yup
    .bool()
    .oneOf([true], "É necessário aceitar o termo para continuar"),
})

const Signup = () => {
  const { search } = useLocation()
  const queryString = useMemo(() => {
    let _search = search ? search : ""
    return qs.parse(_search.replace("?", ""))
  }, [search])

  const { track, identify } = useTracking()

  const location = useLocation()
  const defaultValues = {
    email: location.state?.email,
  }
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })
  const [error, setError] = useState(null)
  const { setLoading } = useApp()
  const {
    getQueryString,
    client,
    view,
    redirect,
    config,
    setViewTitle,
  } = useClient()

  const onSubmit = async (data) => {
    setError(null)
    setLoading(true)
    try {
      const [firstName, lastName] = splitName(data.name)
      const response = await auth.post("oauth2/signup" + getQueryString(), {
        ...data,
        email: defaultValues.email,
        firstName,
        lastName,
      })
      if (!response || !response.data || !response.data.message)
        throw new Error("Erro ao criar conta, por favor tente mais tarde")

      if (view === "prime")
        gtm("login", data.email, data.firstName, data.lastName)

      const loginResponse = await auth.post(
        "auth/signin",
        {
          client_id: client.client_id,
          email: defaultValues.email,
          password: data.password,
        },
        { withCredentials: true }
      )

      if (!loginResponse || !loginResponse.data || !loginResponse.data.email)
        throw new Error("Falha ao fazer login")

      const eventSlug = queryString?.event
      if (eventSlug) {
        await enrollUser(loginResponse.data._id, eventSlug)
        await updateHubspotProps(loginResponse.data, eventSlug)
      }

      try {
        track("Create Account", {
          redirect_to: queryString.redirect_to
            ? queryString.redirect_to
            : queryString.redirect_uri,
        })
        identify(loginResponse.data.email)
      } catch (err) {
        console.log(err)
      }

      redirect()
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (error.response.data.message.indexOf("11000") > -1)
          return setError("Esse e-mail já está sendo utilizado")

        return setError(error.response.data.message)
      }
      setError("Erro ao criar conta, por favor tente mais tarde")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setViewTitle("Digite seu nome e crie uma senha:")
    // eslint-disable-next-line
  }, [])

  if (!location.state?.email) return <RedirectQS to="/authentication/signin" />

  return (
    <SignupContainer>
      <h1 className="signup-title">Bem-vindo</h1>

      <PreLoggedUser email={defaultValues.email} />
      <SignupForm
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        {config && config.description && <p>{config.description}</p>}
        <h3 className="signup-subtitle">Digite seu nome e crie uma senha:</h3>
        <TextField
          id="name"
          inputRef={register}
          label="Nome"
          size="medium"
          name="name"
          errors={errors}
          autoFocus={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ProfileIcon />
              </InputAdornment>
            ),
          }}
        />

        <GroupedInputs>
          <TextField
            id="password"
            inputRef={register}
            label="Senha"
            size="medium"
            name="password"
            type="password"
            errors={errors}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            id="passwordAgain"
            inputRef={register}
            label="Confirmação de senha"
            size="medium"
            name="passwordAgain"
            type="password"
            errors={errors}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
        </GroupedInputs>
        <HelperText style={{ texAlign: "left" }}>
          Use oito ou mais caracteres com uma combinação de letras, números e
          símbolos
        </HelperText>

        {config?.privacy || config?.terms ? (
          <SingleCheckbox
            errors={errors}
            name="agree"
            register={register}
            defaultValue={true}
            label={
              config?.privacy && config?.terms ? (
                <CustomLabel>
                  <span>Eu concordo com os </span>
                  <Link target={"_blank"} href={config?.terms}>
                    Termos de Uso{" "}
                  </Link>
                  <span> e a </span>
                  <Link target={"_blank"} href={config?.privacy}>
                    Política de Privacidade
                  </Link>
                </CustomLabel>
              ) : config?.privacy ? (
                <CustomLabel>
                  <span>Eu concordo com a </span>
                  <Link target={"_blank"} href={config?.privacy}>
                    Política de Privacidade
                  </Link>
                </CustomLabel>
              ) : config?.terms ? (
                <CustomLabel>
                  <span>Eu concordo com o </span>
                  <Link target={"_blank"} href={config?.terms}>
                    Termos de Uso
                  </Link>
                </CustomLabel>
              ) : null
            }
          />
        ) : null}

        <Error text={error} />

        <LoginButton fullWidth type="submit">
          Continuar
        </LoginButton>
        <LinkQS
          to="/authentication/signin/local"
          state={{ email: location.state?.email }}
          className="cancel"
        >
          Já tenho uma conta
        </LinkQS>
      </SignupForm>
    </SignupContainer>
  )
}

export default Signup
