import React from "react"
import { useLocation, useHistory } from "react-router-dom"
import Chip from "@material-ui/core/Chip"
import FaceIcon from "@material-ui/icons/Face"

const PreLoggedUser = ({ email }) => {
  const { push } = useHistory()
  const { search } = useLocation()

  const handleBack = () => {
    push("/authentication/signin/" + search)
  }

  return (
    <Chip
      icon={<FaceIcon />}
      label={email}
      onClick={handleBack}
      onDelete={handleBack}
      variant="outlined"
      color="primary"
      className="pre-logged-user"
    />
  )
}

export default PreLoggedUser
