import React from "react"
import { FieldArea, Label } from "./styled"
import ReactSelectMaterialUi from "react-select-material-ui"
import styled from "styled-components"

const stylesFn = ({ hasError }) => ({
  control: (base, { isFocused }) => ({
    ...base,
    width: "160px",
    maxWidth: "100%",
    marginLeft: "10px",
    borderBottom: isFocused ? "#0080ED" : "rgba(0, 0, 0, 0.42)",
    borderBottomWidth: isFocused ? 2 : 1,
    borderBottomStyle: "solid",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderRadius: 0,
    boxShadow: "none",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
      borderBottomWidth: 2,
    },
    ...(hasError
      ? {
          borderBottomColor: "#f44336 !important",
        }
      : {}),
    "@media screen and (max-width: 600px)": {
      width: "100%",
      marginLeft: "0px",
    },
  }),
  option: (base, { isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? "#0080ED" : "#ffffff",
    color: isSelected ? "#ffffff" : "#000000DE",
    "&:hover": { backgroundColor: "#0080ED", color: "#ffffff" },
  }),
})

const SelectContainer = styled.div`
  @media screen and (max-width: 600px) {
    width: 100%;
    padding-right: 25px;
    & > .MuiFormControl-root {
      width: 100% !important;
    }
  }
`

export const Select = ({
  label,
  setRequired,
  hide,
  SelectProps,
  disabled,
  ...props
}) => {
  if (hide) return null

  return (
    <FieldArea>
      <Label>{label}</Label>
      <SelectContainer>
        <ReactSelectMaterialUi
          SelectProps={{
            msgNoValidValue: "O novo valor não é válido",
            isValidNewOption: (value) =>
              value && value.length > 2 && value.length < 28,
            formatCreateLabel: (value) => `Criar ${value}`,
            msgNoOptionsMatchFilter: "Sem dados",
            styles: stylesFn({
              hasError: setRequired,
            }),
            ...SelectProps,
          }}
          style={disabled ? { pointerEvents: "none" } : null}
          {...props}
        />
        {setRequired && <small className="error-msg">Obrigatório</small>}
      </SelectContainer>
    </FieldArea>
  )
}
