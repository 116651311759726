import React from "react"

import { Input } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { TextError, ContainerButton } from "./style"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    width: "100%",
    backgroundColor: " #E5E5E5",
    borderRadius: "9px",
    height: "50px",
    padding: "20px 15px",
    borderBottom: "0px solid",
  },
}))

const Button = ({ errors, ...props }) => {
  const classes = useStyles()
  const error = errors && errors[props.name] ? errors[props.name] : null

  return (
    <ContainerButton>
      <Input
        className={classes.root}
        variant="contained"
        color="primary"
        error={!!error}
        {...props}
      />
      {error && <TextError>{error.message}</TextError>}
    </ContainerButton>
  )
}

export default Button
