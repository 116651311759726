import React from "react"
import { Link, useLocation } from "react-router-dom"

export default ({ to, state, ...props }) => {
  const { search } = useLocation()

  return (
    <Link
      {...props}
      to={{
        pathname: to,
        state,
        search,
      }}
    />
  )
}
