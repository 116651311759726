import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

const EmailIcon = (props) => (
  <SvgIcon
    className="startse-icon"
    viewBox="0 0 16 18"
    fontSize="small"
    {...props}
  >
    <path d="M6.141,18.363c-.111,0-.19,0-.262,0l-.132,0a2.037,2.037,0,0,1-.371-.043A14.166,14.166,0,0,1,3.23,18L3,17.952A3.643,3.643,0,0,1,.258,16.2,2.7,2.7,0,0,1,0,15.051a2.645,2.645,0,0,1,.264-1.162A3.986,3.986,0,0,1,3.211,12.1a15.4,15.4,0,0,1,2.152-.3c.661-.058,1.332-.087,2-.087s1.335.029,2,.087l.424.036A15.838,15.838,0,0,1,11.5,12.1c1.589.327,2.528.9,2.957,1.8a2.679,2.679,0,0,1,0,2.3c-.426.9-1.391,1.487-2.946,1.793l-.43.089a14.453,14.453,0,0,1-1.729.225c-.658.056-1.326.084-1.985.084C6.956,18.4,6.543,18.386,6.141,18.363Zm-.675-5.243a14.283,14.283,0,0,0-1.984.281,2.952,2.952,0,0,0-2.028,1.075,1.3,1.3,0,0,0-.128.574,1.335,1.335,0,0,0,.127.577c.234.485.943.855,2.049,1.071l.325.069A14.264,14.264,0,0,0,5.551,17a1.693,1.693,0,0,0,.264.031l.362.006c.395.022.8.032,1.192.032.623,0,1.257-.026,1.883-.08a13.194,13.194,0,0,0,1.988-.29l.207-.044c.976-.221,1.6-.574,1.815-1.019a1.345,1.345,0,0,0,0-1.158c-.225-.474-.927-.846-2.032-1.074a14.327,14.327,0,0,0-1.979-.279l-.012,0c-.621-.055-1.252-.082-1.874-.082S6.1,13.065,5.466,13.121ZM2.476,4.907A4.887,4.887,0,1,1,7.363,9.815,4.9,4.9,0,0,1,2.476,4.907Zm1.326,0A3.561,3.561,0,1,0,7.363,1.332,3.572,3.572,0,0,0,3.8,4.907Z" />
  </SvgIcon>
)
export default EmailIcon
