export default [
  {
    id_pais: 1,
    name: "Afeganistão",
  },
  {
    id_pais: 2,
    name: "África do Sul",
  },
  {
    id_pais: 3,
    name: "Akrotiri",
  },
  {
    id_pais: 4,
    name: "Albânia",
  },
  {
    id_pais: 5,
    name: "Alemanha",
  },
  {
    id_pais: 6,
    name: "Andorra",
  },
  {
    id_pais: 7,
    name: "Angola",
  },
  {
    id_pais: 8,
    name: "Anguila",
  },
  {
    id_pais: 9,
    name: "Antárctida",
  },
  {
    id_pais: 10,
    name: "Antígua e Barbuda",
  },
  {
    id_pais: 11,
    name: "Antilhas Neerlandesas",
  },
  {
    id_pais: 12,
    name: "Arábia Saudita",
  },
  {
    id_pais: 13,
    name: "Arctic Ocean",
  },
  {
    id_pais: 14,
    name: "Argélia",
  },
  {
    id_pais: 15,
    name: "Argentina",
  },
  {
    id_pais: 16,
    name: "Arménia",
  },
  {
    id_pais: 17,
    name: "Aruba",
  },
  {
    id_pais: 18,
    name: "Ashmore and Cartier Islands",
  },
  {
    id_pais: 19,
    name: "Atlantic Ocean",
  },
  {
    id_pais: 20,
    name: "Austrália",
  },
  {
    id_pais: 21,
    name: "Áustria",
  },
  {
    id_pais: 22,
    name: "Azerbaijão",
  },
  {
    id_pais: 23,
    name: "Baamas",
  },
  {
    id_pais: 24,
    name: "Bangladeche",
  },
  {
    id_pais: 25,
    name: "Barbados",
  },
  {
    id_pais: 26,
    name: "Barém",
  },
  {
    id_pais: 27,
    name: "Bélgica",
  },
  {
    id_pais: 28,
    name: "Belize",
  },
  {
    id_pais: 29,
    name: "Benim",
  },
  {
    id_pais: 30,
    name: "Bermudas",
  },
  {
    id_pais: 31,
    name: "Bielorrússia",
  },
  {
    id_pais: 32,
    name: "Birmânia",
  },
  {
    id_pais: 33,
    name: "Bolívia",
  },
  {
    id_pais: 34,
    name: "Bósnia e Herzegovina",
  },
  {
    id_pais: 35,
    name: "Botsuana",
  },
  {
    id_pais: 36,
    name: "Brasil",
  },
  {
    id_pais: 37,
    name: "Brunei",
  },
  {
    id_pais: 38,
    name: "Bulgária",
  },
  {
    id_pais: 39,
    name: "Burquina Faso",
  },
  {
    id_pais: 40,
    name: "Burúndi",
  },
  {
    id_pais: 41,
    name: "Butão",
  },
  {
    id_pais: 42,
    name: "Cabo Verde",
  },
  {
    id_pais: 43,
    name: "Camarões",
  },
  {
    id_pais: 44,
    name: "Camboja",
  },
  {
    id_pais: 45,
    name: "Canadá",
  },
  {
    id_pais: 46,
    name: "Catar",
  },
  {
    id_pais: 47,
    name: "Cazaquistão",
  },
  {
    id_pais: 48,
    name: "Chade",
  },
  {
    id_pais: 49,
    name: "Chile",
  },
  {
    id_pais: 50,
    name: "China",
  },
  {
    id_pais: 51,
    name: "Chipre",
  },
  {
    id_pais: 52,
    name: "Clipperton Island",
  },
  {
    id_pais: 53,
    name: "Colômbia",
  },
  {
    id_pais: 54,
    name: "Comores",
  },
  {
    id_pais: 55,
    name: "Congo-Brazzaville",
  },
  {
    id_pais: 56,
    name: "Congo-Kinshasa",
  },
  {
    id_pais: 57,
    name: "Coral Sea Islands",
  },
  {
    id_pais: 58,
    name: "Coreia do Norte",
  },
  {
    id_pais: 59,
    name: "Coreia do Sul",
  },
  {
    id_pais: 60,
    name: "Costa do Marfim",
  },
  {
    id_pais: 61,
    name: "Costa Rica",
  },
  {
    id_pais: 62,
    name: "Croácia",
  },
  {
    id_pais: 63,
    name: "Cuba",
  },
  {
    id_pais: 64,
    name: "Dhekelia",
  },
  {
    id_pais: 65,
    name: "Dinamarca",
  },
  {
    id_pais: 66,
    name: "Domínica",
  },
  {
    id_pais: 67,
    name: "Egipto",
  },
  {
    id_pais: 68,
    name: "Emiratos Árabes Unidos",
  },
  {
    id_pais: 69,
    name: "Equador",
  },
  {
    id_pais: 70,
    name: "Eritreia",
  },
  {
    id_pais: 71,
    name: "Eslováquia",
  },
  {
    id_pais: 72,
    name: "Eslovénia",
  },
  {
    id_pais: 73,
    name: "Espanha",
  },
  {
    id_pais: 74,
    name: "Estados Unidos",
  },
  {
    id_pais: 75,
    name: "Estónia",
  },
  {
    id_pais: 76,
    name: "Etiópia",
  },
  {
    id_pais: 77,
    name: "Faroé",
  },
  {
    id_pais: 78,
    name: "Fiji",
  },
  {
    id_pais: 79,
    name: "Filipinas",
  },
  {
    id_pais: 80,
    name: "Finlândia",
  },
  {
    id_pais: 81,
    name: "França",
  },
  {
    id_pais: 82,
    name: "Gabão",
  },
  {
    id_pais: 83,
    name: "Gâmbia",
  },
  {
    id_pais: 84,
    name: "Gana",
  },
  {
    id_pais: 85,
    name: "Gaza Strip",
  },
  {
    id_pais: 86,
    name: "Geórgia",
  },
  {
    id_pais: 87,
    name: "Geórgia do Sul e Sandwich do Sul",
  },
  {
    id_pais: 88,
    name: "Gibraltar",
  },
  {
    id_pais: 89,
    name: "Granada",
  },
  {
    id_pais: 90,
    name: "Grécia",
  },
  {
    id_pais: 91,
    name: "Gronelândia",
  },
  {
    id_pais: 92,
    name: "Guame",
  },
  {
    id_pais: 93,
    name: "Guatemala",
  },
  {
    id_pais: 94,
    name: "Guernsey",
  },
  {
    id_pais: 95,
    name: "Guiana",
  },
  {
    id_pais: 96,
    name: "Guiné",
  },
  {
    id_pais: 97,
    name: "Guiné Equatorial",
  },
  {
    id_pais: 98,
    name: "Guiné-Bissau",
  },
  {
    id_pais: 99,
    name: "Haiti",
  },
  {
    id_pais: 100,
    name: "Honduras",
  },
  {
    id_pais: 101,
    name: "Hong Kong",
  },
  {
    id_pais: 102,
    name: "Hungria",
  },
  {
    id_pais: 103,
    name: "Iémen",
  },
  {
    id_pais: 104,
    name: "Ilha Bouvet",
  },
  {
    id_pais: 105,
    name: "Ilha do Natal",
  },
  {
    id_pais: 106,
    name: "Ilha Norfolk",
  },
  {
    id_pais: 107,
    name: "Ilhas Caimão",
  },
  {
    id_pais: 108,
    name: "Ilhas Cook",
  },
  {
    id_pais: 109,
    name: "Ilhas dos Cocos",
  },
  {
    id_pais: 110,
    name: "Ilhas Falkland",
  },
  {
    id_pais: 111,
    name: "Ilhas Heard e McDonald",
  },
  {
    id_pais: 112,
    name: "Ilhas Marshall",
  },
  {
    id_pais: 113,
    name: "Ilhas Salomão",
  },
  {
    id_pais: 114,
    name: "Ilhas Turcas e Caicos",
  },
  {
    id_pais: 115,
    name: "Ilhas Virgens Americanas",
  },
  {
    id_pais: 116,
    name: "Ilhas Virgens Britânicas",
  },
  {
    id_pais: 117,
    name: "Índia",
  },
  {
    id_pais: 118,
    name: "Indian Ocean",
  },
  {
    id_pais: 119,
    name: "Indonésia",
  },
  {
    id_pais: 120,
    name: "Irão",
  },
  {
    id_pais: 121,
    name: "Iraque",
  },
  {
    id_pais: 122,
    name: "Irlanda",
  },
  {
    id_pais: 123,
    name: "Islândia",
  },
  {
    id_pais: 124,
    name: "Israel",
  },
  {
    id_pais: 125,
    name: "Itália",
  },
  {
    id_pais: 126,
    name: "Jamaica",
  },
  {
    id_pais: 127,
    name: "Jan Mayen",
  },
  {
    id_pais: 128,
    name: "Japão",
  },
  {
    id_pais: 129,
    name: "Jersey",
  },
  {
    id_pais: 130,
    name: "Jibuti",
  },
  {
    id_pais: 131,
    name: "Jordânia",
  },
  {
    id_pais: 132,
    name: "Kuwait",
  },
  {
    id_pais: 133,
    name: "Laos",
  },
  {
    id_pais: 134,
    name: "Lesoto",
  },
  {
    id_pais: 135,
    name: "Letónia",
  },
  {
    id_pais: 136,
    name: "Líbano",
  },
  {
    id_pais: 137,
    name: "Libéria",
  },
  {
    id_pais: 138,
    name: "Líbia",
  },
  {
    id_pais: 139,
    name: "Listenstaine",
  },
  {
    id_pais: 140,
    name: "Lituânia",
  },
  {
    id_pais: 141,
    name: "Luxemburgo",
  },
  {
    id_pais: 142,
    name: "Macau",
  },
  {
    id_pais: 143,
    name: "Macedónia",
  },
  {
    id_pais: 144,
    name: "Madagáscar",
  },
  {
    id_pais: 145,
    name: "Malásia",
  },
  {
    id_pais: 146,
    name: "Malávi",
  },
  {
    id_pais: 147,
    name: "Maldivas",
  },
  {
    id_pais: 148,
    name: "Mali",
  },
  {
    id_pais: 149,
    name: "Malta",
  },
  {
    id_pais: 150,
    name: "Man, Isle of",
  },
  {
    id_pais: 151,
    name: "Marianas do Norte",
  },
  {
    id_pais: 152,
    name: "Marrocos",
  },
  {
    id_pais: 153,
    name: "Maurícia",
  },
  {
    id_pais: 154,
    name: "Mauritânia",
  },
  {
    id_pais: 155,
    name: "Mayotte",
  },
  {
    id_pais: 156,
    name: "México",
  },
  {
    id_pais: 157,
    name: "Micronésia",
  },
  {
    id_pais: 158,
    name: "Moçambique",
  },
  {
    id_pais: 159,
    name: "Moldávia",
  },
  {
    id_pais: 160,
    name: "Mónaco",
  },
  {
    id_pais: 161,
    name: "Mongólia",
  },
  {
    id_pais: 162,
    name: "Monserrate",
  },
  {
    id_pais: 163,
    name: "Montenegro",
  },
  {
    id_pais: 164,
    name: "Mundo",
  },
  {
    id_pais: 165,
    name: "Namíbia",
  },
  {
    id_pais: 166,
    name: "Nauru",
  },
  {
    id_pais: 167,
    name: "Navassa Island",
  },
  {
    id_pais: 168,
    name: "Nepal",
  },
  {
    id_pais: 169,
    name: "Nicarágua",
  },
  {
    id_pais: 170,
    name: "Níger",
  },
  {
    id_pais: 171,
    name: "Nigéria",
  },
  {
    id_pais: 172,
    name: "Niue",
  },
  {
    id_pais: 173,
    name: "Noruega",
  },
  {
    id_pais: 174,
    name: "Nova Caledónia",
  },
  {
    id_pais: 175,
    name: "Nova Zelândia",
  },
  {
    id_pais: 176,
    name: "Omã",
  },
  {
    id_pais: 177,
    name: "Pacific Ocean",
  },
  {
    id_pais: 178,
    name: "Países Baixos",
  },
  {
    id_pais: 179,
    name: "Palau",
  },
  {
    id_pais: 180,
    name: "Panamá",
  },
  {
    id_pais: 181,
    name: "Papua-Nova Guiné",
  },
  {
    id_pais: 182,
    name: "Paquistão",
  },
  {
    id_pais: 183,
    name: "Paracel Islands",
  },
  {
    id_pais: 184,
    name: "Paraguai",
  },
  {
    id_pais: 185,
    name: "Peru",
  },
  {
    id_pais: 186,
    name: "Pitcairn",
  },
  {
    id_pais: 187,
    name: "Polinésia Francesa",
  },
  {
    id_pais: 188,
    name: "Polónia",
  },
  {
    id_pais: 189,
    name: "Porto Rico",
  },
  {
    id_pais: 190,
    name: "Portugal",
  },
  {
    id_pais: 191,
    name: "Quénia",
  },
  {
    id_pais: 192,
    name: "Quirguizistão",
  },
  {
    id_pais: 193,
    name: "Quiribáti",
  },
  {
    id_pais: 194,
    name: "Reino Unido",
  },
  {
    id_pais: 195,
    name: "República Centro-Africana",
  },
  {
    id_pais: 196,
    name: "República Checa",
  },
  {
    id_pais: 197,
    name: "República Dominicana",
  },
  {
    id_pais: 198,
    name: "Roménia",
  },
  {
    id_pais: 199,
    name: "Ruanda",
  },
  {
    id_pais: 200,
    name: "Rússia",
  },
  {
    id_pais: 201,
    name: "Salvador",
  },
  {
    id_pais: 202,
    name: "Samoa",
  },
  {
    id_pais: 203,
    name: "Samoa Americana",
  },
  {
    id_pais: 204,
    name: "Santa Helena",
  },
  {
    id_pais: 205,
    name: "Santa Lúcia",
  },
  {
    id_pais: 206,
    name: "São Cristóvão e Neves",
  },
  {
    id_pais: 207,
    name: "São Marinho",
  },
  {
    id_pais: 208,
    name: "São Pedro e Miquelon",
  },
  {
    id_pais: 209,
    name: "São Tomé e Príncipe",
  },
  {
    id_pais: 210,
    name: "São Vicente e Granadinas",
  },
  {
    id_pais: 211,
    name: "Sara Ocidental",
  },
  {
    id_pais: 212,
    name: "Seicheles",
  },
  {
    id_pais: 213,
    name: "Senegal",
  },
  {
    id_pais: 214,
    name: "Serra Leoa",
  },
  {
    id_pais: 215,
    name: "Sérvia",
  },
  {
    id_pais: 216,
    name: "Singapura",
  },
  {
    id_pais: 217,
    name: "Síria",
  },
  {
    id_pais: 218,
    name: "Somália",
  },
  {
    id_pais: 219,
    name: "Southern Ocean",
  },
  {
    id_pais: 220,
    name: "Spratly Islands",
  },
  {
    id_pais: 221,
    name: "Sri Lanca",
  },
  {
    id_pais: 222,
    name: "Suazilândia",
  },
  {
    id_pais: 223,
    name: "Sudão",
  },
  {
    id_pais: 224,
    name: "Suécia",
  },
  {
    id_pais: 225,
    name: "Suíça",
  },
  {
    id_pais: 226,
    name: "Suriname",
  },
  {
    id_pais: 227,
    name: "Svalbard e Jan Mayen",
  },
  {
    id_pais: 228,
    name: "Tailândia",
  },
  {
    id_pais: 229,
    name: "Taiwan",
  },
  {
    id_pais: 230,
    name: "Tajiquistão",
  },
  {
    id_pais: 231,
    name: "Tanzânia",
  },
  {
    id_pais: 232,
    name: "Território Britânico do Oceano Índico",
  },
  {
    id_pais: 233,
    name: "Territórios Austrais Franceses",
  },
  {
    id_pais: 234,
    name: "Timor Leste",
  },
  {
    id_pais: 235,
    name: "Togo",
  },
  {
    id_pais: 236,
    name: "Tokelau",
  },
  {
    id_pais: 237,
    name: "Tonga",
  },
  {
    id_pais: 238,
    name: "Trindade e Tobago",
  },
  {
    id_pais: 239,
    name: "Tunísia",
  },
  {
    id_pais: 240,
    name: "Turquemenistão",
  },
  {
    id_pais: 241,
    name: "Turquia",
  },
  {
    id_pais: 242,
    name: "Tuvalu",
  },
  {
    id_pais: 243,
    name: "Ucrânia",
  },
  {
    id_pais: 244,
    name: "Uganda",
  },
  {
    id_pais: 245,
    name: "União Europeia",
  },
  {
    id_pais: 246,
    name: "Uruguai",
  },
  {
    id_pais: 247,
    name: "Usbequistão",
  },
  {
    id_pais: 248,
    name: "Vanuatu",
  },
  {
    id_pais: 249,
    name: "Vaticano",
  },
  {
    id_pais: 250,
    name: "Venezuela",
  },
  {
    id_pais: 251,
    name: "Vietname",
  },
  {
    id_pais: 252,
    name: "Wake Island",
  },
  {
    id_pais: 253,
    name: "Wallis e Futuna",
  },
  {
    id_pais: 254,
    name: "West Bank",
  },
  {
    id_pais: 255,
    name: "Zâmbia",
  },
  {
    id_pais: 256,
    name: "Zimbabué",
  },
]
