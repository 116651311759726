import React from "react"
import LinkUI from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#ACACAC",
    textDecoration: "underline",
    fontWeight: "bold",
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      bottom: "10px",
      right: "0",
      position: "absolute",
    },
  },
}))

const Link = (props) => {
  const classes = useStyles()

  return (
    <LinkUI
      component="button"
      variant="body2"
      className={classes.root}
      type="button"
      {...props}
    >
      Preencher Depois
    </LinkUI>
  )
}

export default Link
