import React, { useState, useEffect, useMemo } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useApp } from "modules/hooks/login/app"
import LogoStartSe from "./icon/LogoStartseBlue"
import qs from "qs"
import cookies from "modules/cookies"

import { Container, ContainerHead, ContainerSignIn, Divider } from "./style"

import SignIn from "pages/Login/Sign/components/Form/SignIn/index"
import SignUp from "pages/Login/Sign/components/Form/SignUp/index"
import { PasswordForgot } from "pages/Login/PasswordForgot"
import { useLocation } from "react-router"
import LottieLoading from "./components/LottieLoading"
import { useClient } from "modules/hooks/login/client"

const Sign = () => {
  const { search } = useLocation()
  const { fullLoading, passwordForgot } = useApp()
  const { client, checkForSession, setClient } = useClient()

  const [showSignIn, setShowSignIn] = useState(true)
  const [showSignUp, setShowSignUp] = useState(true)
  const [hideLogout, setHideLogout] = useState(false)
  const [camePlatform, setCamePlatform] = useState(false)
  const [showBackground, setShowBackground] = useState(false)

  const token = cookies.get("authStartseToken")
  const matches = useMediaQuery("(max-width: 780px)")

  const queries = useMemo(() => (search || "").replace("?", ""), [search])
  const queriesObj = useMemo(() => qs.parse(queries), [queries])
  const inputs = useMemo(() => {
    return Object.keys(queriesObj).map((key) => ({
      name: key,
      value: queriesObj[key],
    }))
  }, [queriesObj])

  useEffect(() => {
    let _search = search ? search : ""

    const queryString = qs.parse(_search.replace("?", ""))

    let client = {
      redirect_uri: process.env.REACT_APP_CLIENT_URI,
    }

    if (queryString.redirect_uri) client = queryString

    setClient(client)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (client && client.redirect_uri) checkForSession()

    // eslint-disable-next-line
  }, [client])

  useEffect(() => {
    const showBg = inputs.find((input) => input.name === "showBg")
    if (showBg && showBg.value === "true") setShowBackground(true)

    const utm = inputs.find((input) => input.name === "utm_source")
    if (utm && utm.value === "platform" && token) setCamePlatform(true)

    const hideLogout = inputs.find((input) => input.name === "hideLogout")
    if (hideLogout && hideLogout.value === "true") setHideLogout(true)

    if (matches) {
      const input = inputs.find((input) => input.name === "signUp")
      if (input && input.value === "true") {
        setShowSignIn(false)
        setShowSignUp(true)
      } else {
        setShowSignIn(true)
        setShowSignUp(false)
      }
    } else {
      setShowSignIn(true)
      setShowSignUp(true)
    }
  }, [inputs, matches, token])

  const handleForm = () => {
    if (matches) {
      setShowSignIn(!showSignIn)
      setShowSignUp(!showSignUp)
    }
  }

  return (
    <>
      {passwordForgot && <PasswordForgot />}
      <Container showBackground={showBackground} camePlatform={camePlatform}>
        {!hideLogout && (
          <ContainerHead camePlatform={camePlatform}>
            <LogoStartSe />
          </ContainerHead>
        )}
        <ContainerSignIn>
          {showSignUp && !camePlatform && <SignUp handleForm={handleForm} />}
          {showSignUp && showSignIn && !camePlatform && <Divider />}
          {showSignIn && (
            <SignIn
              handleForm={handleForm}
              camePlatform={camePlatform}
              hideLogout={hideLogout}
            />
          )}
        </ContainerSignIn>
      </Container>
      <LottieLoading fullLoading loading={fullLoading} />
    </>
  )
}

export default Sign
