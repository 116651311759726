import styled from "styled-components"
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded"
import Autocomplete from "@material-ui/lab/Autocomplete"

export const AutocompleteUI = styled(Autocomplete)`
  width: 100%;
  max-width: 25em;
  font-size: 2vh;

  .MuiInputBase-root {
    font-size: 2vh;
    padding: 0 1em !important;
    @media (max-width: 780px) {
      font-size: 14px;
    }
  }
  .MuiAutocomplete-inputRoot {
    max-height: 2.3em !important;
  }
  .MuiSvgIcon-root {
    font-size: 1.2em !important;
  }
`

export const ExpandIcon = styled(ExpandMoreRoundedIcon)`
  color: #959595;
  margin-top: -3px;
`
