import React, { useEffect } from "react"
import { Route, Switch, useLocation } from "react-router-dom"
import { GroupFormSocial } from "./style"

import { withNamespaces } from "react-i18next"

import { useApp } from "modules/hooks/login/app"
import { useClient } from "modules/hooks/login/client"
import qs from "qs"

import Login from "./Login"

import Signup from "./Signup"
import LocalLogin from "./LocalLogin"
import ForgotPassword from "./ForgotPassword"
import CreatePassword from "./CreatePassword"
import ResetPassword from "./ResetPassword"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import Error from "./Error"
import RedirectQS from "components/Router/RedirectQS"

// eslint-disable-next-line react/display-name
const Router = ({ t }) => {
  const { loading } = useApp()
  const { setClient } = useClient()
  const { search } = useLocation()

  useEffect(() => {
    let _search = search ? search : ""

    const queryString = qs.parse(_search.replace("?", ""))

    let client = {
      response_type: process.env.REACT_APP_CLIENT_TYPE,
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_CLIENT_URI,
    }

    if (queryString.redirect_uri) client = queryString

    setClient(client)

    // eslint-disable-next-line
  }, [])

  return (
    <>
      {loading && (
        <Backdrop open={true} style={{ zIndex: "9" }}>
          <CircularProgress />
        </Backdrop>
      )}
      <GroupFormSocial>
        <Switch>
          <RedirectQS
            exact
            from="/authentication"
            to="/authentication/signin"
          />
          <Route exact path="/authentication/signup" component={Signup} />
          <Route
            exact
            path="/authentication/password/reset/:id/:token"
            component={CreatePassword}
          />
          <Route
            exact
            path="/authentication/password/reset/landing/:id/:token"
            component={ResetPassword}
          />
          <Route
            exact
            path="/authentication/password/reset/invalid"
            component={() => (
              <Error text={t("text_forgot_password_later")} page />
            )}
          />
          <Route
            exact
            path="/authentication/password/forgot"
            component={ForgotPassword}
          />
          <Route
            exact
            path="/authentication/signin/local"
            component={LocalLogin}
          />
          <Route exact path="/authentication/signin" component={Login} />
          <Route
            exact
            path="/authentication/confirmation/error"
            component={() => (
              <Error text={t("text_forgot_password_later")} page />
            )}
          />
          <Route
            exact
            path="/authentication/email-confirmation/:id/:token"
            component={CreatePassword}
          />
        </Switch>
      </GroupFormSocial>
    </>
  )
}

export default withNamespaces()(Router)
