import React from "react"

import { TextFieldUI } from "./styles"

const TextField = ({ errors, ...props }) => {
  const error = errors && errors[props.name] ? errors[props.name] : null

  return (
    <TextFieldUI
      {...props}
      error={!!error}
      variant="standard"
      size="small"
      helperText={error ? error.message : props.helperText}
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

export default TextField
