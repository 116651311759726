import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

const EmailIcon = (props) => (
  <SvgIcon
    className="startse-icon"
    viewBox="0 0 16 13"
    fontSize="small"
    {...props}
  >
    <path d="M4.364,13.76a4.3,4.3,0,0,1-3.133-1.294A4.551,4.551,0,0,1,0,9.281v-4.8A4.551,4.551,0,0,1,1.232,1.294,4.3,4.3,0,0,1,4.364,0H11.62a4.535,4.535,0,0,1,3.19,1.356,4.144,4.144,0,0,1,1.182,3.124V9.247A4.184,4.184,0,0,1,14.81,12.4a4.543,4.543,0,0,1-3.2,1.356ZM1.214,4.479v4.8a3.127,3.127,0,0,0,3.15,3.311h7.242a3.289,3.289,0,0,0,2.316-.984,3.046,3.046,0,0,0,.857-2.327V4.445a2.985,2.985,0,0,0-.141-1.1l-5.2,4.321a2.4,2.4,0,0,1-2.733.087l-.129-.09-5.2-4.3A3.78,3.78,0,0,0,1.214,4.479ZM7.328,6.741a1.145,1.145,0,0,0,1.264.067l.094-.063,5.361-4.456q-.06-.07-.125-.137a3.289,3.289,0,0,0-2.316-.984H4.364a3.048,3.048,0,0,0-2.421,1.12Z" />
  </SvgIcon>
)
export default EmailIcon
