import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import moment from "moment"
import MaskedInput from "react-text-mask"

import yup from "modules/form-validation"
import { useUser } from "modules/hooks/onboarding/user"
import { concatName, splitName } from "modules/helpers"
import { useApp } from "modules/hooks/onboarding/app"
import { closeModal } from "modules/helpers"
import { countries, states } from "modules/constants"

import TextField from "../Components/Inputs/TextField"
import Button from "../styled/Button"
import Header from "../styled/Header"
import Title from "../styled/Title"
import Subtitle from "../styled/Subtitle"
import Footer from "../styled/Footer"
import { Select } from "../Components/Inputs/Select"

const locationSchema = yup.object().shape({
  country: yup.string().required(),
  state: yup.string().when("country", {
    is: "Brasil",
    then: yup.string().required(),
  }),
})

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
    />
  )
}

const Profile = ({ nextStep, show, done }) => {
  const { user, updateUser } = useUser()
  const { setLoading } = useApp()
  const [country, setCountry] = useState(user.country)
  const [state, setState] = useState(user.state)
  const [triedSent, setTriedSent] = useState(false)
  const defaultValues = {
    name: concatName(user.firstName, user.lastName),
    dateOfBirth: user.dateOfBirth
      ? moment.utc(user.dateOfBirth).format("DD/MM/YYYY")
      : "",
    city: user.city,
    phone: user.phone,
  }

  const [showFields, setShowFields] = useState([
    "name",
    defaultValues.dateOfBirth ? "dateOfBirth" : null,
    defaultValues.phone ? "phone" : null,
    user.country ? "country" : null,
    user.state ? "state" : null,
    defaultValues.city ? "city" : null,
  ])

  const onSubmit = async (data) => {
    setTriedSent(true)
    const location = {
      country: country || "",
      state: state || "",
    }
    if (!locationSchema.isValidSync(location)) return

    setLoading(true)

    const [firstName, lastName] = splitName(data.name)
    delete data.name

    data.dateOfBirth = moment(data.dateOfBirth, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    )
    data.dateOfBirth =
      data.dateOfBirth === "Invalid date" ? null : data.dateOfBirth
    try {
      await updateUser({
        ...user,
        ...data,
        ...location,
        firstName,
        lastName,
        hasCompletedProfile: true,
      })
    } catch (error) {
      return closeModal(user)
    }

    setLoading(false)
    nextStep()
  }

  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues,
  })

  useEffect(() => {
    if (country && country !== "Brasil") {
      setValue("city", "")
      setState(null)
    }
    // eslint-disable-next-line
  }, [country])

  const handleCompleted = (name) => {
    if (showFields.indexOf(name) < 0) setShowFields((prev) => [...prev, name])
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={!show ? { display: "none" } : null}
    >
      <Header>
        <Title>Meu Perfil</Title>
        <Subtitle>
          {user.hasCompletedProfile
            ? "Atualize os dados do seu perfil"
            : "Confira o seu perfil e complete seus dados atualizados"}
        </Subtitle>
      </Header>

      <TextField
        id="name"
        label="Meu nome é"
        inputRef={register({ required: "Obrigatório" })}
        name="name"
        errors={errors}
      />

      <TextField
        id="dateOfBirth"
        label="Eu nasci em"
        inputRef={register({ required: "Obrigatório" })}
        name="dateOfBirth"
        placeholder="dd/mm/aaaa"
        type="text"
        InputLabelProps={{ shrink: true }}
        errors={errors}
        hasCompleted={handleCompleted}
        inputComponent={TextMaskCustom}
      />

      {(countries || []).length > 0 && (
        <Select
          options={countries.map((item) => item.name)}
          onChange={(value) => {
            handleCompleted("country")
            setCountry(value)
          }}
          id="country"
          label="Meu país de residência é"
          hide={
            showFields.indexOf("country") < 0 &&
            showFields.indexOf("dateOfBirth") < 0
          }
          defaultValue={country}
          placeholder="país"
          setRequired={triedSent && !country}
        />
      )}

      {(states || []).length > 0 && country === "Brasil" && (
        <Select
          id="state"
          options={states.map((item) => item.estado)}
          onChange={(value) => {
            handleCompleted("state")
            setState(value)
          }}
          label="Meu estado é"
          hide={
            showFields.indexOf("country") < 0 && showFields.indexOf("state") < 0
          }
          defaultValue={state}
          placeholder="Estado"
          setRequired={triedSent && country && !state}
        />
      )}

      {country === "Brasil" && state && (
        <TextField
          id="city"
          label="E a cidade é"
          inputRef={register({
            validate: (val) =>
              country === "Brasil" && !val ? "Obrigatório" : true,
          })}
          name="city"
          placeholder="cidade"
          errors={errors}
          hasCompleted={handleCompleted}
          hide={
            showFields.indexOf("city") < 0 && showFields.indexOf("state") < 0
          }
        />
      )}

      <TextField
        id="phone"
        label="Se precisar, meu telefone é"
        inputRef={register({ required: "Obrigatório" })}
        name="phone"
        placeholder="(XX) XXXXX-XXXX"
        errors={errors}
        hasCompleted={handleCompleted}
        hide={
          showFields.indexOf("phone") < 0 &&
          (country === "Brasil"
            ? showFields.indexOf("city") < 0
            : showFields.indexOf("country") < 0)
        }
      />

      <Footer>
        <Button type="submit" disabled={showFields.indexOf("phone") < 0}>
          {done ? "Concluir" : "Avançar"}
        </Button>
      </Footer>
    </form>
  )
}

export default Profile
