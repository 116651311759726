import React, { useState, useCallback } from "react"

import { interests as interestsObject } from "modules/constants"
import { useApp } from "modules/hooks/onboarding/app"
import { useUser } from "modules/hooks/onboarding/user"
import { closeModal } from "modules/helpers"

import Chip from "components/Chip"
import ChipContainer from "./styled/ChipContainer"
import Button from "../styled/Button"
import Header from "../styled/Header"
import Title from "../styled/Title"
import Subtitle from "../styled/Subtitle"
import Footer from "../styled/Footer"
import BackButton from "components/BackButton"
import FillLater from "../styled/FillLater"
import Tabs from "./styled/Tabs"
import Label from "./styled/Label"
import Tab from "@material-ui/core/Tab"

const Interests = ({
  nextStep,
  show,
  backStep,
  disableLater,
  done,
  disableBack,
}) => {
  const { user, updateUser } = useUser()
  const { setLoading } = useApp()
  const [interests, setInterest] = useState(user.interests || [])
  const [value, setValue] = React.useState(0)

  const handleClick = (currentSelected, value) => {
    if (!currentSelected) return setInterest([value, ...interests])

    let newState = [...interests]

    const index = interests.findIndex((s) => s === value)
    if (index < 0) return

    newState.splice(index, 1)
    setInterest(newState)
  }

  const submit = useCallback(async () => {
    setLoading(true)

    try {
      await updateUser({
        ...user,
        interests,
      })
    } catch {
      return closeModal(user)
    }

    setLoading(false)
    nextStep()
  }, [interests, nextStep, setLoading, updateUser, user])

  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  const currentTab = () => {
    let interestsMapeable = []
    switch (value) {
      case 0:
        interestsMapeable = interestsObject.tech
        break

      case 1:
        interestsMapeable = interestsObject.management
        break

      case 2:
        interestsMapeable = interestsObject.skills
        break

      default:
        break
    }

    return (
      <ChipContainer>
        {interestsMapeable.map((option) => {
          const currentSelected =
            interests.findIndex((s) => s === option.value) > -1

          return (
            <Chip
              key={option.value}
              label={option.label}
              mobileGrow={option.big}
              selected={currentSelected}
              onClick={() => handleClick(currentSelected, option.value)}
            />
          )
        })}
      </ChipContainer>
    )
  }

  const handleNext = () => {
    if (value === 0 || value === 1) return setValue((prev) => prev + 1)

    submit()
  }

  const hasOne = (item) =>
    (item || []).findIndex(
      (t) => interests.findIndex((s) => s === t.value) > -1
    ) > -1

  const isAbleSubmit = () => {
    switch (value) {
      case 0:
        return hasOne(interestsObject.tech)

      case 1:
        return hasOne(interestsObject.management)

      case 2:
        return (
          hasOne(interestsObject.skills) &&
          hasOne(interestsObject.management) &&
          hasOne(interestsObject.tech)
        )

      default:
        break
    }
  }
  return (
    <div style={!show ? { display: "none" } : null}>
      <Header>
        <Title>Meus Interesses</Title>
        <Subtitle>Quais os temas de interesse que você está buscando?</Subtitle>
      </Header>

      <Tabs value={value} onChange={handleChange}>
        <Tab label="Em Tecnologia" />
        <Tab label="Em Gestão" />
        <Tab label="Em Habilidades" />
      </Tabs>

      <Label>Você pode selecionar mais de uma opção:</Label>

      {currentTab()}

      <Footer>
        {!disableBack && <BackButton onClick={backStep} />}
        {!disableLater && <FillLater onClick={nextStep} />}
        <Button onClick={handleNext} disabled={!isAbleSubmit()}>
          {done && value === 2 ? "Concluir" : "Avançar"}
        </Button>
      </Footer>
    </div>
  )
}

export default Interests
