import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

const CameraIcon = (props) => (
  <SvgIcon
    className="startse-icon"
    viewBox="0 0 16 16"
    fontSize="small"
    {...props}
  >
    <path d="M3.518,14.514A3.52,3.52,0,0,1,0,11V6.219A3.5,3.5,0,0,1,3.477,2.707l.063,0a.173.173,0,0,0,.133-.1l.4-.836.311-.636A1.956,1.956,0,0,1,6.165,0h3.8a1.953,1.953,0,0,1,1.786,1.135c.046.091.1.205.189.382l.519,1.091a.181.181,0,0,0,.171.1l.171,0a3.512,3.512,0,0,1,3.331,3.509V11a3.519,3.519,0,0,1-3.517,3.514Zm1.94-12.83c-.049.1-.108.215-.181.366L4.766,3.124a1.389,1.389,0,0,1-1.111.782l-.137.007A2.309,2.309,0,0,0,1.21,6.219V11a2.309,2.309,0,0,0,2.308,2.305h9.091A2.309,2.309,0,0,0,14.917,11V6.219a2.3,2.3,0,0,0-2.169-2.3l-.261-.009a1.387,1.387,0,0,1-1.126-.785L10.8,1.942l-.129-.259a.747.747,0,0,0-.712-.475H6.174A.75.75,0,0,0,5.458,1.684Zm2.605,9.81A3.175,3.175,0,0,1,4.9,8.328,3.111,3.111,0,0,1,5.812,6.1a3.15,3.15,0,0,1,2.251-.943l.166,0a3.169,3.169,0,0,1-.165,6.333ZM7.937,6.369A1.976,1.976,0,0,0,6.105,8.324a1.956,1.956,0,1,0,1.96-1.959Zm4.08.07a.871.871,0,0,1-.242-.063.811.811,0,0,1-.259-.178.823.823,0,0,1-.227-.56.788.788,0,0,1,.066-.317.842.842,0,0,1,.19-.276.93.93,0,0,1,.245-.157.825.825,0,0,1,.872.173.761.761,0,0,1,.153.21l.019.047a.789.789,0,0,1,.067.319.82.82,0,0,1-.235.568.8.8,0,0,1-.491.233l-.08,0Z" />
  </SvgIcon>
)
export default CameraIcon
