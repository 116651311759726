import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

const CameraIcon = (props) => (
  <SvgIcon
    className="startse-icon"
    viewBox="0 0 17 17"
    fontSize="small"
    {...props}
  >
    <path d="M0,8.365A8.365,8.365,0,1,1,8.365,16.73,8.374,8.374,0,0,1,0,8.365Zm1.255,0a7.11,7.11,0,1,0,7.11-7.11A7.119,7.119,0,0,0,1.255,8.365ZM7.733,11.54a.628.628,0,0,1,.543-.622l.093-.006a.628.628,0,0,1,.085,1.25l-.093.005A.627.627,0,0,1,7.733,11.54Zm.006-2.569-.006-.086v-3.7A.627.627,0,0,1,8.982,5.1l.006.085v3.7a.627.627,0,0,1-1.249.086Z" />
  </SvgIcon>
)
export default CameraIcon
