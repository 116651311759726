import axios from "axios"
import { returnHubspotPropKey } from "modules/helpers"

const URI = process.env.REACT_APP_SERVICES_URI

const updateHubspotProps = async (
  userData,
  eventSlug = "",
  actionName = ""
) => {
  let props = []

  for (const prop in userData) {
    const key = returnHubspotPropKey(prop)
    if (key) {
      props.push([key, userData[prop]])
    }
  }

  try {
    const response = await axios.post(`${URI}/update-hubspot-props`, {
      email: userData.email,
      props,
      eventSlug,
      actionName,
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

export default updateHubspotProps
