import React from "react"
import ButtonUI from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    textTransform: "unset",
    fontWeight: "bold",
  },
}))

const Button = (props) => {
  const classes = useStyles()

  return (
    <ButtonUI
      className={classes.root}
      variant="contained"
      color="primary"
      {...props}
    />
  )
}

export default Button
