import React, { useState } from "react"
import { useForm } from "react-hook-form"

import yup from "modules/form-validation"
import {
  jobtitle as jobtitleData,
  employees as employeesData,
  revenues as revenuesData,
  segment as segmentData,
  department as departmentData,
} from "modules/constants"
import { useApp } from "modules/hooks/onboarding/app"
import { useUser } from "modules/hooks/onboarding/user"
import { closeModal } from "modules/helpers"

import TextField from "../Components/Inputs/TextField"
import Button from "../styled/Button"
import Header from "../styled/Header"
import Title from "../styled/Title"
import Subtitle from "../styled/Subtitle"
import Footer from "../styled/Footer"
import SingleCheckbox from "components/Form/Checkbox/SingleCheckbox"
import BackButton from "components/BackButton"
import FillLater from "../styled/FillLater"
import { Select } from "../Components/Inputs/Select"

const validationSchema = yup.object().shape({
  name: yup.string().when("unemployed", {
    is: false,
    then: yup.string().required(),
  }),
  unemployed: yup.bool(),
})

const selectSchema = yup.object().shape({
  jobtitle: yup.string().required(),
  revenues: yup.string().required(),
  employees: yup.string().required(),
  segment: yup.string().required(),
  department: yup.string().required(),
})

const Professional = ({
  nextStep,
  show,
  backStep,
  disableLater,
  disableBack,
  done,
}) => {
  const { user, updateUser } = useUser()
  const { setLoading } = useApp()
  const [jobtitle, setJobtitle] = useState(user.jobtitle)
  const [segment, setSegment] = useState(user.segment)
  const [department, setDepartment] = useState(user.department)
  const [employees, setEmployees] = useState((user.company || {}).employees)
  const [revenues, setRevenues] = useState((user.company || {}).revenues)
  const [triedSent, setTriedSent] = useState(false)

  const defaultValues = {
    name: (user.company || {}).name || "",
    unemployed: user.jobtitle === "unemployed",
  }

  const userJobtitles =
    user.jobtitle &&
    !(jobtitleData.find((i) => i.value === user.jobtitle) || {}).label
      ? [
          ...jobtitleData,
          {
            value: user.jobtitle,
            label: user.jobtitle,
          },
        ]
      : jobtitleData

  const [showFields, setShowFields] = useState([
    defaultValues.name ? "name" : null,
    user.jobtitle ? "jobtitle" : null,
    (user.company || {}).revenues || (user.company || {}).revenues === null
      ? "revenues"
      : null,
    (user.company || {}).employees || (user.company || {}).employees === null
      ? "employees"
      : null,
    user.segment ? "segment" : null,
    user.department ? "department" : null,
  ])

  const onSubmit = async (data) => {
    setTriedSent(true)

    const selects = {
      jobtitle: jobtitle || "",
      segment: segment || "",
      department: department || "",
      employees:
        employees === null && (user.company || {}).employees === null
          ? "Não se aplica"
          : employees || "",
      revenues:
        revenues === null && (user.company || {}).revenues === null
          ? "Não se aplica"
          : revenues || "",
    }
    if (!data.unemployed && !selectSchema.isValidSync(selects)) return

    setLoading(true)
    let newUser = {
      ...user,
      jobtitle: data.unemployed ? "unemployed" : selects.jobtitle || null,
      segment: selects.segment || null,
      department: selects.department || null,
      company: {
        name: data.name || null,
        revenues:
          selects.revenues === "Não se aplica"
            ? null
            : selects.revenues || null,
        employees:
          selects.employees === "Não se aplica"
            ? null
            : selects.employees || null,
      },
    }

    try {
      await updateUser(newUser)
    } catch {
      return closeModal(user)
    }

    setLoading(false)
    nextStep()
  }

  const { register, handleSubmit, errors, watch } = useForm({
    validationSchema,
    defaultValues,
  })

  const handleCompleted = (name) => {
    if (showFields.indexOf(name) < 0) setShowFields((prev) => [...prev, name])
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={!show ? { display: "none" } : null}
    >
      <Header>
        <Title>Meus Dados Profissionais</Title>
        <Subtitle>
          Fale um pouco sobre o seu segmento de atuação para que possamos lhe
          auxiliar no seu networking
        </Subtitle>
      </Header>

      <TextField
        id="name"
        label="A empresa ou instituição na qual trabalho se chama"
        inputRef={register}
        name="name"
        errors={errors}
        placeholder="nome"
        hasCompleted={handleCompleted}
        leftComponent={
          <SingleCheckbox
            label="Buscando Realocação"
            errors={errors}
            name="unemployed"
            register={register}
            autoWidth
            style={{ marginLeft: "10px" }}
            defaultValue={defaultValues.unemployed}
          />
        }
      />

      <Select
        id="segment"
        options={segmentData.map((item) => item.label)}
        defaultValue={
          (segmentData.find((i) => i.value === segment) || {}).label || segment
        }
        label="O segmento dela é"
        onChange={(value) => {
          handleCompleted("segment")
          setSegment(
            (segmentData.find((i) => i.label === value) || {}).value || value
          )
        }}
        hide={
          showFields.indexOf("segment") < 0 && showFields.indexOf("name") < 0
        }
        placeholder="segmento"
        setRequired={triedSent && !segment}
      />

      <Select
        id="department"
        options={departmentData.map((item) => item.label)}
        defaultValue={
          (departmentData.find((i) => i.value === department) || {}).label ||
          department
        }
        label="O departmento em que trabalho é"
        onChange={(value) => {
          handleCompleted("department")
          setDepartment(
            (departmentData.find((i) => i.label === value) || {}).value || value
          )
        }}
        hide={
          showFields.indexOf("segment") < 0 &&
          showFields.indexOf("department") < 0
        }
        placeholder="departamento"
        setRequired={triedSent && !department}
      />

      <Select
        id="revenues"
        options={revenuesData.map((item) => item.label)}
        defaultValue={
          (revenuesData.find((i) => i.value === revenues) || {}).label ||
          revenues
        }
        label="Com o faturamento de"
        onChange={(value) => {
          handleCompleted("revenues")
          setRevenues(
            (revenuesData.find((i) => i.label === value) || {}).value || value
          )
        }}
        hide={
          showFields.indexOf("department") < 0 &&
          showFields.indexOf("revenues") < 0
        }
        placeholder="faturamento"
        setRequired={
          triedSent && !revenues && (user.company || {}).revenues !== null
        }
        style={{ width: "250px" }}
      />

      <Select
        id="employees"
        options={employeesData.map((item) => item.label)}
        defaultValue={
          (employeesData.find((i) => i.value === employees) || {}).label ||
          employees
        }
        label="Com o número de funcionários de"
        hide={
          showFields.indexOf("employees") < 0 &&
          showFields.indexOf("revenues") < 0
        }
        onChange={(value) => {
          handleCompleted("employees")
          setEmployees(
            (employeesData.find((i) => i.label === value) || {}).value || value
          )
        }}
        placeholder="funcionários"
        setRequired={
          triedSent && !employees && (user.company || {}).employees !== null
        }
      />

      <Select
        id="jobtitle"
        options={userJobtitles.map((item) => item.label)}
        defaultValue={
          (userJobtitles.find((i) => i.value === jobtitle) || {}).label ||
          jobtitle
        }
        label="Meu cargo atual hoje é"
        SelectProps={{
          isCreatable: true,
          formatCreateLabel: (value) => `Criar ${value}`,
        }}
        hide={
          showFields.indexOf("employees") < 0 &&
          showFields.indexOf("jobtitle") < 0
        }
        onChange={(value) => {
          handleCompleted("jobtitle")
          setJobtitle(
            (userJobtitles.find((i) => i.label === value) || {}).value || value
          )
        }}
        placeholder="cargo"
        setRequired={triedSent && !jobtitle}
      />

      <Footer>
        {!disableBack && <BackButton onClick={backStep} />}
        {!disableLater && <FillLater onClick={nextStep} />}
        <Button
          type="submit"
          disabled={
            showFields.indexOf("jobtitle") < 0 && !watch("unemployed", false)
          }
        >
          {done ? "Concluir" : "Avançar"}
        </Button>
      </Footer>
    </form>
  )
}

export default Professional
