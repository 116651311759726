import styled from "styled-components"
import { withStyles } from "@material-ui/core/styles"

import MuiButton from "@material-ui/core/Button"
import MuiRadioGroup from "@material-ui/core/RadioGroup"
import Tooltip from "@material-ui/core/Tooltip"

export const Main = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CardItem = styled.div`
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #00000066;
`

export const CardHeader = styled.h2`
  width: 100%;
  font-size: 16px;
  background-color: #0080ed;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  color: #fff;
  font-weight: bold;
  padding: 10px;
  text-transform: uppercase;
  span {
    font-weight: normal;
  }
  img {
    margin-right: 5px;
  }
`

export const CardBody = styled.div`
  padding: 20px 40px;
  @media screen and (max-width: 600px) {
    padding: 20px 0px;
  }
`

export const CardBottom = styled.div`
  @media screen and (max-width: 600px) {
    padding: 0px 10px;
  }

  .MuiFormControl-root.makeStyles-root-5 {
    width: 100%;
  }
`

export const CardProfile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1100px) {
    flex-wrap: nowrap;
    justify-content: start;
  }
`

export const CardProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 70px;
  justify-content: space-between;
  margin-left: 20px;
  flex-grow: 1;
  @media screen and (max-width: 600px) {
    margin-left: 0px;
    text-align: center;
    margin-top: 15px;
  }
`

export const CardProfileName = styled.h4`
  color: #0080ed;
  text-transform: uppercase;
  font-size: 18px;
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`

export const ButtonLinkedin = withStyles(() => ({
  root: {
    minWidth: "120px",
    alignSelf: "center",
    padding: "10px 8px 6px",
  },
  label: {
    flexDirection: "column",
  },
}))(MuiButton)

export const ButtonLinkedinDesc = styled.span`
  font-size: 11px;
  color: #9b9b9b;
  text-transform: none;
`

export const RadioGroup = withStyles(() => ({
  root: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "20px",
  },
}))(MuiRadioGroup)

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "#9B9B9B",
    boxShadow: "0px 1px 3px #00000066",
    fontSize: 14,
    padding: "10px 20px",
  },
}))(Tooltip)

export const SharedData = styled.p`
  color: #acacac;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  svg {
    color: #0080ed;
    margin-right: 5px;
  }
`
