import React from "react"

import { AutocompleteUI, ExpandIcon } from "./styles"

const AutoComplete = (props) => {
  return (
    <AutocompleteUI
      noOptionsText={"Sem dados"}
      size="small"
      disableClearable
      popupIcon={<ExpandIcon />}
      {...props}
    />
  )
}

export default AutoComplete
