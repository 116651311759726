import React, { useMemo, useState, useEffect } from "react"
import qs from "qs"

import { withNamespaces } from "react-i18next"

import TextField from "components/Form/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import CircularProgress from "@material-ui/core/CircularProgress"
import Avatar from "@material-ui/core/Avatar"
import LoginButton from "components/Button/LoginButton"
import { EmailIcon } from "components/icons"
import { useLocation } from "react-router-dom"
import { useClient } from "modules/hooks/login/client"
import {
  GlobalStyle,
  GroupSingleIframe,
  GroupForm,
  GroupFormSocial,
  GroupFormSocialLogos,
} from "../Login/style"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import { useTracking } from "modules/hooks/tracking"
import updateHubspotProps from "modules/services/lambdas/updateHubspotProps"

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#0080ED",
      light: "#61a7e2",
      dark: "#003a6b",
      contrastText: "#ffffff",
    },
  },
})

const Login = ({ t }) => {
  const { search } = useLocation()
  const { track, identify } = useTracking()
  const { userIsLoggedIn } = useClient()
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [formAction, setFormAction] = useState(
    `${process.env.REACT_APP_HOST}authentication/email`
  )

  useEffect(() => {
    async function userIsLogged() {
      const response = await userIsLoggedIn()
      if (response !== {}) {
        setUserData(response)
      }
      setLoading(false)
    }

    userIsLogged()
  }, [setLoading, userIsLoggedIn])

  const queries = useMemo(() => (search || "").replace("?", ""), [search])

  const queriesObj = useMemo(() => qs.parse(queries), [queries])

  const inputs = useMemo(() => {
    let params = Object.keys(queriesObj).map((key) => ({
      name: key,
      value: queriesObj[key],
    }))
    if (userData) {
      params = params.filter(
        (p) => p.name === "event" || p.name === "redirect_to"
      )
      setFormAction(`${process.env.REACT_APP_HOST}subscribe`)
    }
    return params
  }, [queriesObj, userData])

  const trackLogin = (mode) => {
    if (!userData) {
      const email = document.getElementById("email").value
      if (email !== "" && email.includes("@")) {
        updateHubspotProps(
          { email },
          queriesObj.event,
          `become-lead-${queriesObj.event}`
        )
        identify(email)
      }
      track("Clicked Subscribe Event", {
        eventName: queriesObj.event,
        mode,
      })
    } else {
      track("Clicked Subscribe Event", {
        eventName: queriesObj.event,
        mode,
      })
      identify(userData.email)
      track("Automatic Login")
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <GroupSingleIframe>
        <GlobalStyle />
        <GroupForm>
          <GroupFormSocial>
            {loading && <CircularProgress />}

            {!loading && !userData && (
              <div>
                <h3>{t("title_subscribe")}</h3>
                <GroupFormSocialLogos>
                  <a
                    href={`${process.env.REACT_APP_AUTH_URI}auth/linkedin/?${queries}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackLogin("linkedin")}
                  >
                    <img src="/img/linkedin.svg" alt="Logo Linkedin" />
                  </a>
                  <a
                    href={`${process.env.REACT_APP_AUTH_URI}auth/google/?${queries}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackLogin("google")}
                  >
                    <img src="/img/google.svg" alt="Logo Google" />
                  </a>
                  <a
                    href={`${process.env.REACT_APP_AUTH_URI}auth/facebook/?${queries}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackLogin("facebook")}
                  >
                    <img src="/img/facebook.svg" alt="Logo Facebook" />
                  </a>
                </GroupFormSocialLogos>

                <p className="divider">
                  <span>{t("text_or_subscribe")}</span>
                </p>

                <h3>{t("text_email_subscribe")}</h3>
              </div>
            )}

            {!loading && userData && (
              <>
                <p
                  style={{
                    color: "#FFFFFF",
                    fontSize: "21px",
                    letterSpacing: "0px",
                  }}
                >
                  {t("text_welcome_subscribe")},
                </p>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "35px",
                  }}
                >
                  <Avatar
                    alt="Profile photo"
                    src={userData.image}
                    style={{ width: "91px", height: "91px", margin: "10px" }}
                  />
                  <p
                    style={{
                      color: "#FFFFFF",
                      fontSize: "21px",
                      letterSpacing: "0px",
                    }}
                  >
                    {userData.fullName}
                  </p>
                </div>
              </>
            )}

            <form
              autoComplete="off"
              action={formAction}
              method="GET"
              target="_blank"
            >
              {inputs.map((input) => (
                <input
                  key={input.name}
                  type="hidden"
                  name={input.name}
                  value={input.value}
                />
              ))}
              {!loading && !userData?.email && (
                <TextField
                  id="email"
                  label={t("input_main_modal_email")}
                  size="medium"
                  name="email"
                  autoFocus={queriesObj.autoFocus !== "false"}
                  required
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <LoginButton
                fullWidth
                type="submit"
                style={{ marginTop: "16px" }}
                onClick={() => trackLogin("email")}
              >
                {t("text_book_place_subscribe")}
              </LoginButton>
            </form>
          </GroupFormSocial>
        </GroupForm>
      </GroupSingleIframe>
    </ThemeProvider>
  )
}

export default withNamespaces()(Login)
