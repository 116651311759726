/* eslint-disable react/display-name */
import React, { useState } from "react"
import ModalVideo from "react-modal-video"
import {
  GlobalStyle,
  Group,
  GroupForm,
  GroupContent,
  GroupContentBottom,
} from "../style"
import "../../../styled/modal-video.scss"

import LoginRouter from "../Router"

export default () => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Group>
      <GlobalStyle />
      <ModalVideo
        channel="vimeo"
        isOpen={modalOpen}
        videoId="421666113"
        onClose={() => setModalOpen(false)}
        loop={1}
        autoplay={1}
        showinfo={0}
        rel={0}
      />

      <GroupForm>
        <img
          className="GroupFormLogo"
          src="/img/startse-logo.svg"
          alt="Logo StartSe"
        />

        <LoginRouter />
      </GroupForm>

      <GroupContent>
        <img
          className="GroupContentLogo"
          src="https://startse-landings.s3.us-east-2.amazonaws.com/eventos-online/svg/logos/logo-lawtech-conference-2020.svg"
          alt="Logo Lawtech Conference 2020"
        />

        <h4 className="SubtitleLawtech">
          A maior conferência online do setor para você se preparar para a{" "}
          <span>Nova Era da Advocacia!</span>
        </h4>

        <img
          onMouseUp={() => {
            setModalOpen(true)
          }}
          className="GroupContentMac"
          sizes="(max-width: 768px) 100vw, 768px"
          srcSet="
          https://startse-landings.s3.us-east-2.amazonaws.com/eventos-online/img/varejotech/login/login-macbook_rclzbp_c_scale,w_200.png 200w,
          https://startse-landings.s3.us-east-2.amazonaws.com/eventos-online/img/varejotech/login/login-macbook_rclzbp_c_scale,w_335.png 335w,
          https://startse-landings.s3.us-east-2.amazonaws.com/eventos-online/img/varejotech/login/login-macbook_rclzbp_c_scale,w_444.png 444w,
          https://startse-landings.s3.us-east-2.amazonaws.com/eventos-online/img/varejotech/login/login-macbook_rclzbp_c_scale,w_538.png 538w,
          https://startse-landings.s3.us-east-2.amazonaws.com/eventos-online/img/varejotech/login/login-macbook_rclzbp_c_scale,w_621.png 621w,
          https://startse-landings.s3.us-east-2.amazonaws.com/eventos-online/img/varejotech/login/login-macbook_rclzbp_c_scale,w_700.png 700w,
          https://startse-landings.s3.us-east-2.amazonaws.com/eventos-online/img/varejotech/login/login-macbook_rclzbp_c_scale,w_768.png 768w"
          src="https://startse-landings.s3.us-east-2.amazonaws.com/eventos-online/img/varejotech/login/login-macbook_rclzbp_c_scale,w_768.png"
          alt="Imagem de um MacBook Air branco; print da plataforma de eventos na tela"
        />

        <GroupContentBottom>
          <img
            src="https://startse-landings.s3.us-east-2.amazonaws.com/eventos-online/svg/login/icon-green-camera.svg"
            alt="Ícone de uma câmera na cor verde"
          />

          <h5>
            Evento inédito: <span>online</span> e completo.
          </h5>
        </GroupContentBottom>
      </GroupContent>
    </Group>
  )
}
