import styled from "styled-components"

import MuiDialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiBackdrop from "@material-ui/core/Backdrop"
import { withStyles } from "@material-ui/core/styles"

export const Dialog = withStyles(() => ({
  root: {
    margin: "0px",
    padding: "0px",
    borderRadius: "15px",
  },
  paper: {
    width: "500px",
    borderRadius: "15px",
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
}))(MuiDialog)

export const DialogContent = withStyles(() => ({
  root: {
    padding: "60px !important",
    width: "100%",
    display: "flex",
    borderRadius: "15px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    backdropFilter: "blur(44px)",
    boxShadow: "0px 0px 60px #00000036",
    "@media screen and (max-width: 780px)": {
      padding: "60px 10px !important",
    },
  },
}))(MuiDialogContent)

export const Backdrop = withStyles(() => ({
  root: {
    backdropFilter: "blur(2px)",
    backgroundColor: "transparent",
  },
}))(MuiBackdrop)

export const Title = styled.p`
  font-family: "Barlow", sans-serif;
  font-size: 22px;
  font-weight: bold;
`

export const Description = styled.p`
  font-family: "Barlow", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
`
