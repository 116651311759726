import React from "react"
import { withStyles } from "@material-ui/core/styles"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import Button from "@material-ui/core/Button"

const styles = (theme) => ({
  button: {
    position: "absolute",
    left: "0",
    color: "#000",
    textDecoration: "underline",
    marginLeft: "10px",
    fontSize: "16px",
    textTransform: "unset",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#ccc",
      textDecoration: "underline",
    },
    "& svg": {
      fontSize: "28px !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "30px",
      marginLeft: "0px",
      position: "relative",
    },
  },
})

export default withStyles(styles)((props) => {
  const { classes, ...other } = props
  return (
    <Button
      aria-label="back"
      color="primary"
      className={classes.button}
      startIcon={<ArrowBackIcon />}
      type="button"
      {...other}
    >
      Voltar
    </Button>
  )
})
