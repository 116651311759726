import React from "react"
import LogoStartseBlue from "../../icon/LogoStartseBlue"
import { ContainerHead } from "../../style"
import LottieLoading from "../LottieLoading"

import { Backdrop, Dialog, DialogContent, Title, Description } from "./styles"

function LoadingModal({ userName, eventName, showModal }) {
  return (
    <Dialog
      open={showModal}
      aria-labelledby="login"
      BackdropComponent={Backdrop}
    >
      <DialogContent dividers style={{ WebkitOverflowScrolling: "touch" }}>
        <ContainerHead>
          <LogoStartseBlue />
        </ContainerHead>
        <Title>{`Olá ${userName}`}</Title>
        <LottieLoading loading size={200} />
        <Description>{`Seu cadastro no evento ${eventName} está em adamento.`}</Description>
        <Description>
          Você está sendo redirecionado à página de eventos da StartSe.
        </Description>
        <Description>Aguarde o loading...</Description>
      </DialogContent>
    </Dialog>
  )
}

export default LoadingModal
