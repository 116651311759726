import styled from "styled-components"
import TextField from "@material-ui/core/TextField"

export const TextFieldUI = styled(TextField)`
  width: 100% !important;
  max-width: 25em !important;
  .MuiInputBase-input {
    font-size: 1.8vh;
    color: #737373;
    font-family: "Barlow", sans-serif;
    @media (max-width: 780px) {
      font-size: 14px !important;
    }
  }
  .MuiInputLabel-formControl {
    font-family: "Barlow", sans-serif;
    font-size: 1em !important;
    margin-top: 0.8em !important;
    @media (max-width: 780px) {
      font-size: 14px !important;
    }
  }
  label + .MuiInput-formControl {
    margin-top: 0.3em !important;
  }
`
