import React, { createContext, useState, useContext, useCallback } from "react"
import moonshot from "modules/graphql/handler"
import { showModal } from "modules/helpers"

const UserContext = createContext({})

export function UserProvider({ children }) {
  const [user, setUser] = useState({})

  const getUser = useCallback(
    async (token) => {
      const user = await moonshot("getFullData", null, token)
      if (!user) {
        showModal(false)
      }

      showModal(!user.hasCompletedProfile)
      setUser(user)
      return user
    },
    [setUser]
  )

  const updateUser = useCallback(
    async (input) => {
      delete input._id
      const response = await moonshot("updateUser", { input: { ...input } })
      setUser({ ...user, ...input })
      return response
    },
    [user, setUser]
  )

  return (
    <UserContext.Provider value={{ user, getUser, updateUser }}>
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error("useUser must be used within an UserProvider")
  }

  return context
}
