import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"

import { withNamespaces } from "react-i18next"

import yup from "modules/form-validation"
import auth from "modules/services/auth"
import { useApp } from "modules/hooks/login/app"
import { useClient } from "modules/hooks/login/client"

import { FormButtons, SignupForm, SignupContainer } from "./style"
import TextField from "components/Form/TextField"
import { useLocation, useHistory } from "react-router-dom"
import InputAdornment from "@material-ui/core/InputAdornment"
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined"
import Success from "./Success"
import Error from "./Error"
import LinkQS from "components/Router/LinkQS"
import LoginButton from "components/Button/LoginButton"
import { Box } from "@material-ui/core"
import PreLoggedUser from "components/PreLoggedUser"

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
})

const ForgotPassword = ({ t }) => {
  const location = useLocation()
  const history = useHistory()
  const defaultValues = {
    email: (location.state || {}).email || "",
  }
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const { setLoading, loading } = useApp()
  const { setViewTitle, getQueryString } = useClient()
  const [email, setEmail] = useState()
  const [send, setSend] = useState(false)
  const [activation, setActivation] = useState(false)
  useEffect(() => {
    const stateEmail = (location.state || {}).email
    const stateSend = (location.state || {}).send
    setEmail(stateEmail)
    setSend(stateSend)
    setActivation((location.state || {}).activation)
    if (stateEmail && stateSend) {
      onSubmit({ email: stateEmail })
      window.history.pushState(null, "")
    }
    setViewTitle(`${t("text_type_email_forgot_password")}`)
    // eslint-disable-next-line
  }, [])

  const onSubmit = async (data) => {
    setError(null)
    setLoading(true)
    try {
      const response = await auth.post(`auth/forgot${getQueryString()}`, {
        ...data,
      })
      if (!response || !response.data || !response.data.message)
        throw new Error(`${t("text_error_generating_forgot_password")}`)

      setSuccess(true)
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      )
        return setError(error.response.data.message)

      setError(`${t("text_error_generating_forgot_password")}`)
    } finally {
      setLoading(false)
    }
  }

  if (success)
    return (
      <>
        <Success
          title={t("text_new_password_forgot_password")}
          subtitle={t("text_sent_message_forgot_password")}
        />
        <LoginButton
          onClick={() => {
            history.push("/authentication/signin/local" + location.search, {
              ...defaultValues,
            })
          }}
          type="button"
        >
          {t("button_back")}
        </LoginButton>
      </>
    )
  if (loading) {
    return <></>
  }
  if (email && !send) {
    return (
      <SignupContainer>
        <h1 className="signup-title">
          {activation
            ? `${t("text_account_activation_forgot_password")}`
            : `${t("text_register_password_forgot_password")}`}
        </h1>
        <PreLoggedUser email={defaultValues.email} />
        <Box m={2}>
          <h3 className="signup-subtitle">
            {activation
              ? `${t("text_email_not_verified_forgot_password")}`
              : `${t("text_email_with_no_password_forgot_password")}`}
          </h3>
          <LoginButton
            onClick={() => {
              onSubmit({ email, activation })
            }}
            type="button"
          >
            {activation
              ? `${t("text_send_verification_email_forgot_password")}`
              : `${t("text_send_email_to_create_password_forgot_password")}`}
          </LoginButton>
        </Box>
      </SignupContainer>
    )
  }
  return (
    <SignupContainer>
      <h1 className="forgot-title">{t("title_message_forgot_password")}</h1>
      <SignupForm
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="email"
          inputRef={register}
          label={t("input_main_modal_email")}
          size="medium"
          name="email"
          autoFocus={true}
          errors={errors}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />

        <Error text={error} />
        <FormButtons>
          <LinkQS to="/authentication/signin">{t("link_login")}</LinkQS>
          <LoginButton type="submit">{t("button_send")}</LoginButton>
        </FormButtons>
      </SignupForm>
    </SignupContainer>
  )
}

export default withNamespaces()(ForgotPassword)
