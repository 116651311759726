import styled from "styled-components"

export default styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;

  @media screen and (max-width: 700px) {
    position: relative;
    padding: 0;
    height: 120px;
  }
`
