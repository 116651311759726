import axios from "axios"
import cookies from "../../cookies"
import * as queries from "../queries"
import * as mutations from "../mutations"

export default (queryName, variables, token) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const guardianUri = process.env.REACT_APP_GUARDIAN_URI
    const authToken = token || cookies.get("authStartseToken")
    const allQueries = { ...queries, ...mutations }
    const query = allQueries[queryName]

    try {
      const result = await axios.post(
        guardianUri,
        {
          query: query.data,
          variables: JSON.stringify(query.setUser(variables || {})),
        },
        { headers: { "x-auth-token": authToken } }
      )

      if (!result || !result.data || !result.data[query.name]) {
        return reject(new Error("No result"))
      }

      resolve(result.data[query.name])
    } catch (error) {
      reject(error)
    }
  })
