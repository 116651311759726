import styled from "styled-components"

export default styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
  @media screen and (max-width: 600px) {
    padding: 0px 10px;
  }
`
