import styled, { css } from "styled-components"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { CircularProgress } from "@material-ui/core"

export const Container = styled.div`
  font-size: 2vh;
  width: ${(props) => (props.camePlatform ? "auto" : "100%")};
  padding-top: 3em;

  ${(props) =>
    props.showBackground &&
    css`
      padding: 3em;
      background-color: #f2f3f5;
      border-radius: 15px;
      border: 2px solid #fff;
    `}

  p,
  h1,
  label,
  text,
  a {
    font-family: Barlow;
    font-size: 1em;
  }
  h1 {
    text-align: center;
    font-size: 1.4em;
  }
  @media (max-width: 780px) {
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0.5rem;
    p,
    a {
      font-size: 1em;
    }
  }
`
export const CircularProgressUI = withStyles(() => ({
  root: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    top: "40%",
    textAlign: "center",
  },
  svg: {},
}))(CircularProgress)

export const ContainerProgress = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  opacity: 0.8;
  z-index: 99;
`

export const ContainerHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 28px;

  svg {
    width: 40px;
    height: 40px;
  }
  ${(props) =>
    props.camePlatform
      ? css`
          position: relative;
          top: -28px;
          left: -40px;
          @media (max-width: 780px) {
            margin-top: -10px;
            margin-left: 20px;
          }
        `
      : css`
          position: absolute;
          top: 5px;
          left: 0;
          @media (max-width: 780px) {
            padding: 0;
            top: 23px;
            margin-top: -10px;
            margin-left: 20px;
          }
        `}
`

export const ContainerSignIn = styled.div`
  display: flex;
  flex-direction: row;
`

export const ContainerSign = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.camePlatform ? "100%" : "50%")};

  ${(props) =>
    props.hideLogout &&
    css`
      padding-top: 100px;
      justify-content: space-around;
      height: 100vh;

      @media (max-width: 780px) {
        padding-top: 70px;
      }
    `}

  @media (max-width: 780px) {
    width: 100%;
    h1 {
      font-size: 25px;
    }
  }
  a {
    text-decoration: none;
  }
`
export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  @media (max-width: 780px) {
    height: 100vh;
    width: 100%;
    padding: 50px 0;
    border-right: none;
    h1 {
      font-size: 25px;
      margin-bottom: 12px;
    }
  }
`
export const ContainerImages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  text-align: center;
  div {
    display: flex;
    flex-direction: row;
  }
  a {
    margin: 0.5em 0.4em;
    width: 3.1em;
    height: 3.1em;
  }
  img {
    cursor: pointer;
    object-fit: contain;
    width: 3.1em;
    height: 3.1em;
  }

  @media (max-width: 780px) {
    padding: 5px 0;
    p {
      font-size: 14px;
    }
    a {
      margin: 8px 6px;
      width: 45px;
      height: 45px;
    }
    img {
      width: 45px;
      height: 45px;
    }
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  justify-content: ${(props) => (props.hideLogout ? "space-around" : "center")};
  height: ${(props) => (props.hideLogout ? "100%" : null)};

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #d3d3d3 inset;
  }
  .MuiInputLabel-root {
    margin: 15px 0;
    color: #000;
    font-size: 18px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px;
  }
  .MuiAutocomplete-inputRoot {
    height: 50px;
  }
  .MuiInputBase-root {
    border: 0;
    width: 100%;
    background-color: #d3d3d3;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 18px;
    :hover {
      border: 0;
    }
  }
  .MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: 15px;
  }
  .MuiInputLabel-shrink {
    position: absolute;
    transform: none;
    transform-origin: none;
  }
  .makeStyles-root-2 {
    width: 100%;
    margin-bottom: 0px;
  }
  .MuiInput-underline:before {
    border-bottom: 0px;
  }
  .MuiInput-underline:after {
    border: 0;
    background-color: #d3d3d3;
  }
  .MuiInputLabel-formControl {
    position: relative;
    margin-bottom: 0px;
    margin-top: 20px;
    transform: none;
  }
  .makeStyles-root-3 {
    margin-bottom: 0px;
  }
  .MuiFormHelperText-root.Mui-error {
    font-size: 14px;
    margin-top: 5px;
    display: flex;
    justify-content: left;
  }
  p {
    margin-top: 30px;
    text-align: center;
    a {
      color: #1e90ff;
    }
  }
  @media (max-width: 780px) {
    padding: 0 21px;
  }
`

export const Button = styled.button`
  cursor: pointer;
  width: 100%;
  padding: 0.5em;
  height: 2.5em;
  border: 0;
  max-width: 27em;
  background-color: #1e90ff;
  color: #ffffff;
  font-size: 1.8vh;
  font-weight: bold;
  border-radius: 24px;

  @media (max-width: 780px) {
    font-size: 14px;
  }
`

export const UseStyle = makeStyles({
  count: {
    "&:hover": {
      backgroundColor: "#bab9b8",
    },
  },
  haveAccount: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "40px",
    "@media (min-width: 780px)": {
      display: "none",
    },
  },
})

export const Divider = styled.div`
  background-color: #bab9b8;
  opacity: 0.3;
  margin-top: 50px;
  width: 3px;
  height: 500px;
  border-radius: 2px;
`

export const RowText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Barlow", sans-serif;
  p {
    text-align: center;
    span {
      color: #1e90ff;
      cursor: pointer;
      margin: 0px;
      margin-top: 10px !important;
      padding: 0;
      font-size: 1em;
    }
    margin-top: 10px !important;
    padding: 0;
    font-size: 1em;
  }
  @media (min-width: 780px) {
    display: ${(props) => (props.notHide ? "flex" : "none")};
    margin-top: ${(props) => (props.notHide ? "0.8em" : 0)};
  }
  @media (max-width: 780px) {
    p,
    span {
      font-size: 14px;
    }
  }
`

export const SizedBox = styled.div`
  display: none;
  width: 1px;
  height: 2.1em;
  @media (max-width: 780px) {
    display: block;
  }
`

export const RowSelects = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  max-width: 25em;

  @media (max-width: 780px) {
    flex-direction: column;
  }
`

export const SizedBoxSelect = styled.div`
  display: block;
  width: 0.9em;
  height: 1px;
  @media (max-width: 780px) {
    display: none;
  }
`
