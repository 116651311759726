/* eslint-disable react/prop-types */
import React, { useState, useMemo } from "react"
import { useForm } from "react-hook-form"
import moment from "moment"

import MaskedInput from "react-text-mask"
import { withNamespaces } from "react-i18next"

import { concatName, splitName } from "modules/helpers"
import { states } from "modules/constants"
import yup from "modules/form-validation"

import TextField from "components/v4/Form/TextField"
import AutoComplete from "components/v4/Form/AutoComplete"
import DefaultButton from "components/v4/Button/DefaultButton"

import { useTracking } from "modules/hooks/tracking"

import {
  ProfileFormBox,
  ContainerTextField,
  ActionAreaEditProfile,
} from "../../style"

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
    />
  )
}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  dateOfBirth: yup.string().required(),
  country: yup.string().required(),
  state: yup.string().when("country", {
    is: "Brasil",
    then: yup.string().required(),
  }),
  city: yup.string().when("country", {
    is: "Brasil",
    then: yup.string().required(),
  }),
  phone: yup.string().required(),
})

const Form = ({ user, done, show, t }) => {
  const { track } = useTracking()

  const defaultValues = {
    name: concatName(user.firstName, user.lastName),
    dateOfBirth: user.dateOfBirth
      ? moment.utc(user.dateOfBirth).format("DD/MM/YYYY")
      : "",
    country: user.country,
    state: user.state,
    city: user.city,
    phone: user.phone,
  }

  const countries = useMemo(
    () =>
      t("countries", { returnObjects: true }).map((country, index) => ({
        id_pais: index,
        name: country,
      })),
    [t]
  )

  const [watchCountry, setWatchCountry] = useState(
    defaultValues.country === "Brasil"
  )

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })

  const onSubmit = async (data) => {
    const [firstName, lastName] = splitName(data.name)
    delete data.name
    data.dateOfBirth = moment(data.dateOfBirth, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    )
    done({
      ...data,
      firstName,
      lastName,
    })

    track("Clicked on Continue button - My Profile [1/3]")
  }

  return (
    <ProfileFormBox
      style={{ display: show ? "block" : "none" }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <h1>{t("title_personal_data")}</h1>
      <h2>{t("subtitle_personal_data")}</h2>
      <ContainerTextField>
        <h3>{t("input_full_name")}</h3>
        <TextField
          id="name"
          inputRef={register}
          size="medium"
          name="name"
          errors={errors}
          autoFocus={true}
        />

        <h3>{t("input_was_born")}</h3>
        <TextField
          id="dateOfBirth"
          inputRef={register}
          placeholder="dd/mm/aaaa"
          size="medium"
          name="dateOfBirth"
          errors={errors}
          InputProps={{
            inputComponent: TextMaskCustom,
          }}
        />

        <h3>{t("select_country")}</h3>
        <AutoComplete
          id="country"
          options={countries}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => value?.name === option?.name}
          disableClearable
          size="medium"
          defaultValue={
            defaultValues.country ? { name: defaultValues.country } : null
          }
          onChange={(_e, value) => setWatchCountry(value?.name === "Brasil")}
          renderInput={(params) => (
            <TextField
              {...params}
              size="medium"
              inputRef={register}
              name="country"
              errors={errors}
            />
          )}
        />

        {!!watchCountry && (
          <>
            <h3>{t("select_state")}</h3>
            <AutoComplete
              id="state"
              options={states}
              getOptionLabel={(option) => option.estado}
              getOptionSelected={(option, value) =>
                value?.estado === option?.estado
              }
              disableClearable
              size="medium"
              defaultValue={
                defaultValues.state ? { estado: defaultValues.state } : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="medium"
                  inputRef={register}
                  name="state"
                  errors={errors}
                />
              )}
            />

            <h3>{t("input_city")}</h3>
            <TextField
              id="city"
              inputRef={register}
              size="medium"
              name="city"
              errors={errors}
            />
          </>
        )}

        <h3>{t("input_cell_phone")}</h3>
        <TextField
          id="phone"
          inputRef={register}
          size="medium"
          name="phone"
          errors={errors}
        />
      </ContainerTextField>
      <ActionAreaEditProfile>
        <DefaultButton type="submit">{t("button_continue")}</DefaultButton>
      </ActionAreaEditProfile>
    </ProfileFormBox>
  )
}

export default withNamespaces()(Form)
