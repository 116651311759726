import styled from "styled-components"
import { withStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import MuiRadioGroup from "@material-ui/core/RadioGroup"

export const ProfileArea = styled.div`
  text-align: center;
  margin-bottom: 40px;
`
export const RadioGroup = withStyles(() => ({
  root: {
    marginTop: "40px",
    "& .MuiFormControlLabel-label": {
      color: "#000",
      fontSize: "18px",
      lineHeight: "24px",
      fontFamily: "Barlow",
      fontWeight: 600,
    },
  },
}))(MuiRadioGroup)

export const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "#9B9B9B",
    boxShadow: "0px 1px 3px #00000066",
    fontSize: 14,
    padding: "10px 20px",
  },
}))(Tooltip)

export const SharedData = styled.span`
  color: #0080ec;
  font-weight: 400;
  font-family: "Barlow";
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 15px;

  svg {
    color: #0080ec;
    margin-right: 5px;
  }
`
