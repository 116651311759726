import React, { useState } from "react"

import { useForm } from "react-hook-form"
import yup from "modules/form-validation"
import { concatName, splitName } from "modules/helpers"
import moment from "moment"
import MaskedInput from "react-text-mask"

import TextField from "components/Form/TextField"
import AutoComplete from "components/Form/AutoComplete"
import LoginButton from "components/Button/LoginButton"
import { countries, states } from "modules/constants"
import { SendIcon } from "components/icons"
import { ProfileFormBox, ActionAreaEditProfile } from "../style"

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
    />
  )
}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  dateOfBirth: yup.string().required(),
  country: yup.string().required(),
  state: yup.string().when("country", {
    is: "Brasil",
    then: yup.string().required(),
  }),
  city: yup.string().when("country", {
    is: "Brasil",
    then: yup.string().required(),
  }),
  phone: yup.string().required(),
})

const Form = ({ user, done, show }) => {
  const defaultValues = {
    name: concatName(user.firstName, user.lastName),
    dateOfBirth: user.dateOfBirth
      ? moment.utc(user.dateOfBirth).format("DD/MM/YYYY")
      : "",
    country: user.country,
    state: user.state,
    city: user.city,
    phone: user.phone,
  }

  const [watchCountry, setWatchCountry] = useState(
    defaultValues.country === "Brasil"
  )

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })

  const onSubmit = async (data) => {
    const [firstName, lastName] = splitName(data.name)
    delete data.name
    data.dateOfBirth = moment(data.dateOfBirth, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    )
    done({
      ...data,
      firstName,
      lastName,
    })
  }

  return (
    <ProfileFormBox
      style={{ display: show ? "block" : "none" }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <h1>Dados Pessoais</h1>
      <h2>Atualize os dados do seu perfil</h2>
      <TextField
        id="name"
        inputRef={register}
        label="Meu nome completo é"
        size="medium"
        name="name"
        errors={errors}
        autoFocus={true}
      />

      <TextField
        id="dateOfBirth"
        inputRef={register}
        label="Eu nasci em"
        placeholder="dd/mm/aaaa"
        size="medium"
        name="dateOfBirth"
        errors={errors}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
      />

      <AutoComplete
        id="country"
        options={countries}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => value?.name === option?.name}
        disableClearable
        size="medium"
        defaultValue={
          defaultValues.country ? { name: defaultValues.country } : null
        }
        onChange={(_e, value) => setWatchCountry(value?.name === "Brasil")}
        renderInput={(params) => (
          <TextField
            {...params}
            label="País"
            size="medium"
            inputRef={register}
            name="country"
            errors={errors}
          />
        )}
      />

      {!!watchCountry && (
        <>
          <AutoComplete
            id="state"
            options={states}
            getOptionLabel={(option) => option.estado}
            getOptionSelected={(option, value) =>
              value?.estado === option?.estado
            }
            disableClearable
            size="medium"
            defaultValue={
              defaultValues.state ? { estado: defaultValues.state } : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estado"
                size="medium"
                inputRef={register}
                name="state"
                errors={errors}
              />
            )}
          />

          <TextField
            id="city"
            inputRef={register}
            label="Cidade"
            size="medium"
            name="city"
            errors={errors}
          />
        </>
      )}

      <TextField
        id="phone"
        inputRef={register}
        label="Seu celular"
        size="medium"
        name="phone"
        errors={errors}
      />

      <ActionAreaEditProfile>
        <LoginButton
          type="submit"
          endIcon={<SendIcon style={{ color: "#fff" }} />}
        >
          Continuar
        </LoginButton>
      </ActionAreaEditProfile>
    </ProfileFormBox>
  )
}

export default Form
