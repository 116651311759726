import { makeStyles } from "@material-ui/core"
import styled from "styled-components"

export const Container = styled.div`
  border-radius: 15px;
  padding: 2rem;
  font-size: 2vh;
  p,
  h1,
  label,
  text,
  a {
    font-family: Barlow;
  }
  @media (max-width: 600px) {
    padding: 2rem 1rem;
    width: 100%;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #d3d3d3 inset;
  }
  .MuiInputLabel-root {
    margin: 15px 0;
    color: #000;
    font-size: 18px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px;
  }
  .MuiAutocomplete-inputRoot {
    height: 50px;
  }
  .MuiInputBase-root {
    border: 0;
    width: 100%;
    background-color: #d3d3d3;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 18px;
    :hover {
      border: 0;
    }
  }
  .MuiInputLabel-shrink {
    position: absolute;
    transform: none;
    transform-origin: none;
  }
  .makeStyles-root-2 {
    width: 100%;
    margin-bottom: 0px;
  }
  .MuiInput-underline:before {
    border-bottom: 0px;
  }
  .MuiInput-underline:after {
    border: 0;
    background-color: #d3d3d3;
  }
  .MuiInputLabel-formControl {
    position: relative;
    margin-bottom: 0px;
    margin-top: 20px;
    transform: none;
  }
  .makeStyles-root-3 {
    margin-bottom: 0px;
  }
  .MuiFormHelperText-root.Mui-error {
    font-size: 14px;
    margin-top: 5px;
    display: flex;
    justify-content: left;
  }
`

export const ContainerLogo = styled.div`
  @media (max-width: 780px) {
    svg {
      margin-top: -30px;
      width: 55px;
      height: 55px;
    }
  }
`

export const ContainerInfo = styled.div`
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h1`
  text-align: center;
  margin-top: 20px;
  @media (max-width: 780px) {
    font-size: 20px;
  }
`

export const Name = styled.p`
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
`

export const ContainerForm = styled.form`
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Button = styled.button`
  cursor: pointer;
  width: 100%;
  padding: 10px;
  height: 45px;
  border: 0;
  background-color: #1e90ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 24px;
  margin-top: 32px;
  max-width: 412px;
  @media (max-width: 780px) {
    margin-top: 0;
  }
`

export const useStyles = makeStyles({
  avatar: {
    width: 100,
    height: 100,
  },
})
