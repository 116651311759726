import React, { useState, useEffect } from "react"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"

import cookie from "modules/cookies"
import { useUser } from "modules/hooks/onboarding/user"
import { useApp } from "modules/hooks/onboarding/app"
import { closeModal, changeStep } from "modules/helpers"
import { useLocation } from "react-router-dom"
import qs from "qs"
import { steps as stepsData } from "modules/constants"

import Profile from "./Profile"
import Professional from "./Professional"
import Interests from "./Interests"
import EducationInterest from "./EducationInterest"
import Challenge from "./Challenge"
import Main from "./styled/Main"
import Stepper from "./Components/Stepper"
import PageContainer from "./styled/PageContainer"
import Card from "./Card"

const useStyles = makeStyles({
  backdrop: {
    zIndex: 9,
    background: "transparent",
  },
})

const Onboarding = () => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [steps, setSteps] = useState(Object.keys(stepsData))
  const [disableLater, setDisableLater] = useState([])
  const [totalSteps, setTotalSteps] = useState(Object.keys(stepsData).length)
  const [finishStep, setFinishStep] = useState(false)
  const [disableBack, setDisableBack] = useState(false)
  const { user, getUser } = useUser()
  const { loading, setLoading } = useApp()
  const { search } = useLocation()

  useEffect(() => {
    if (!search) return

    const queryString = qs.parse(search.replace("?", ""))

    if (!queryString) return

    if (queryString.hide && queryString.hide.length) {
      let newSteps = [...steps]
      newSteps = newSteps.filter((s) => !queryString.hide.includes(s))
      const newTotalSteps = newSteps.length
      setSteps(newSteps)
      setTotalSteps(newTotalSteps)

      if (activeStep === newTotalSteps - 1) {
        setFinishStep(true)
        setDisableBack(true)
        setDisableLater(newSteps)
      }
    }
    if (queryString.disableLater && queryString.disableLater.length) {
      setDisableLater(queryString.disableLater)
    }

    // eslint-disable-next-line
  }, [
    search,
    setSteps,
    setDisableLater,
    setTotalSteps,
    setFinishStep,
    setDisableBack,
  ])

  useEffect(() => {
    if (totalSteps - 1 === 0) {
      setFinishStep(true)
      setDisableBack(true)
      return
    }

    const currentCheck = activeStep === totalSteps - 1

    if (currentCheck) {
      setFinishStep(true)
      setDisableBack(true)
      return
    }

    if (disableBack) {
      setDisableBack(false)
    }
    // eslint-disable-next-line
  }, [activeStep, totalSteps, setFinishStep])

  useEffect(() => {
    const fetchData = async (_token) => {
      try {
        await getUser(_token)
      } catch {
        closeModal(null)
      }

      setLoading(false)
    }

    const token = cookie.get("authStartseToken")
    if (!token) {
      closeModal(null)
      return setLoading(false)
    }

    fetchData(token)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if ((!user || !user.email) && !loading) closeModal(null)
  }, [user, loading])

  const handleNext = (updatedUser) => {
    if (activeStep === totalSteps - 1) return handleEnd(updatedUser)
    const newStep = activeStep + 1
    setActiveStep(newStep)
    changeStep(newStep)
  }

  const handleBack = () => {
    const newStep = activeStep - 1
    setActiveStep(newStep)
    changeStep(newStep)
  }

  const handleEnd = (updatedUser) => {
    closeModal(updatedUser && updatedUser.email ? updatedUser : user)
  }
  const checkInputLater = (stepName) => {
    if (!disableLater.length) return false
    return !!disableLater.find((s) => s === stepName)
  }

  const findStepIndex = (stepName) => {
    return steps.findIndex((s) => s === stepName)
  }

  return (
    <Main>
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress />
        </Backdrop>
      )}
      {user && user.email && (
        <>
          <Stepper
            activeStep={activeStep}
            steps={steps}
            stepsData={stepsData}
          />
          <PageContainer>
            <Profile
              nextStep={handleNext}
              show={activeStep === findStepIndex("profile")}
              done={finishStep}
            />
            <Professional
              nextStep={handleNext}
              show={activeStep === findStepIndex("professional")}
              disableLater={checkInputLater("professional")}
              backStep={handleBack}
              disableBack={disableBack}
              done={finishStep}
            />
            <EducationInterest
              nextStep={handleNext}
              show={activeStep === findStepIndex("educationInterest")}
              disableLater={checkInputLater("educationInterest")}
              backStep={handleBack}
              disableBack={disableBack}
              done={finishStep}
            />
            <Interests
              nextStep={handleNext}
              show={activeStep === findStepIndex("interests")}
              disableLater={checkInputLater("interests")}
              backStep={handleBack}
              disableBack={disableBack}
              done={finishStep}
            />
            <Card
              nextStep={handleNext}
              show={activeStep === findStepIndex("card")}
              disableLater={checkInputLater("card")}
              backStep={handleBack}
              disableBack={disableBack}
              done={finishStep}
            />
            <Challenge
              nextStep={handleNext}
              show={activeStep === findStepIndex("challenge")}
              disableLater={checkInputLater("challenge")}
              backStep={handleBack}
              disableBack={disableBack}
              done={finishStep}
            />
          </PageContainer>
        </>
      )}
    </Main>
  )
}

export default Onboarding
