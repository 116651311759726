import styled from "styled-components"
import { withStyles } from "@material-ui/core/styles"
import { IconButton } from "@material-ui/core"
import MobileStepper from "@material-ui/core/MobileStepper"
import FormControl from "@material-ui/core/FormControl"

export const SubscribeArea = styled.div`
  padding: 32px 65px 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: none;
  max-width: 480px;
  margin: auto;
  border-bottom: none;
  border-top: none;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);

  @media screen and (max-width: 400px) {
    padding: 32px 20px 70px;
  }

  h1 {
    color: #000;
    font-family: Barlow, sans-serif;
    font-size: 26px;
    text-align: center;
    margin-top: 30px;
  }
  h2 {
    text-align: center;
    color: #000;
    font-size: 16px;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 30px;
  }

  .form-area {
    max-width: 520px;
    margin: 0 auto;
  }
`

export const SubscribeBackground = styled.div`
  width: 100%;
  background-color: transparent;
`

export const HeaderEditProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 30px;
  margin-top: 10px;

  .left-btn {
    left: 0;
  }
  .right-btn {
    right: 0;
  }
`

export const CloseButton = withStyles(() => ({
  root: {
    position: "absolute",
    "& svg": {
      color: "#333333",
    },
  },
}))(IconButton)

export const Steps = withStyles(() => ({
  root: {
    margin: "auto",
    width: "50px",
    backgroundColor: "transparent",
    paddingBottom: 40,
    fontFamily: "Barlow, sans-serif",
  },
}))(MobileStepper)

export const ProfileFormBox = styled.form`
  h1 {
    margin: 24px 0;
    color: #000;
    font-family: Barlow, sans-serif;
    font-weight: 700;
    font-size: 24px;

    &.h1Bigger {
      font-size: 28px;
    }
  }

  h2 {
    margin: 0 auto 32px;
    color: #000;
    font-family: Barlow, sans-serif;
    font-weight: 300;
    font-size: 16px;
    max-width: 350px;
  }

  .congratulationsCheckIcon {
    width: 200px;
    margin: auto;
    display: block;

    polyline {
      stroke-linecap: round;
      stroke-dasharray: 27;
      stroke-dashoffset: -23;
      animation: dash 500ms ease forwards;
    }
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }

  .MuiFormGroup-root .MuiFormControlLabel-root {
    margin-right: 0;

    .MuiTypography-root.MuiFormControlLabel-label {
      font-size: 16px;
    }
  }
`
export const ActionAreaEditProfile = styled.div`
  text-align: center;
  width: 100%;
  max-width: 600px;
  padding-top: 30px;
`

export const ProfileFormSingleCheckboxArea = styled.div`
  max-width: 255px;
  margin: 0 auto 15px;

  .MuiFormControlLabel-root {
    margin-right: 0px;

    .MuiTypography-root.MuiFormControlLabel-label {
      margin-left: 10px;
    }
  }
  .MuiIconButton-label {
    font-size: 26px;
    background: #e5e5e5;
    border-radius: 6px;
    border-color: #e5e5e5;
  }
  .MuiIconButton-label span {
    border-radius: 6px;
    border: 1px solid #e5e5e5;
  }
  .MuiIconButton-label svg {
    font-size: 14px;
  }
  .MuiFormControlLabel-label {
    color: #000;
    font-family: Barlow, sans-serif;
    font-weight: 600;
    font-size: 16px;
  }
`
export const ContainerTextField = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 255px;
  width: 100%;
  margin: auto;
  h3 {
    margin: 0 0 5px;
    color: #000;
    font-family: Barlow, sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin-top: 1rem;
  }
  & > h3:first-child {
    margin-top: 0;
  }
`

export const CheckboxesArea = withStyles(() => ({
  root: {
    "& .MuiFormControlLabel-root": {
      marginBottom: "15px",
    },

    "& .MuiFormControlLabel-label": {
      color: "#818181",
      marginLeft: "10px",
      fontFamily: "Barlow",
      fontWeight: "500",
      fontSize: "19px",
      lineHeight: "21px",
    },
  },
}))(FormControl)

export const LogoStartSe = styled.img`
  width: 75px;
  margin-right: auto;
`
