import React, { useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"

import { useClient } from "modules/hooks/login/client"
import auth from "modules/services/auth"
import qs from "qs"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { sleep } from "modules/helpers"

// eslint-disable-next-line react/display-name
export default () => {
  const { config, setViewTitle } = useClient()
  const { search } = useLocation()
  const history = useHistory()

  useEffect(() => {
    const featchData = async (email) => {
      await sleep(3000)
      try {
        const response = await auth.post("auth/signin/email", { email })
        if (!response || !response.status || response.status !== 200) {
          history.push("/authentication/signup" + search, { email })
        }
        history.push("/authentication/signin/local" + search, { email })
      } catch (err) {
        const errorType = err?.response?.data.error
        if (errorType === "email_not_confirmed") {
          history.push("/authentication/password/forgot" + search, {
            email,
            send: false,
            activation: true,
          })
        } else if (errorType === "password_not_created") {
          history.push("/authentication/password/forgot" + search, {
            email,
            send: false,
          })
        } else {
          history.push("/authentication/signup" + search, { email })
        }
      }
    }

    setViewTitle("")
    let _search = search ? search : ""

    const queryString = qs.parse(_search.replace("?", ""))

    if (!queryString.email) return
    featchData(queryString.email)
    // eslint-disable-next-line
  }, [])

  return config.processingPage ? (
    <config.processingPage />
  ) : (
    <Backdrop open={true} style={{ zIndex: "9" }}>
      <CircularProgress />
    </Backdrop>
  )
}
