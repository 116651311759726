import React, { useEffect } from "react"

import { useClient } from "modules/hooks/login/client"
import { useApp } from "modules/hooks/login/app"
import { Route, Switch, useLocation } from "react-router-dom"
import qs from "qs"

import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import Default from "./Default"
import EmailChecker from "./EmailChecker"

// eslint-disable-next-line react/display-name
export default () => {
  const { getClientView, setView, config } = useClient()
  const { fullLoading, setFullLoading } = useApp()
  const { search } = useLocation()

  useEffect(() => {
    document.title = "StartSe | Login"

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchData = async (id) => {
      setFullLoading(true)
      try {
        const viewClient = await getClientView(id)
        if (viewClient && viewClient.view) setView(viewClient.view)
      } catch (error) {
        console.log(error)
      } finally {
        setFullLoading(false)
      }
    }

    let _search = search ? search : ""
    const queryString = qs.parse(_search.replace("?", ""))

    if (queryString.client_view) return setView(queryString.client_view)
    if (queryString.client_id) fetchData(queryString.client_id)

    // eslint-disable-next-line
  }, [])

  if (fullLoading) {
    return (
      <Backdrop open={true} style={{ zIndex: "9" }}>
        <CircularProgress />
      </Backdrop>
    )
  }

  return (
    <Switch>
      <Route exact path="/authentication/email" component={EmailChecker} />
      <Route
        path="/authentication"
        component={config ? config.component : Default}
      />
    </Switch>
  )
}
