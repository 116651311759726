import React, { useState } from "react"

import {
  Container,
  Header,
  Form,
  Button,
  ContainerText,
  Title,
  Text,
  ContainerIcon,
  ContainerSuccess,
  ContainerCircularProgress,
} from "./style"

import ClearIcon from "@material-ui/icons/Clear"
import LogoStartSe from "../Sign/icon/LogoStartseBlue"
import { useForm } from "react-hook-form"
import yup from "modules/form-validation"
import { useClient } from "modules/hooks/login/client"
import { useApp } from "modules/hooks/login/app"
import auth from "modules/services/auth"
import TextField from "components/Form/TextField"
import { InputAdornment } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import Error from "../Error"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useTracking } from "modules/hooks/tracking"

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
})

export const PasswordForgot = () => {
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const { viewResetPassword } = useApp()
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const { getQueryString } = useClient()
  const { track } = useTracking()

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
  })

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      try {
        track("Forgot password")
      } catch (err) {
        console.log(err)
      }
      const response = await auth.post(`auth/forgot${getQueryString()}`, {
        ...data,
      })
      if (!response || !response.data || !response.data.message)
        throw new Error("Erro gerar nova senha, por favor tente mais tarde")
      setEmail(data.email)
      setSuccess(true)
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (error.response.data.message.indexOf("11000") > -1)
          return setError("Esse e-mail já está sendo utilizado")

        return setError(error.response.data.message)
      }
      setError("Erro ao enviar email, por favor tente mais tarde")
    } finally {
      setLoading(false)
    }
  }
  return (
    <Container>
      {loading ? (
        <ContainerCircularProgress>
          <CircularProgress />
        </ContainerCircularProgress>
      ) : (
        <>
          <Header>
            <LogoStartSe />
            <ClearIcon onClick={viewResetPassword} />
          </Header>
          {!success ? (
            <>
              <ContainerText>
                <Title>Esqueci minha senha</Title>
                <Text>Digite seu email cadastrado</Text>
              </ContainerText>
              <Form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="email"
                  placeholder="Digite seu email"
                  inputRef={register}
                  size="medium"
                  name="email"
                  errors={errors}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                />
                <Error text={error} />
                <Button type="submit">Continuar</Button>
              </Form>
            </>
          ) : (
            <>
              <ContainerText>
                <Title>Esqueci minha senha</Title>
              </ContainerText>
              <ContainerIcon>
                <CheckIcon />
              </ContainerIcon>
              <ContainerSuccess>
                <Text>Enviamos uma recuperação de senha para o email</Text>
                <Text>{email}</Text>
              </ContainerSuccess>
            </>
          )}
        </>
      )}
    </Container>
  )
}
