import React, { createContext, useState, useContext, useCallback } from "react"
import api from "modules/services/api"

const LocationContext = createContext({})

export function LocationProvider({ children }) {
  // const [countries, setCountries] = useState([])
  // const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const [countriesData, statesData] = await Promise.all([
  //       api.get("countries"),
  //       api.get("states"),
  //     ])

  //     const newCountries = (countriesData || {}).data || [] || {}
  //     const newStates = (statesData || {}).data || [] || {}
  //     setCountries(newCountries.data || [])
  //     setStates(newStates.data || [])
  //   }

  //   fetchData()
  // }, [])

  const getCity = useCallback(async (state) => {
    setCities([])
    const citiesData = await api.get(`cities?id_state=${state}`)

    const newCities = (citiesData || {}).data || [] || {}
    setCities(newCities.data || [])
  }, [])

  return (
    <LocationContext.Provider
      value={{ /* countries, states, */ cities, getCity }}
    >
      {children}
    </LocationContext.Provider>
  )
}

export function useLocation() {
  const context = useContext(LocationContext)

  if (!context) {
    throw new Error("useLocation must be used within an LocationProvider")
  }

  return context
}
